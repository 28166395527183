<template>
  <q-form @submit="onSubmit" @reset="onReset" class="bg-white q-ma-md">
    <!--health form-->
    <!--tree info-->
    <q-item class="text-body1 text-center">
      <q-item-section>
        <div class="q-mt-xs q-mb-none q-pb-none text-body2 text-bold">
          {{ selectedTree.tree_name }}
        </div>
        <div class="text-body2 q-mt-xs q-mb-none q-pb-none">
          {{ selectedTree.common_name }}
        </div>
        <div class="text-caption q-mt-none q-pt-none">
          {{ selectedTree.scientific_name }}
        </div>
      </q-item-section>
    </q-item>
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <q-input
        color="secondary"
        class="q-mb-sm"
        outlined
        square
        v-model="tDBH"
        label="DBH (in)"
        style="font-size: 16px; width: 100%"
        :disable="createdVisit ? false : true"
      >
        <template v-slot:after>
          <q-btn
            padding="xs"
            flat
            round
            icon="info"
            color="blue-grey-9"
            @click="
              this.$store.commit('updateShowHelp', {
                url: '/help/DBH_Flowcharts.pdf',
                filename: 'DBH_Flowcharts.pdf',
              })
            "
          /> </template
      ></q-input>
    </q-card>
    <!--Fine twig dieback-->
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <div class="col-11">
        <q-select
          filled
          v-model="twigDiebackModel"
          :options="twigDiebackOptions"
          label="Fine Twig Dieback"
          color="primary"
          clearable
          options-selected-class="text-deep-orange"
          emit-value
          option-label="label"
          option-value="value"
          map-options
          :disable="createdVisit ? false : true"
        >
        </q-select>
      </div>
      <div class="col-1 self-center text-right">
        <q-btn
          padding="xs"
          flat
          round
          icon="info"
          color="blue-grey-9"
          @click="
            this.$store.commit('updateShowHelp', {
              url: '/help/health_check/Fine_Twig_Dieback-Pager.pdf',
              filename: 'Fine_Twig_Dieback-Pager.pdf',
            })
          "
        ></q-btn>
      </div>
    </q-card>
    <!--leaf discoloration-->
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <div class="col-11 text-left">Leaf Discoloration</div>
      <div class="col-1 self-center text-right">
        <q-btn
          padding="xs"
          round
          flat
          icon="info"
          color="blue-grey-9"
          @click="
            this.$store.commit('updateShowHelp', {
              url: '/help/health_check/Discoloration-Pager.pdf',
              filename: 'Discoloration-Pager.pdf',
            })
          "
        >
        </q-btn>
      </div>
      <div class="text-left">
        <q-option-group
          v-model="leafDiscolorationModel"
          :options="leafDiscolorationOptions"
          color="primary"
          inline
          :disable="createdVisit ? false : true"
        />
      </div>
    </q-card>
    <!--leaf defoliation-->
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <div class="col-11 text-left">Leaf Defoliation</div>
      <div class="col-1 self-center text-right">
        <q-btn
          padding="xs"
          round
          flat
          icon="info"
          color="blue-grey-9"
          @click="
            this.$store.commit('updateShowHelp', {
              url: '/help/health_check/Defoliation-Pager.pdf',
              filename: 'Defoliation-Pager.pdf',
            })
          "
        >
        </q-btn>
      </div>
      <div class="text-left">
        <q-option-group
          v-model="leafDefoliationModel"
          :options="leafDefoliationOptions"
          color="primary"
          inline
          :disable="createdVisit ? false : true"
        />
      </div>
    </q-card>
    <!--crown light exposure-->
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <div class="col-11 text-left">Crown Light Exposure</div>
      <div class="col-1 self-center text-right">
        <q-btn
          padding="xs"
          round
          flat
          icon="info"
          color="blue-grey-9"
          @click="
            this.$store.commit('updateShowHelp', {
              url: '/help/health_check/CLE-Pager.pdf',
              filename: 'CLE-Pager.pdf',
            })
          "
        >
        </q-btn>
      </div>
      <div class="text-left">
        <q-option-group
          v-model="crownLightModel"
          :options="crownLightOptions"
          color="primary"
          inline
          :disable="createdVisit ? false : true"
        />
      </div>
    </q-card>
    <!--crown vigor rating-->
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <div class="col-11 text-left">Crown Vigor Rating</div>
      <div class="col-1 self-center text-right">
        <q-btn
          padding="xs"
          round
          flat
          icon="info"
          color="blue-grey-9"
          @click="
            this.$store.commit('updateShowHelp', {
              url: '/help/health_check/Vigor-Pager.pdf',
              filename: 'Vigor-Pager.pdf',
            })
          "
        >
        </q-btn>
      </div>
      <div class="text-left">
        <q-option-group
          v-model="crownVigorModel"
          :options="crownVigorOptions"
          color="primary"
          inline
          :disable="createdVisit ? false : true"
        />
      </div>
    </q-card>
    <!--crown transparency-->
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <div class="col-11">
        <q-select
          filled
          v-model="crownTransparencyModel"
          :options="crownTransparecyOptions"
          label="Crown Transparency"
          color="primary"
          clearable
          options-selected-class="text-deep-orange"
          emit-value
          option-label="label"
          option-value="value"
          map-options
          :disable="createdVisit ? false : true"
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <img style="height: 25px" :src="scope.opt.img" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div class="col-1 self-center text-right">
        <q-btn
          padding="xs"
          round
          flat
          icon="info"
          color="blue-grey-9"
          @click="
            this.$store.commit('updateShowHelp', {
              url: '/help/health_check/Transparency-Pager.pdf',
              filename: 'Transparency-Pager.pdf',
            })
          "
        ></q-btn>
      </div>
    </q-card>
    <!--height-->
    <div class="q-mt-md q-mx-md q-mb-sm">
      <q-input
        outlined
        square
        v-model="tHeight"
        label="Tree Height (feet) - Optional"
        style="font-size: 16px"
        class="bg-white"
        :disable="createdVisit ? false : true"
      />
    </div>
    <!--notes-->
    <div class="q-mt-md q-mx-md q-mb-sm">
      <q-input
        v-model="tNotes"
        autogrow
        outlined
        label="Notes"
        style="font-size: 16px"
        class="bg-white"
        :disable="createdVisit ? false : true"
      />
    </div>
    <!--photos-->
    <!-- <q-item style="border-bottom: 1px solid lightgrey">
        <q-uploader
          url="http://localhost:4444/upload"
          multiple
          accept=".jpg, image/*"
          @rejected="onRejected"
          @added="handleFileAdded"
          style="width: 100%"
          class="shadow-1"
        >
          <template v-slot:header="scope">
            <div
              class="row no-wrap items-center q-pa-sm bg-blue-grey-1 text-primary"
            >
              <q-btn
                v-if="scope.uploadedFiles.length > 0"
                icon="done_all"
                @click="scope.removeUploadedFiles"
                round
                dense
                flat
              >
                <q-tooltip>Remove Uploaded Files</q-tooltip>
              </q-btn>
              <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />

              <div class="col text-left">
                <div class="q-uploader__title">Add Photos</div>
              </div>
              <q-btn
                v-if="scope.canAddFiles"
                type="a"
                icon="photo_camera"
                @click="scope.pickFiles"
                round
                dense
                flat
                size="lg"
              >
                <q-uploader-add-trigger />
                <q-tooltip>Pick Files</q-tooltip>
              </q-btn>
              <q-btn
                v-if="scope.canUpload"
                icon="cloud_upload"
                @click="uploadPhoto"
                round
                dense
                flat
                size="lg"
              >
                <q-tooltip>Upload Files</q-tooltip>
              </q-btn>

              <q-btn
                v-if="scope.isUploading"
                icon="clear"
                @click="scope.abort"
                round
                dense
                flat
              >
                <q-tooltip>Abort Upload</q-tooltip>
              </q-btn>
            </div>
          </template></q-uploader
        >
      </q-item> -->
    <!--submit /cancel-->
    <div class="q-mb-xl q-mt-md">
      <q-btn label="Submit" type="submit" color="primary" v-if="createdVisit" />
    </div>
  </q-form>
</template>

<script>
import { useSSRContext } from 'vue';
export default {
  name: 'FormHealthCheck',
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    selectedTree() {
      return this.$store.state.treesStore.selectedTree;
    },
    createdVisit() {
      let createdVisit = false;
      if (this.$store.state.treesStore.selectedVisitDetails.created_visit) {
        createdVisit = true;
      }
      console.log(createdVisit);
      return createdVisit;
    },
  },
  data() {
    return {
      treeInfoExpanded: true,
      healthExpanded: true,
      tDBH: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.dbh
        : null,
      tHeight: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.treeheight
        : null,
      tNotes: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.notes
        : null,
      tPhoto: '',
      twigDiebackModel: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.fine_twig_dieback
        : null,
      twigDiebackOptions: [
        { label: '1 = 0-1%(trace)', value: '1 = 0-1%(trace)' },
        { label: '5 = 2-5%', value: '5 = 2-5%' },
        { label: '10 = 6-10%', value: '10 = 6-10%' },
        { label: '15 = 11-15%', value: '15 = 11-15%' },
        { label: '20 = 16-20%', value: '20 = 16-20%' },
        { label: '25 = 21-25%', value: '25 = 21-25%' },
        { label: '30 = 26-30%', value: '30 = 26-30%' },
        { label: '35 = 31-35%', value: '35 = 31-35%' },
        { label: '40 = 36-40%', value: '40 = 36-40%' },
        { label: '45 = 41-45%', value: '45 = 41-45%' },
        { label: '50 = 46-50%', value: '50 = 46-50%' },
        { label: '55 = 51-55%', value: '55 = 51-55%' },
        { label: '60 = 56-60%', value: '60 = 56-60%' },
        { label: '65 = 61-65%', value: '65 = 61-65%' },
        { label: '70 = 66-70%', value: '70 = 66-70%' },
        { label: '75 = 71-75%', value: '75 = 71-75%' },
        { label: '80 = 76-80%', value: '80 = 76-80%' },
        { label: '85 = 81-85%', value: '85 = 81-85%' },
        { label: '90 = 86-90%', value: '90 = 86-90%' },
        { label: '95 = 91-95%', value: '95 = 91-95%' },
        { label: '99 = 96-99%', value: '99 = 96-99%' },
        { label: '100 = 100%', value: '100 = 100%' },
      ],
      leafDefoliationModel: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.leaf_defoliation
        : null,
      leafDefoliationOptions: [
        {
          label: 'N/A',
          value: 'N/A',
        },
        {
          label: '1',
          value: '1',
        },
        {
          label: '2',
          value: '2',
        },
        {
          label: '3',
          value: '3',
        },
        {
          label: '4',
          value: '4',
        },
        {
          label: '5',
          value: '5',
        },
      ],
      leafDiscolorationModel: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.leaf_discoloration
        : null,
      leafDiscolorationOptions: [
        {
          label: 'N/A',
          value: 'N/A',
        },
        {
          label: '1',
          value: '1',
        },
        {
          label: '2',
          value: '2',
        },
        {
          label: '3',
          value: '3',
        },
        {
          label: '4',
          value: '4',
        },
        {
          label: '5',
          value: '5',
        },
      ],
      crownVigorModel: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.crown_vigor
        : null,
      crownVigorOptions: [
        {
          label: 'N/A',
          value: 'N/A',
        },
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
      ],
      crownLightModel: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.crown_light_exposure
        : null,
      crownLightOptions: [
        {
          label: 'N/A',
          value: 'N/A',
        },
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
      ],
      crownTransparencyModel: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.crown_density_trans
        : null,
      crownTransparecyOptions: [
        {
          label: '5%',
          value: 1,
          img: '/help/health_check/crown/5.png',
        },
        {
          label: '15%',
          value: 2,
          img: '/help/health_check/crown/15.png',
        },
        {
          label: '25%',
          value: 3,
          img: '/help/health_check/crown/25.png',
        },
        {
          label: '35%',
          value: 4,
          img: '/help/health_check/crown/35.png',
        },
        {
          label: '45%',
          value: 5,
          img: '/help/health_check/crown/45.png',
        },
        {
          label: '55%',
          value: 6,
          img: '/help/health_check/crown/55.png',
        },
        {
          label: '65%',
          value: 7,
          img: '/help/health_check/crown/65.png',
        },
        {
          label: '75%',
          value: 8,
          img: '/help/health_check/crown/75.png',
        },
        {
          label: '85%',
          value: 9,
          img: '/help/health_check/crown/85.png',
        },
        {
          label: '95%',
          value: 10,
          img: '/help/health_check/crown/95.png',
        },
      ],
    };
  },
  methods: {
    onRejected() {
      console.log('rejected');
    },
    handleFileAdded() {
      console.log('file added');
    },
    onSubmit() {
      this.handleResize();
      let obj = {
        visitId: this.$store.state.treesStore.selectedVisitDetails.visit_id,
        treeId: this.selectedTree.tree_id,
        subId: this.selectedTree.sub_id,
        projectId: this.selectedTree.project_id,
        inDbh: this.tDBH,
        inTreeheight: this.tHeight,
        inNotes: this.tNotes,
        fineTwigDieback: this.twigDiebackModel,
        leafDefoliation: this.leafDefoliationModel,
        leafDiscoloration: this.leafDiscolorationModel,
        crownVigor: this.crownVigorModel == 'N/A' ? 0 : this.crownVigorModel,
        crownLightExposure:
          this.crownLightModel == 'N/A' ? 0 : this.crownLightModel,
        crownDensityTrans: this.crownTransparencyModel,
      };

      if (this.$store.state.treesStore.editTreeMode) {
        this.$store.dispatch('editHealthCheck', obj);
      } else {
        this.$store.dispatch('insertHealthCheck', obj);
      }
    },
    onReset() {
      this.handleResize();
      this.tDBH = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.dbh
        : null;
      this.tHeight = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.treeheight
        : null;
      this.tNotes = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.notes
        : null;
      this.twigDiebackModel = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.fine_twig_dieback
        : null;
      this.leafDefoliationModel = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.leaf_defoliation
        : null;
      this.leafDiscolorationModel = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.leaf_discoloration
        : null;
      this.crownVigorModel = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.crown_vigor
        : null;
      this.crownLightModel = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.crown_light_exposure
        : null;
      this.crownTransparencyModel = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.crown_density_trans
        : null;
    },
    //what this does is reset the size of the viewport.  This handles the issue on mobile
    //where the browser resets the size of the viewport in order to move the input up above
    //the virtual keyboard.  Setting the font size to 16px prevents it froom zooming.
    handleResize() {
      const currentViewportHeight = window.visualViewport.height;
      const heightDifference =
        this.originalViewportHeight - currentViewportHeight;
      window.scrollTo(0, window.pageYOffset + heightDifference);
    },
    openTab(path) {
      //window.open(path, '_blank');
      this.showPdf;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
