<template>
  <div class="bg-white">
    <q-toolbar class="bg-blue-grey-9 text-white">
      <q-btn
        icon="menu"
        flat
        round
        color=""
        @click="toggleDrawer"
        v-if="$q.screen.lt.md"
      >
      </q-btn>
      <q-toolbar-title class="text-subtitle2">Dashboard Home</q-toolbar-title>
      <q-space></q-space>
      <q-btn
        padding="xs"
        to="/"
        class="q-mr-md"
        flat
        color="white"
        label="Sign Out"
        @click="logout"
      >
      </q-btn>
      <q-btn padding="xs" round flat color="white" icon="help"
        ><q-menu class="">
          <div class="q-ma-md">
            Get started using the shortcuts below. These tasks can also be found
            on the left side menu.
          </div></q-menu
        ></q-btn
      >
    </q-toolbar>
    <q-scroll-area style="height:calc(100vh - 86px)" class="shadow-1">
      <div :style="$q.screen.lt.sm ? 'padding-bottom:100px' : ''">
        <div class="text-h5 text-center q-mt-lg">Welcome, {{ userInfo }}!</div>

        <div class="text-center q-mx-xl text-justify">
          Healthy Trees, Healthy Cities (HTHC) is a tree health monitoring
          initiative coordinated by The Nature Conservancy and the USDA Forest
          Service, which supports researchers, managers, and civic ecologists in
          the protection of trees and forests in communities nationwide. Healthy
          Trees, Healthy Cities offers forestry managers and tree stewards
          critical tools to monitor and prioritize stewardship and management of
          trees. The data gathered through these efforts have both local and
          national implications: locally, managers and stewards use the data to
          prioritize the care of stressed trees and intervene to restore health;
          nationally, USDA Forest Service researchers study the effects of the
          urban environment on trees, and the impact healthy trees have on
          communities.
        </div>
        <div class="row justify-center q-mt-lg" v-if="this.stats">
          <div class="text-center q-mr-lg">
            <q-img
              src="/img/stats_trees.png"
              style="width: 100px; height: 100px"
              class="q-mb-md"
            ></q-img
            ><br /><span class="text-bold">
              {{ this.stats.trees }}<br />Trees<br />
              Surveyed</span
            >
          </div>
          <div class="text-center q-mr-lg">
            <q-img
              src="/img/stats_activities.png"
              style="width: 100px; height: 100px"
              class="q-mb-md"
            ></q-img
            ><br /><span class="text-bold">
              {{ this.stats.activities }}
              Tree<br />
              Care <br />Activities</span
            >
          </div>
          <div class="text-center">
            <q-img
              src="/img/stats_users.png"
              style="width: 100px; height: 100px"
              class="q-mb-md"
            ></q-img
            ><br /><span class="text-bold">
              {{ this.stats.users }}
              <br />
              Civic <br />Scientists</span
            >
          </div>
        </div>
        <div class="text-body1 text-center q-mt-xl">Quick Links</div>
        <div class="row justify-center  text-center">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="my-card q-ma-md ">
              <!--img src="/img/placeholder.png" style="height:200px; " /-->

              <q-card-section>
                <div class="text-subtitle1">My Projects</div>
                <q-separator spaced></q-separator>
                <div class="text-body1 text-orange-9 ">
                  Continue working on a recent project
                </div>

                <q-card-section class="q-pt-none"> </q-card-section>
                <q-btn
                  to="/dashboard/myprojects"
                  outline
                  class=""
                  color="secondary"
                  >Go to My Projects</q-btn
                >
              </q-card-section>

              <!--q-card-section class="q-pt-none">
          <q-list>
            <q-item
              clickable
              v-for="(proj, key) in $store.state.projects.slice(0, 2)"
              :key="key"
              class="text-left"
            >
              <q-item-section>
                <q-item-label>{{ proj.name }}</q-item-label>
                <q-item-label caption>{{ proj.role }}</q-item-label>
                <q-item-label caption lines="1">{{
                  proj.description
                }}</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-btn flat name="" label="Open" color="secondary" />
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section-->
            </q-card>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="my-card q-ma-md">
              <!--img src="/img/placeholder.png" style="height:200px; " /-->

              <q-card-section>
                <div class="text-subtitle1">Find a Project to Join</div>
                <q-separator spaced></q-separator>
                <div class="text-body1 text-orange-9 ">
                  Get started by finding a project to join
                </div>
              </q-card-section>

              <q-btn
                outline
                class="q-mb-md"
                color="secondary"
                to="/dashboard/joinproject"
                >Find a Project to Join</q-btn
              >
            </q-card>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="my-card q-ma-md ">
              <!--img src="/img/placeholder.png" style="height:200px; " /-->

              <q-card-section>
                <div class="text-subtitle1">Create Project</div>
                <q-separator spaced></q-separator>
                <div class="text-body1 text-orange-9">
                  Get started by creating a new project
                </div>
              </q-card-section>

              <q-btn
                class="q-mb-md"
                outline
                color="secondary"
                to="/dashboard/newproject"
                >Create Project</q-btn
              >
            </q-card>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="my-card q-ma-md ">
              <!--img src="/img/placeholder.png" style="height:200px; " /-->

              <q-card-section>
                <div class="text-subtitle1">Field Data Collection</div>
                <q-separator spaced></q-separator>
                <div class="text-body1 text-orange-9">
                  Get started with field data collection
                </div>
              </q-card-section>

              <q-btn
                outline
                class="q-mb-md q-mr-sm"
                color="secondary"
                to="/dashboard/fieldtool"
                >Field Tool Info</q-btn
              >
            </q-card>
          </div>
        </div>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  name: 'Home',

  components: {},
  data() {
    return {
      selected: 'DashboardHome',
      slide: 1,
      autoplay: true,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.authStore.userInfo.given_name;
    },
    stats() {
      let stats = '';
      if (this.$store.state.dashboardStats) {
        let trees = this.$store.state.dashboardStats.data.data[0].trees;
        let treesFormat = trees.toLocaleString();
        let activities = this.$store.state.dashboardStats.data.data[0].visits;
        let activitiesFormat = activities.toLocaleString();
        let users = this.$store.state.dashboardStats.data.data[0].users;
        let usersFormat = users.toLocaleString();
        stats = {
          trees: treesFormat,
          activities: activitiesFormat,
          users: usersFormat,
        };
      }
      return stats;
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
    logout() {
      this.$store.dispatch('logoutUser');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
