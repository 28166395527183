<template>
  <!--This is the inner toolbar and shell for the Projects section of the dashboad-->
  <div>
    <div class="bg-blue-grey-9">
      <q-toolbar>
        <q-btn
          icon="menu"
          flat
          round
          color="white"
          @click="toggleDrawer"
          v-if="$q.screen.lt.md"
        >
        </q-btn>
        <q-toolbar-title
          class="text-white text-subtitle1"
          v-if="!$q.screen.lt.sm"
          >{{ activeProject.project_name }}
          <q-btn
            icon="menu"
            flat
            @click="$state.commit('openDrawer')"
            v-if="$q.screen.lt.sm"
            color="white"
          >
          </q-btn>
        </q-toolbar-title>
        <q-space></q-space>
        <q-list>
          <q-btn
            padding="sm"
            :to="'/dashboard/project/' + this.index + '/map'"
            label="Map"
            unelevated
            :class="
              this.selected == 'map'
                ? 'bg-secondary text-white q-mr-xs'
                : 'bg-white text-secondary q-mr-xs'
            "
            @click="
              this.helpText =
                'This is the map. Project lead can add trees to the project. Users can see all the trees in the project red and all the trees not in the project but in the db. blue'
            "
          ></q-btn>
          <q-btn
            padding="sm"
            :to="'/dashboard/project/' + this.index + '/trees'"
            label="Trees"
            unelevated
            :class="
              this.selected == 'trees'
                ? 'bg-secondary text-white q-mr-xs'
                : 'bg-white text-secondary q-mr-xs'
            "
            @click="
              this.helpText =
                'This is the project trees page. View a table of the trees in the project. Select a tree to view the tree homepage.'
            "
          ></q-btn>
          <q-btn
            padding="sm"
            :to="'/dashboard/project/' + this.index + '/users'"
            label="Users"
            unelevated
            :class="
              this.selected == 'users'
                ? 'bg-secondary text-white q-mr-xs'
                : 'bg-white text-secondary q-mr-xs'
            "
            @click="
              this.helpText =
                'This is a list of project particpants. Project leaders can approve requests to add users, remove users, and assign roles.'
            "
          ></q-btn>
          <q-btn
            padding="sm"
            :to="'/dashboard/project/' + this.index + '/activities'"
            label="Log"
            unelevated
            :class="
              this.selected == 'activities'
                ? 'bg-secondary text-white q-mr-xs'
                : 'bg-white text-secondary q-mr-xs'
            "
            @click="this.helpText = 'Activites Help'"
          ></q-btn>
          <q-btn
            padding="sm"
            :to="'/dashboard/project/' + this.index + '/qaqc'"
            label="QAQC"
            unelevated
            v-if="
              this.$store.state.userStore.activeProject.role_name ==
                'Project Leader'
            "
            :class="
              this.selected == 'qaqc'
                ? 'bg-secondary text-white q-mr-xs'
                : 'bg-white text-secondary q-mr-xs'
            "
            @click="this.helpText = 'QAQC Help'"
          ></q-btn>
          <q-btn
            padding="sm"
            :to="'/dashboard/project/' + this.index + '/settings'"
            label="Settings"
            unelevated
            :class="
              this.selected == 'settings'
                ? 'bg-secondary text-white '
                : 'bg-white text-secondary '
            "
            @click="this.helpText = 'Settings Help'"
          ></q-btn>
        </q-list>
        <q-btn
          padding="xs"
          flat
          round
          color="white"
          icon="help"
          class="q-ml-md"
          v-if="!$q.screen.lt.sm"
          ><q-menu
            ><div class="q-ma-md">
              {{ helpText }}
            </div>
          </q-menu></q-btn
        >
      </q-toolbar>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  name: 'ActiveProject',
  props: ['index'],
  computed: {
    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
    //this highlights the correct menu based on route
    selected() {
      let path = this.$route.path;
      let active = path.substring(path.lastIndexOf('/') + 1);
      return active;
    },
  },
  watch: {
    selected() {
      this.$store.commit('updateShowTreeHome', false);
    },
  },

  data() {
    return {
      selectedActiveMenu: 'Map',
      helpText:
        'This is the map. Project lead can add trees to the project. Users can see all the trees in the project red and all the trees not in the project but in the db. blue',
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
    closeProject() {
      this.$store.commit('closeProject', this.index);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
