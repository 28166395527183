<template>
  <div
    class="shadow-1"
    :style="
      $q.screen.lt.sm
        ? 'height: calc( 100vh - 76px);'
        : 'height: calc( 100vh - 86px); display:flex'
    "
  >
    <Map height="calc(100vh - 86px)" style="flex:1; "></Map>
    <div
      :style="$q.screen.lt.sm ? 'width:100vw' : 'width:33vw'"
      v-if="showTreeHome"
    >
      <tree-home-page></tree-home-page>
    </div>
  </div>
</template>

<script>
import Map from './Map.vue';
import TreeHomePage from './TreeHomePage.vue';
export default {
  name: 'ActiveProjectMap',
  props: ['index'],
  computed: {
    showTreeHome() {
      return this.$store.state.showTreeHome;
    },
  },
  components: { Map, TreeHomePage },
  data() {
    return {};
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
