const state = () => ({
  qaqcVisits: '',
  qaqcByProject: '',
  qaqcCompare: '',
  activeQAQCProject: '',
  qaqcTreesGeoJson: { features: [] },
  percentComplete: { total_visits: 2, visits_complete: 0 },
});

const getters = {};
const mutations = {
  updateQAQCVisits(state, arr) {
    state.qaqcVisits = arr;
  },
  updateQAQCByProject(state, arr) {
    state.qaqcByProject = '';
    state.qaqcByProject = arr;
  },
  updateQAQCTreesGeoJson(state, arr) {
    state.qaqcTreesGeoJson = arr;
  },
  updateQAQCCompare(state, arr) {
    state.qaqcCompare = arr;
  },
  updateActiveQAQCProject(state, obj) {
    state.activeQAQCProject = obj;
  },
  updatePercentComplete(state, obj) {
    state.percentComplete = obj;
  },
};

const actions = {
  async getQAQCByProject(context, val) {
    console.log('gets here');
    let projectId = val;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/qaqc?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        console.log(response);
        try {
          let arr = response.data.data;
          await context.commit('updateQAQCByProject', arr);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getQAQCVisits(context, obj) {
    let qaqcId = obj.qaqcId;
    let projectId = obj.projectId;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/qaqc/visits?qaqcId=${qaqcId}&projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        console.log(response);
        try {
          let arr = response.data.data;
          await context.commit('updateQAQCVisits', arr);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async createQAQCVisits(context, obj) {
    console.log(obj);
    let projectId = obj.projectId;
    let startDate = obj.startDate;
    let endDate = obj.endDate;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: '/qaqc/visits',
        type: 'POST',
        body: {
          projectId: projectId,
          startDate: startDate,
          endDate: endDate,
        },
      });
      if (response.status === 200) {
        console.log(response);
        try {
          let arr = response.data.data;
          await context.dispatch('getQAQCByProject', projectId);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getQAQCTrees(context, obj) {
    return new Promise(async (resolve, reject) => {
      const qaqcId = obj.qaqcId;
      const projectId = obj.projectId;
      const response = await context.dispatch('protectedApiRequest', {
        route: `qaqc/trees?qaqcId=${qaqcId}&projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        console.log(response);
        await context.dispatch('convertQAQCToGeojson', response.data.data);
        resolve();
      } else {
        reject();
      }
    });
  },
  async convertQAQCToGeojson(context, geoJson) {
    let geoJsonReformat = {
      type: 'FeatureCollection',
      features: [],
    };
    geoJson.forEach((item) => {
      console.log(item);
      geoJsonReformat.features.push(JSON.parse(item.point_geojson));
    });

    await context.commit('updateQAQCTreesGeoJson', geoJsonReformat);
  },
  async getQAQCCompare(context, obj) {
    let projectId = obj.projectId;
    let qaqcId = obj.qaqcId;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/qaqc/compare?projectId=${projectId}&qaqcId=${qaqcId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let arr = response.data.data;
          console.log(arr);
          await context.commit('updateQAQCCompare', arr);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async editQAQC(context, obj) {
    let projectId = obj.projectId;
    let qaqcId = obj.qaqcId;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/qaqc',
      type: 'PUT',
      body: {
        projectId,
        qaqcId,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        await context.dispatch('getQAQCByProject', projectId);
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
