<template>
  <!--This is the resources page-->
  <q-toolbar>
    <q-toolbar-title>Resources to help you..</q-toolbar-title>
    <q-space></q-space>
    <q-btn-dropdown
      icon="language"
      rounded
      text-color="primary"
      color="white"
      :label="'Show resources in ' + langSelection"
      size="12px"
      class="q-mr-md"
      :no-caps="true"
    >
      <q-list dense class="">
        <q-item clickable v-close-popup @click="langSelection = 'english'">
          <q-item-section>
            <q-item-label>English</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>
        <q-item clickable v-close-popup @click="langSelection = 'español'">
          <q-item-section>
            <q-item-label>Español</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </q-toolbar>
  <div
    class="q-pa-md q-gutter-sm q-mt-sm"
    :style="$q.screen.lt.sm ? 'padding-bottom:100px' : ''"
  >
    <div>
      <q-tree
        ref="tree"
        :nodes="this.simple"
        node-key="label"
        selected-color="secondary"
        class="text-body1 q-ml-lg"
        v-model:expanded="expanded"
        no-connectors=""
      >
        <template v-slot:default-body="prop">
          <div v-if="prop.node.resource">
            <div v-if="this.langSelection == 'english'">
              <q-btn
                v-if="prop.node.resource.type == 'pdf'"
                color="secondary"
                icon="picture_as_pdf"
                flat
                label="View PDF"
                @click="
                  this.$store.commit('updateShowHelp', {
                    url: prop.node.resource.english,
                    filename: prop.node.label,
                  })
                "
                size="sm"
              ></q-btn>
              <q-btn
                v-if="prop.node.resource.type == 'pdf'"
                color="secondary"
                icon="download"
                flat
                label="Download PDF"
                @click="
                  downloadFile(prop.node.resource.english, prop.node.label)
                "
                size="sm"
              ></q-btn>
              <q-btn
                v-if="prop.node.resource.type == 'ppt'"
                color="secondary"
                icon="download"
                flat
                label="Download power point"
                @click="
                  downloadFile(prop.node.resource.english, prop.node.label)
                "
                size="sm"
              ></q-btn>
              <q-btn
                v-if="prop.node.resource.type == 'xlsx'"
                color="secondary"
                icon="download"
                flat
                label="Download excel file"
                @click="
                  downloadFile(prop.node.resource.english, prop.node.label)
                "
                size="sm"
              ></q-btn>
              <q-btn
                v-if="prop.node.resource.type == 'video'"
                color="secondary"
                icon="play_circle"
                flat
                label="Play video"
                @click="this.showVideo = prop.node.resource.english"
                size="sm"
              ></q-btn>
            </div>
            <q-slide-transition>
              <div v-if="prop.node.resource.english == this.showVideo">
                <q-btn
                  flat
                  icon="expand_less"
                  label="close"
                  @click="this.showVideo = ''"
                ></q-btn>

                <q-video :ratio="16 / 9" :src="prop.node.resource.english" />
              </div>
            </q-slide-transition>
            <div
              v-if="
                this.langSelection == 'español' &&
                  prop.node.resource.spanish !== ''
              "
            >
              <q-btn
                v-if="prop.node.resource.type == 'pdf'"
                color="secondary"
                icon="picture_as_pdf"
                flat
                label="vista PDF"
                @click="
                  this.$store.commit('updateShowHelp', {
                    url: prop.node.resource.spanish,
                    filename: prop.node.label,
                  })
                "
                size="sm"
              ></q-btn>
              <q-btn
                v-if="prop.node.resource.type == 'pdf'"
                color="secondary"
                icon="download"
                flat
                label="descargar PDF"
                @click="
                  downloadFile(prop.node.resource.spanish, prop.node.label)
                "
                size="sm"
              ></q-btn>
              <q-btn
                v-if="prop.node.resource.type == 'ppt'"
                color="secondary"
                icon="download"
                flat
                label="Descargar power point"
                @click="
                  downloadFile(prop.node.resource.spanish, prop.node.label)
                "
                size="sm"
              ></q-btn>
              <q-btn
                v-if="prop.node.resource.type == 'xlsx'"
                color="secondary"
                icon="download"
                flat
                label="Download excel file"
                @click="
                  downloadFile(prop.node.resource.spanish, prop.node.label)
                "
                size="sm"
              ></q-btn>
            </div>
            <div
              v-if="
                this.langSelection == 'español' &&
                  prop.node.resource.spanish == ''
              "
            >
              <p class="text-caption" color="blue-grey-8">
                Available in english
              </p>
            </div>
          </div>
        </template>
      </q-tree>
    </div>
    <!--div
        v-if="this.selectedVideo"
        class="col-md-6 col-sm-12 col-xs-12 q-gutter-sm text-center"
      >
        <div class="q-pa-md">
          <p class="text-h6 text-left text-primary">
            {{ this.selectedTitle }}
          </p>
          <q-video :ratio="16 / 9" :src="this.selectedVideo" />
        </div>
      </div>
      <div
        v-if="this.selectedResource.type == 'pdf'"
        class="col-md-8 col-sm-12 col-xs-12 q-gutter-sm"
      >
        <div class="">
          <div>
            <p class="text-h6 text-left text-primary">
              {{ this.selectedResource.title }}
            </p>
            <q-item style="border-bottom: 1px solid lightgrey">
              <q-item-section thumbnail>
                <q-icon name="home"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-subtitle2">
                  {{ this.selectedResource.title }}
                </q-item-label>
                <q-item-label class="text-caption"> </q-item-label>
                <div class="row q-mt-md">
                  <q-btn
                    v-if="this.selectedResource.type.PDF"
                    color="secondary"
                    icon="download"
                    flat
                    label="PDF"
                    @click="
                      this.$store.commit('updateShowHelp', {
                        url: this.selectedResource.english,
                        filename: file.label,
                      })
                    "
                    size="sm"
                  ></q-btn>

                  <q-btn
                    color="secondary"
                    icon="download"
                    flat
                    label="Power Point"
                    type="a"
                    :href="file.powerpoint"
                    target="_blank"
                    size="sm"
                  ></q-btn>
                </div>
              </q-item-section>
            </q-item>
          </div>
        </div>
      </div-->
  </div>
</template>

<script>
export default {
  components: {},
  name: 'Resources',
  computed: {},
  watch: {
    selected() {
      console.log(this.selected);
      let node = this.$refs.tree.getNodeByKey(this.selected);
      this.selectedResource = node.resource;
    },
  },

  data() {
    return {
      langSelection: 'english',
      expanded: ['Videos', 'Training Videos'],
      selected: '',
      selectedResource: '',
      showVideo: '',
      simple: [
        {
          label: 'Use the field tool and dashboard',
          selectable: false,
          children: [
            {
              label: 'Field tool',
              selectable: false,
              children: [
                {
                  label: 'How to use the Field Tool (Guide)',
                  icon: 'article',
                  selectable: true,
                  resource: {
                    english:
                      '/resources/hthc_materials/training_manual_individual/How to use the field tool.pdf',
                    spanish:
                      '/resources/hthc_materials_spanish/1.4.How to use the field tool_SPA_Final.pdf',
                    type: 'pdf',
                  },
                },
              ],
            },
            {
              label: 'Dashboard',
              selectable: false,
              children: [
                {
                  label: 'How to use the Dashboard (Guide)',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/training_manual_individual/How to use the dashboard.pdf',
                    spanish:
                      '/resources/hthc_materials_spanish/1.3.How to use the dashboard HTHC_SPA_Final.pdf',
                    type: 'pdf',
                  },
                },
                {
                  label:
                    'Understanding your data: Preexisting Inventory/Data Import Dictionary',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/Data Import Dictionary - Species List.xlsx',
                    spanish:
                      '/resources/hthc_materials_spanish/3.1.Data Import Dictionary - Species List_SPA_Final.xlsx',
                    type: 'xlsx',
                  },
                },
                {
                  label: 'Uploading your data: HTHC Data Dictionary',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/HTHC Data Dictionary.xlsx',
                    spanish:
                      '/resources/hthc_materials_spanish/1.11.HTHC Data Dictionary (Nov 23) SPA_Final.xlsx',
                    type: 'xlsx',
                  },
                },
                {
                  label: 'Importing HTHC Data to i-Tree Eco',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/training_manual_individual/Using HTHC Data with i-Tree.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label: 'The QA/QC Process and Guide',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/training_manual_individual/QA_QC Process and Guide.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
              ],
            },
          ],
        },

        {
          label: 'Run a training',
          selectable: false,
          children: [
            {
              label: 'Training Manual',
              selectable: false,
              children: [
                {
                  label: 'HTHC Training Manual',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/training_manual/HTHC Training Manual Final.pdf',
                    spanish:
                      '/resources/hthc_materials_spanish/2.5.HTHC Training Manual (Nov 24) SPA. Final.pdf',
                    type: 'pdf',
                  },
                },
              ],
            },
            {
              label: 'Training PowerPoints and videos (For use in training)',
              selectable: false,
              children: [
                {
                  label: 'HTHC Introduction',
                  selectable: false,
                  children: [
                    {
                      label: 'HTHC Field Tool Training Overview',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/HTHC Field Tool Training Overview.pptx',
                        spanish:
                          '/resources/hthc_materials_spanish/3.3.HTHC Field Tool Training Overview_SPA_Final.pptx',
                        type: 'ppt',
                      },
                    },
                    {
                      label: 'HTHC Summary, Methods, Tools, & Case Study',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/HTHC Summary, Methods, Tools, & Case Study.pptx',
                        spanish:
                          '/resources/hthc_materials_spanish/3.4.HTHC Summary, Methods, Tools, _ Case Study_SPA_Final.pptx',
                        type: 'ppt',
                      },
                    },
                    {
                      label: 'HTHC Introduction Video',
                      icon: 'play_circle',
                      resource: {
                        english: 'https://www.youtube.com/embed/y-o4kGhYTfw',
                        spanish: '',
                        type: 'video',
                      },
                    },
                  ],
                },
                {
                  label: 'Project Setup, Adding a Tree, and Tree Care',
                  selectable: false,
                  children: [
                    {
                      label:
                        'How to Create a Project, Add a Tree, Add Users to a Project, and Collect and Input Tree Care Data',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/HTHC Create Project, Add a Tree, Add Users, Tree Care- PPT1.pptx',
                        spanish:
                          '/resources/hthc_materials_spanish/1.10.HTHC Create Project, Add a Tree, Add Users, Tree Care- PPT1 (Nov 23) SPA_Final.pptx',
                        type: 'ppt',
                      },
                    },
                    {
                      label: 'How to calculate DBH',
                      icon: 'play_circle',
                      resource: {
                        english: 'https://www.youtube.com/embed/yoMkfPpS_b0',
                        spanish: '',
                        type: 'video',
                      },
                    },
                  ],
                },
                {
                  label: 'Tree Health Check and Data Input',
                  selectable: false,
                  children: [
                    {
                      label:
                        'How to Conduct a Tree Health Check and Input Data',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/Health Checks Presentation - PPT2.pptx',
                        spanish:
                          '/resources/hthc_materials_spanish/1.9.Health Checks Presentation - PPT2_SPA_Final.pptx',
                        type: 'ppt',
                      },
                    },
                  ],
                },
                {
                  label: 'Pest Check and Data input',
                  selectable: false,
                  children: [
                    {
                      label:
                        'How to Conduct an Early Pest Detection Assessment and Input Data',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/Early Pest Detection Presentation - PPT3.pptx',
                        spanish:
                          '/resources/hthc_materials_spanish/1.8.Early Pest Detection Presentation - PPT3_PPT_Final.pptx',
                        type: 'ppt',
                      },
                    },
                    {
                      label: 'Pest-Specific Presentations',
                      children: [
                        {
                          label: 'Asian Long Horned Beetle',
                          subheader: 'Anoplophora glabripennis',
                          icon: 'article',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_AsianLongHornedBeetle.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Balsam Woolly Adelgid',
                          subheader: 'Adelges piceae',
                          icon: 'article',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Balsam Woolly Adelgid.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Citrus Greening',
                          subheader: 'Candidatus Liberibacter asiaticus',
                          icon: 'article',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Citrus Greening.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Emerald Ash Borer',
                          subheader: 'Agrilus planipennis',
                          icon: 'article',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Emerald Ash Borer.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Gold Spotted Oak Borer',
                          subheader: 'Agrilus coxalis',
                          icon: 'article',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Gold Spotted Oak Borer.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Gypsy Moth',
                          subheader: 'Lymantria dispar dispar',
                          icon: 'article',
                          html: '/resources/publications/GypsyMoth.html',
                          thumbnail: '/resources/publications/GypsyMoth.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Gypsy Moth.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Hemlock Woolly Adelgid',
                          subheader: 'Adelges tsugae',
                          icon: 'article',
                          html:
                            '/resources/publications/HemlockWoollyAdelgid.html',
                          thumbnail:
                            '/resources/publications/HemlockWoolly.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Hemlock Woolly Adelgid.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Oak Wilt',
                          subheader: 'Ceratocystis fagacearum',
                          icon: 'article',
                          html: '/resources/publications/OakWilt.html',
                          thumbnail: '/resources/publications/OakWilt.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Oak Wilt.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Polyphagous Shot Hole Borer',
                          subheader: 'Euwallacea sp.',
                          icon: 'article',
                          html:
                            '/resources/publications/PolyphagousShotHoleBorer.html',
                          thumbnail: '/resources/publications/Polyphagous.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Polyphagous Shot Hole Borer.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Sirex Woodwasp',
                          subheader: 'Sirex noctilio',
                          icon: 'article',
                          html: '/resources/publications/SirexWoodwasp.html',
                          thumbnail: '/resources/publications/Woodwasp.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Sirex Woodwasp.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Spotted Lanternfly',
                          subheader: 'Sirex noctilio',
                          icon: 'article',
                          html: '/resources/publications/SirexWoodwasp.html',
                          thumbnail: '',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Spotted Lanternfly.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },

                        {
                          label: 'Sudden Oak Death',
                          subheader: 'Phytophthora ramorum',
                          icon: 'article',
                          html: '/resources/publications/SuddenOakDeath.html',
                          thumbnail: '/resources/publications/SuddenOak.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Sudden Oak Death.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Thousand Canker Disease',
                          subheader: 'Pityophthorus juglandis',
                          icon: 'article',
                          html:
                            '/resources/publications/ThousandCankerDisease.html',
                          thumbnail: '/resources/publications/Thousand.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Thousand Canker Disease.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Viburnum Leaf Beetle',
                          subheader: 'Pyrrhalta viburni',
                          icon: 'article',
                          html:
                            '/resources/publications/ViburnumLeafBeetle.html',
                          thumbnail: '/resources/publications/Viburnum.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Viburnum Leaf Beetle.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                        {
                          label: 'Winter Moth',
                          subheader: 'Operophtera brumata',
                          icon: 'article',
                          html: '/resources/publications/WinterMoth.html',
                          thumbnail: '/resources/publications/WinterMoth.jpg',
                          resource: {
                            english:
                              '/resources/hthc_materials/pest_ppt/TPPD_Winter Moth.pptx',
                            spanish: '',
                            type: 'ppt',
                          },
                        },
                      ],
                    },
                    {
                      label: 'Pest ID Videos',
                      selectable: false,
                      children: [
                        {
                          label: 'Asian Longhorned Beetle',
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/yh6hyMNN0W0',
                            spanish: '',
                            type: 'video',
                          },
                        },
                        {
                          label: 'Emerald Ash Borer',

                          selectable: true,
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/KxDDrCB4n9Q',
                            spanish: '',
                            type: 'video',
                          },
                        },
                        {
                          label: 'Goldspotted Oak Borer',

                          selectable: true,
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/t201pD320w0',
                            spanish: '',
                            type: 'video',
                          },
                        },
                        {
                          label: 'Gypsy Moth',

                          selectable: true,
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/qPWnLd8-XOA',
                            spanish: '',
                            type: 'video',
                          },
                        },
                        {
                          label: 'Hemlock Woolly Adelgid',

                          selectable: true,
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/BDlCw0Qnpvg',
                            spanish: '',
                            type: 'video',
                          },
                        },
                        {
                          label: 'Polyphagous Shot Hole Borer',

                          selectable: true,
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/BDlCw0Qnpvg',
                            spanish: '',
                            type: 'video',
                          },
                        },
                        {
                          label: 'Thousand Cankers Disease',

                          selectable: true,
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/Ul7XGvCezXw',
                            spanish: '',
                            type: 'video',
                          },
                        },
                        {
                          label: 'Winter Moth',

                          selectable: true,
                          icon: 'play_circle',
                          resource: {
                            english:
                              'https://www.youtube.com/embed/kYgA7Lj3uVE?cc_load_policy=1',
                            spanish: '',
                            type: 'video',
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              label:
                'Data Collection Field Guides (For use during training and in the field)',
              selectable: false,
              children: [
                {
                  label: 'Guides (for use while in the field)',
                  selectable: false,
                  children: [
                    {
                      label: 'Tree Health Field Guide',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/Tree Health Field Guide.pdf',
                        spanish:
                          '/resources/hthc_materials_spanish/1.7.Tree Health Field Guide_SPA_Final.pdf',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'Crown Health One Page Guides',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/Crown Health One Page Guides.pdf',
                        spanish:
                          '/resources/hthc_materials_spanish/1.1.Crown Health One Page Guides with Tree Portrait_SPA_Final.pdf',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'DBH Measurement Guide',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/DBH Measurement Guide.pdf',
                        spanish:
                          '/resources/hthc_materials_spanish/1.2.DBH Measurement Guide_SPA_Final.docx',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'Pest Symptom Guides',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/Pest Symptom Guides.pdf',
                        spanish:
                          '/resources/hthc_materials_spanish/2.8.Pest Symptom Guides (Nov 24) SPA. Final.pdf',
                        type: 'pdf',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Steward Trees',
          selectable: false,
          children: [
            {
              label: 'Guides',
              selectable: false,
              children: [
                {
                  label: 'Best Management Practices',
                  selectable: true,
                  children: [
                    {
                      label: 'Right Tree Right Place',
                      labelSpanish: 'Arbol Correcto Lugar Correcto',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/BMP_RightTreeRightPlace.pdf',
                        spanish:
                          '/resources/hthc_materials/BMP_ArbolCorrectoLugarCorrecto.pdf',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'Tools of the Trade',
                      icon: 'article',
                      labelSpanish: 'Los Materiales de la Practica',
                      resource: {
                        english:
                          '/resources/hthc_materials/BMP_ToolsoftheTrade.pdf',
                        spanish:
                          '/resources/hthc_materials/BMP_LosMaterialesdelaPractica.pdf',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'Tree Monitoring Schedule',
                      icon: 'article',
                      labelSpanish: 'Horariode Monitorizacionde Arboles',
                      resource: {
                        english:
                          '/resources/hthc_materials/BMP_TreeMonitoringSchedule.pdf',
                        spanish:
                          '/resources/hthc_materials/BMP_HorariodeMonitorizaciondeArboles.pdf',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'Watering Your Tree',
                      labelSpanish: 'Regandole Aguaa Tu Arbol',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/BMP_WateringYourTree.pdf',
                        spanish:
                          '/resources/hthc_materials/BMP_RegandoleAguaaTuArbol.pdf',
                        type: 'pdf',
                      },
                    },
                  ],
                },
                {
                  label: 'Tree Planting and Stewardship',
                  children: [
                    {
                      label: 'Tree Owners Manual',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/TreeOwnersManualUSFS.pdf',
                        spanish: '',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'USDA Sustaining Americas Urban Trees + Forests',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/UrbanTreesandForests.pdf',
                        spanish: '',
                        type: 'pdf',
                      },
                    },
                  ],
                },
                {
                  label: 'Wallet Cards',
                  children: [
                    {
                      label: 'Eastern States Forest Pests Pocket Guide',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/Eastern States Forest Pests Pocket Guide.pdf',
                        spanish: '',
                        type: 'pdf',
                      },
                    },
                    {
                      label: 'Western States Forest Pests Pocket Guide',
                      icon: 'article',
                      resource: {
                        english:
                          '/resources/hthc_materials/Western States Forest Pests Guide.pdf',
                        spanish: '',
                        type: 'pdf',
                      },
                    },
                  ],
                },
              ],
            },
            {
              label: 'Videos',
              selectable: false,
              children: [
                {
                  label: 'Tree Care Videos',
                  selectable: false,
                  children: [
                    {
                      label: 'Planting',
                      icon: 'play_circle',
                      resource: {
                        english: 'https://www.youtube.com/embed/wvMqCcm3ZJ4',
                        spanish: '',
                        type: 'video',
                      },
                    },
                    {
                      label: 'Stewardship',
                      icon: 'play_circle',
                      resource: {
                        english: 'https://www.youtube.com/embed/3IEoe0X3w08',
                        spanish: '',
                        type: 'video',
                      },
                    },
                    {
                      label: 'Pruning',
                      icon: 'play_circle',
                      resource: {
                        english: 'https://www.youtube.com/embed/M1wVL5InBxM',
                        spanish: '',
                        type: 'video',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label:
            'Learn how others have used the data (case studies and publications)',
          selectable: false,
          children: [
            {
              label: 'Case Studies',
              selectable: false,
              children: [
                {
                  label:
                    'New York City Housing Authority Tree Health Assessment',
                  icon: 'article',
                  selectable: true,
                  resource: {
                    english:
                      '/resources/hthc_materials/case_studies/New York City Housing Authority Tree Health Assessment.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label: 'Hurricane Sandy Tree Health Impacts',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/case_studies/Hurricane Sandy Tree Health Impacts.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label: 'NYC and Philadelphia Street Tree Health Assessment',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/case_studies/NYC and Philadelphia Street Trees Health Assessment.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label: 'Chicago Street Tree Health Assessment',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/case_studies/Chicago Street Tree Health Assessment.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label: 'Oconomowoc Ash Tree Survey',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/case_studies/Occonomowoc Ash Tree Survey.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label: 'Baltimore Congressional Earmark',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/case_studies/Congressional Earmark.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
              ],
            },
            {
              label: 'Publications',
              selectable: false,
              children: [
                {
                  label:
                    'Citizen science and tree health assessment: How useful are the data?',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/publications/nrs_2018_hallett_002.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label:
                    'Assessing the tree health impacts of salt water flooding in coastal cities: A case study in New York City',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/publications/nrs_2018_hallett_001.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
                {
                  label:
                    'New York City Housing Authority’s Urban Forest: A Vital Resource for New York City',
                  icon: 'article',
                  resource: {
                    english:
                      '/resources/hthc_materials/publications/NYCHA_Urban_Forest.pdf',
                    spanish: '',
                    type: 'pdf',
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'Learn more about HTHC',
          selectable: false,
          children: [
            {
              label: 'HTHC Fact Sheet',
              icon: 'article',
              resource: {
                english:
                  '/resources/hthc_materials/training_manual_individual/HTHC Fact Sheet 2023.pdf',
                spanish:
                  '/resources/hthc_materials_spanish/1.5.HTHC Fact Sheet 2023_SPA_Final.pdf',
                type: 'pdf',
              },
            },
            {
              label: 'HTHC Introduction Video(YouTube)',
              icon: 'play_circle',
              resource: {
                english: 'https://www.youtube.com/embed/y-o4kGhYTfw',
                spanish: '',
                type: 'video',
              },
            },
          ],
        },
      ],

      /*  resourcesTreeCopy: [
        {
          label: 'Publications',
          selectable: false,
          icon: 'article',
          children: [
            {
              label: 'Best Management Practices',
              files: [
                {
                  label: 'Right Tree Right Place',
                  subheader:
                    'Follow these guidelines to plant the best tree for your location',
                  html: '/resources/publications/RightTreeRightPlace.html',
                  thumbnail: '/resources/publications/right-tree.gif',
                  PDF: '/resources/downloads/BMP_RightTreeRightPlace.pdf',
                  Español:
                    '/resources/downloads/BMP_ArbolCorrectoLugarCorrecto.pdf',
                },
                {
                  label: 'Tools of the Trade',
                  subheader:
                    'Choose the right tools to plant and care for trees safely and effectively',
                  html: '/resources/publications/ToolsOfTheTrade.html',
                  thumbnail: '/resources/publications/tools.png',
                  PDF: '/resources/downloads/BMP_ToolsoftheTrade.pdf',
                  Español:
                    '/resources/downloads/BMP_LosMaterialesdelaPractica.pdf',
                },
                {
                  label: 'Tree Monitoring Schedule',
                  subheader:
                    "Keep track of your tree's checkups to keep it healthy",
                  html: '/resources/publications/Schedule.html',
                  thumbnail: '/resources/publications/schedule.gif',
                  PDF: '/resources/downloads/BMP_TreeMonitoringSchedule.pdf',
                  Español:
                    '/resources/downloads/BMP_HorariodeMonitorizaciondeArboles.pdf',
                },
                {
                  label: 'Watering Your Tree',
                  subheader:
                    'Learn how and when to water your tree to help it grow and thrive',
                  html: '/resources/publications/WateringYourTree.html',
                  thumbnail: '/resources/publications/watering.png',
                  PDF: '/resources/downloads/BMP_WateringYourTree.pdf',
                  Español: '/resources/downloads/BMP_RegandoleAguaaTuArbol.pdf',
                },
              ],
            },
            {
              label: 'Tree Planting and Stewardship',
              files: [
                {
                  label: 'Tree Owners Manual',
                  subheader:
                    'Follow these guidelines to plant the best tree for your location',
                  thumbnail: '/resources/publications/TreeOwners.png',
                  PDF: '/resources/downloads/TreeOwnersManualUSFS.pdf',
                },
                {
                  label: 'USDA Sustaining Americas Urban Trees + Forests',
                  subheader:
                    'Choose the right tools to plant and care for trees safely and effectively',
                  thumbnail: '/resources/publications/Sustaining.png',
                  PDF: '/resources/downloads/UrbanTreesandForests.pdf',
                },
              ],
            },
            {
              label: 'Pest and Disease Identification',
              files: [
                {
                  label: 'Asian Long Horned Beetle',
                  subheader: 'Anoplophora glabripennis',
                  html: '/resources/publications/AsianLongHornedBeetle.html',
                  thumbnail: '/resources/publications/AsianBeatle.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_AsianLongHornedBeetle.pptx',
                },
                {
                  label: 'Balsam Woolly Adelgid',
                  subheader: 'Adelges piceae',
                  html: '/resources/publications/BalsamWoollyAdelgid.html',
                  thumbnail: '/resources/publications/BalsamWoolly.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Balsam Woolly Adelgid.pptx',
                },
                {
                  label: 'Citrus Greening',
                  subheader: 'Candidatus Liberibacter asiaticus',
                  html: '/resources/publications/CitrusGreening.html',
                  thumbnail: '/resources/publications/CitrusGreening.jpg',
                  powerpoint: '/resources/downloads/TPPD_Citrus Greening.pptx',
                },
                {
                  label: 'Emerald Ash Borer',
                  subheader: 'Agrilus planipennis',
                  html: '/resources/publications/EmeraldAshBorer.html',
                  thumbnail: '/resources/publications/EmeraldAsh.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Emerald Ash Borer.pptx',
                },
                {
                  label: 'Gold Spotted Oak Borer',
                  subheader: 'Agrilus coxalis',
                  html: '/resources/publications/GoldSpottedOakBorer.html',
                  thumbnail: '/resources/publications/GoldSpotted.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Gold Spotted Oak Borer.pptx',
                },
                {
                  label: 'Gypsy Moth',
                  subheader: 'Lymantria dispar dispar',
                  html: '/resources/publications/GypsyMoth.html',
                  thumbnail: '/resources/publications/GypsyMoth.jpg',
                  powerpoint: '/resources/downloads/TPPD_Gypsy Moth.pptx',
                },
                {
                  label: 'Hemlock Woolly Adelgid',
                  subheader: 'Adelges tsugae',
                  html: '/resources/publications/HemlockWoollyAdelgid.html',
                  thumbnail: '/resources/publications/HemlockWoolly.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Hemlock Woolly Adelgid.pptx',
                },
                {
                  label: 'Oak Wilt',
                  subheader: 'Ceratocystis fagacearum',
                  html: '/resources/publications/OakWilt.html',
                  thumbnail: '/resources/publications/OakWilt.jpg',
                  powerpoint: '/resources/downloads/TPPD_Oak Wilt.pptx',
                },
                {
                  label: 'Polyphagous Shot Hole Borer',
                  subheader: 'Euwallacea sp.',
                  html: '/resources/publications/PolyphagousShotHoleBorer.html',
                  thumbnail: '/resources/publications/Polyphagous.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Polyphagous Shot Hole Borer.pptx',
                },
                {
                  label: 'Sirex Woodwasp',
                  subheader: 'Sirex noctilio',
                  html: '/resources/publications/SirexWoodwasp.html',
                  thumbnail: '/resources/publications/Woodwasp.jpg',
                  powerpoint: '/resources/downloads/TPPD_Sirex Woodwasp.pptx',
                },

                {
                  label: 'Sudden Oak Death',
                  subheader: 'Phytophthora ramorum',
                  html: '/resources/publications/SuddenOakDeath.html',
                  thumbnail: '/resources/publications/SuddenOak.jpg',
                  powerpoint: '/resources/downloads/TPPD_Sudden Oak Death.pptx',
                },
                {
                  label: 'Thousand Canker Disease',
                  subheader: 'Pityophthorus juglandis',
                  html: '/resources/publications/ThousandCankerDisease.html',
                  thumbnail: '/resources/publications/Thousand.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Thousand Canker Disease.pptx',
                },
                {
                  label: 'Viburnum Leaf Beetle',
                  subheader: 'Pyrrhalta viburni',
                  html: '/resources/publications/ViburnumLeafBeetle.html',
                  thumbnail: '/resources/publications/Viburnum.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Viburnum Leaf Beetle.pptx',
                },
                {
                  label: 'Winter Moth',
                  subheader: 'Operophtera brumata',
                  html: '/resources/publications/WinterMoth.html',
                  thumbnail: '/resources/publications/WinterMoth.jpg',
                  powerpoint: '/resources/downloads/TPPD_Winter Moth.pptx',
                },
              ],
            },
            {
              label: 'Wallet Cards',
              files: [
                {
                  label: 'Eastern States Forest Pests Pocket Guide',
                  subheader:
                    "Refer to this wallet-sized guide if you think you've spotted one of these 6 major tree pests/diseases affecting trees in the Eastern US",
                  html: '/resources/publications/EasternUS.html',
                  thumbnail: '/resources/publications/easternus.png',
                },
                {
                  label: 'Western States Forest Pests Guide',
                  subheader:
                    "Refer to this wallet-sized guide if you think you've spotted one of these 6 major tree pests/diseases affecting trees in the Western US",
                  html: '/resources/publications/WesternUS.html',
                  thumbnail: '/resources/publications/westernus.png',
                },
              ],
            },
          ],
        },
        {
          label: 'Videos',
          selectable: false,
          expandable: true,
          icon: 'play_circle',
          children: [
            {
              label: 'Training Videos',
              selectable: false,
              expandable: true,
              children: [
                {
                  label: 'Introduction',
                  url: 'https://www.youtube.com/embed/y-o4kGhYTfw',
                  selectable: true,
                },
                {
                  label: 'How to calculate DBH',
                  url: 'https://www.youtube.com/embed/yoMkfPpS_b0',
                  selectable: true,
                },
                {
                  label: 'Fine Twig Dieback',
                  url: 'https://www.youtube.com/embed/xO1NqQcG4wM',
                  selectable: true,
                },
                {
                  label: 'Leaf Discoloration',
                  url: 'https://www.youtube.com/embed/lNi6-kkjHgY',
                  selectable: true,
                },
                {
                  label: 'Leaf Defoliation',
                  url: 'https://www.youtube.com/embed/SZnqGwXplxU',
                  selectable: true,
                },
                {
                  label: 'Crown Vigor',
                  url: 'https://www.youtube.com/embed/V90Q1jAOIOI',
                  selectable: true,
                },
                {
                  label: 'Crown Transparency',
                  url: 'https://www.youtube.com/embed/Fi88Uy8CPiw',
                  selectable: true,
                },
                {
                  label: 'Crown Light Exposure',
                  url: 'https://www.youtube.com/embed/zyIyBx11Bh8',
                  selectable: true,
                },
              ],
            },
            {
              label: 'Tree Care Videos',
              selectable: false,
              children: [
                {
                  label: 'Planting',
                  url: 'https://www.youtube.com/embed/wvMqCcm3ZJ4',
                  selectable: true,
                },
                {
                  label: 'Stewardship',
                  url: 'https://www.youtube.com/embed/3IEoe0X3w08',
                  selectable: true,
                },
                {
                  label: 'Pruning',
                  url: 'https://www.youtube.com/embed/M1wVL5InBxM',
                  selectable: true,
                },
              ],
            },
            {
              label: 'Pest ID Videos',
              selectable: false,
              children: [
                {
                  label: 'Asian Longhorned Beetle',
                  url: 'https://www.youtube.com/embed/yh6hyMNN0W0',
                  selectable: true,
                },
                {
                  label: 'Emerald Ash Borer',
                  url: 'https://www.youtube.com/embed/KxDDrCB4n9Q',
                  selectable: true,
                },
                {
                  label: 'Goldspotted Oak Borer',
                  url: 'https://www.youtube.com/embed/t201pD320w0',
                  selectable: true,
                },
                {
                  label: 'Gypsy Moth',
                  url: 'https://www.youtube.com/embed/qPWnLd8-XOA',
                  selectable: true,
                },
                {
                  label: 'Hemlock Woolly Adelgid',
                  url: 'https://www.youtube.com/embed/BDlCw0Qnpvg',
                  selectable: true,
                },
                {
                  label: 'Polyphagous Shot Hole Borer',
                  url: 'https://www.youtube.com/embed/BDlCw0Qnpvg',
                  selectable: true,
                },
                {
                  label: 'Thousand Cankers Disease',
                  url: 'https://www.youtube.com/embed/Ul7XGvCezXw',
                  selectable: true,
                },
                {
                  label: 'Winter Moth',
                  url: 'https://www.youtube.com/embed/kYgA7Lj3uVE',
                  selectable: true,
                },
              ],
            },
          ],
        },
      ], */
    };
  },
  methods: {
    clearAll() {
      this.selectedPublication = '';
      this.selectedTitle = '';
    },
    downloadFile(path, name) {
      let link = document.createElement('a');
      link.download = name;
      link.href = path;
      link.click();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.q-tree__node {
  padding: 0 0 0 0 !important;
}
.q-tree__node-body {
  padding: 0 0 0 0 !important;
}
</style>
