<template>
  <q-form @submit="onSubmit" @reset="onReset" class="bg-white q-ma-md">
    <!--tree info-->

    <!-- pest detection form-->
    <q-item class="text-body1 text-center">
      <q-item-section>
        <div class="q-mt-xs q-mb-none q-pb-none text-body2 text-bold">
          {{ selectedTree.tree_name }}
        </div>
        <div class="text-body2 q-mt-xs q-mb-none q-pb-none">
          {{ selectedTree.common_name }}
        </div>
        <div class="text-caption q-mt-none q-pt-none">
          {{ selectedTree.scientific_name }}
        </div>
      </q-item-section>
    </q-item>
    <q-card class="row q-ma-md q-pa-md shadow-1">
      <q-input
        color="secondary"
        class="q-mb-sm"
        outlined
        square
        v-model="tDBH"
        label="DBH (in)"
        style="font-size: 16px; width: 100%"
        :disable="createdVisit ? false : true"
      >
        <template v-slot:after>
          <q-btn
            padding-xs
            round
            dense
            flat
            icon="info"
            color="blue-grey-9"
            @click="
              this.$store.commit('updateShowHelp', {
                url: '/help/DBH_Flowcharts.pdf',
                filename: 'DBH_Flowcharts.pdf',
              })
            "
          /> </template
      ></q-input>
    </q-card>
    <!-- pest selects -->
    <q-card
      v-for="(select, index) in pestSelects"
      :key="index"
      class="row q-ma-md q-pa-md shadow-1"
    >
      <div class="col-11">
        <q-select
          dense
          filled
          v-model="select.model"
          :options="select.options"
          :label="select.label"
          color="primary"
          clearable
          options-selected-class="text-deep-orange"
          :bg-color="select.model == '' || select.model == null ? '' : 'white'"
          emit-value
          :disable="createdVisit ? false : true"
        >
        </q-select>
      </div>
      <div class="col-1 self-center text-right">
        <q-btn
          color="blue-grey-9"
          round
          padding="xs"
          flat
          icon="info"
          @click="
            this.$store.commit('updateShowHelp', {
              url: select.help,
              filename: select.help.split('/')[2],
            })
          "
        >
        </q-btn>
      </div>
    </q-card>
    <!-- pest buttons-->
    <q-card
      v-for="(btn, index) in pestBtns"
      :key="index"
      class="row q-ma-md q-pa-md shadow-1"
    >
      <div class="col-11 text-left">
        {{ btn.label }}
        <q-option-group
          v-model="btn.model"
          :options="btn.options"
          color="primary"
          inline
          :disable="createdVisit ? false : true"
        />
      </div>
      <div class="col-1 self-center text-right">
        <q-btn
          color="blue-grey-9"
          round
          padding="xs"
          flat
          icon="info"
          @click="
            this.$store.commit('updateShowHelp', {
              url: btn.help,
              filename: btn.help.split('/')[2],
            })
          "
        >
        </q-btn>
      </div>
    </q-card>
    <!--height-->
    <div class="q-mt-md q-mx-md q-mb-sm">
      <q-input
        outlined
        square
        v-model="tHeight"
        label="Tree Height (feet) - Optional"
        style="font-size: 16px"
        class="bg-white"
        :disable="createdVisit ? false : true"
      />
    </div>
    <!--notes-->
    <div class="q-mt-md q-mx-md q-mb-sm">
      <q-input
        v-model="tNotes"
        autogrow
        outlined
        label="Notes"
        style="font-size: 16px"
        class="bg-white"
        :disable="createdVisit ? false : true"
      />
    </div>
    <!--photos-->
    <!-- <q-item style="border-bottom: 1px solid lightgrey">
        <q-uploader
          url="http://localhost:4444/upload"
          multiple
          accept=".jpg, image/*"
          @rejected="onRejected"
          @added="handleFileAdded"
          style="width: 100%"
          class="shadow-1"
        >
          <template v-slot:header="scope">
            <div
              class="row no-wrap items-center q-pa-sm bg-blue-grey-1 text-primary"
            >
              <q-btn
                v-if="scope.uploadedFiles.length > 0"
                icon="done_all"
                @click="scope.removeUploadedFiles"
                round
                dense
                flat
              >
                <q-tooltip>Remove Uploaded Files</q-tooltip>
              </q-btn>
              <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />

              <div class="col text-left">
                <div class="q-uploader__title">Add Photos</div>
              </div>
              <q-btn
                v-if="scope.canAddFiles"
                type="a"
                icon="photo_camera"
                @click="scope.pickFiles"
                round
                dense
                flat
                size="lg"
              >
                <q-uploader-add-trigger />
                <q-tooltip>Pick Files</q-tooltip>
              </q-btn>
              <q-btn
                v-if="scope.canUpload"
                icon="cloud_upload"
                @click="uploadPhoto"
                round
                dense
                flat
                size="lg"
              >
                <q-tooltip>Upload Files</q-tooltip>
              </q-btn>

              <q-btn
                v-if="scope.isUploading"
                icon="clear"
                @click="scope.abort"
                round
                dense
                flat
              >
                <q-tooltip>Abort Upload</q-tooltip>
              </q-btn>
            </div>
          </template></q-uploader
        >
      </q-item> -->
    <!--submit/cancel-->
    <div class="q-mb-xl q-mt-md">
      <q-btn label="Submit" type="submit" color="primary" v-if="createdVisit" />
    </div>
  </q-form>
</template>

<script>
export default {
  name: 'FormPestDetection',
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    selectedTree() {
      return this.$store.state.treesStore.selectedTree;
    },
    createdVisit() {
      let createdVisit = false;
      if (this.$store.state.treesStore.selectedVisitDetails.created_visit) {
        createdVisit = true;
      }

      return createdVisit;
    },
  },
  data() {
    return {
      treeInfoExpanded: true,
      pestExpanded: true,
      tDBH: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.dbh
        : null,
      tHeight: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.treeheight
        : null,
      tNotes: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.notes
        : null,
      tPhoto: '',
      pestSelects: this.$store.state.treesStore.editTreeMode
        ? [
            {
              label: 'Exit holes',
              options: [
                { label: 'None', value: 'None' },
                {
                  label: 'Pencil-width round exit holes',
                  value: 'Pencil-width round exit holes',
                },
                {
                  label: 'Small, D-shaped exit holes',
                  value: 'Small, D-shaped exit holes',
                },
                {
                  label:
                    'Multiple exit holes roughly the size of a pen tip (resembling shotgun spray)',
                  value:
                    'Multiple exit holes roughly the size of a pen tip (resembling shotgun spray)',
                },
                {
                  label:
                    'Tiny holes surrounded by cankers (or abnormal growth)',
                  value:
                    'Tiny holes surrounded by cankers (or abnormal growth)',
                },
                {
                  label: 'Other',
                  value: 'Other',
                },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .exit_holes,
              help: '/help/pest_detection/exitholes-pager.pdf',
            },
            {
              label: 'Exudation',
              options: [
                {
                  label: 'None',
                  value: 'None',
                },
                {
                  label: 'Red or black staining ("ooze")',
                  value: 'Red or black staining ("ooze")',
                },
                {
                  label: 'Black fungal mat present below cracked bark',
                  value: 'Black fungal mat present below cracked bark',
                },
                {
                  label:
                    'Brown fungal mat present in sapwood (internal tree, only visible through craked bark)',
                  value:
                    'Brown fungal mat present in sapwood (internal tree, only visible through craked bark)',
                },
                { label: 'Apple cider odor', value: 'Apple cider odor' },
                {
                  label: 'Wet or dry discoloration of bark around exit holes',
                  value: 'Wet or dry discoloration of bark around exit holes',
                },
                {
                  label:
                    'White powdery substance around exit holes (sometimes called "sugar volcanoes")',
                  value:
                    'White powdery substance around exit holes (sometimes called "sugar volcanoes")',
                },
                {
                  label:
                    'Black or reddish ooze around cankers (3 ft to 6 ft off the ground)',
                  value:
                    'Black or reddish ooze around cankers (3 ft to 6 ft off the ground)',
                },
                {
                  label: 'White woolly egg masses on the undersides of leaves',
                  value: 'White woolly egg masses on the undersides of leaves',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .exudation,
              help: '/help/pest_detection/exudation-pager.pdf',
            },
            {
              label: 'Egg sites / Eggs',
              options: [
                {
                  label: 'None',
                  value: 'None',
                },
                {
                  label: 'Waxy, woolly white egg masses at base of needles',
                  value: 'Waxy, woolly white egg masses at base of needles',
                },
                {
                  label: 'Fluffy brown egg masses on trunk',
                  value: 'Fluffy brown egg masses on trunk',
                },
                {
                  label: 'Clusters of small oval eggs',
                  value: 'Clusters of small oval eggs',
                },
                {
                  label: 'Shallow, round discolored "divots"',
                  value: 'Shallow, round discolored "divots"',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails.eggs,
              help: '/help/pest_detection/eggsite_eggs-pager.pdf',
            },
            {
              label: 'Appearance of adult insect or larvae',
              options: [
                { label: 'None', value: 'None' },
                {
                  label: 'Moth with white to light brown wings',
                  value: 'Moth with white to light brown wings',
                },
                {
                  label: 'Black beetle with white spots and long antennae',
                  value: 'Black beetle with white spots and long antennae',
                },
                {
                  label: 'Small emerald green beetle',
                  value: 'Small emerald green beetle',
                },
                {
                  label:
                    'Small brown beetle with orange spots on its outer wings',
                  value:
                    'Small brown beetle with orange spots on its outer wings',
                },
                {
                  label:
                    'Caterpillar with three sets of blue spots, six sets of red spots',
                  value:
                    'Caterpillar with three sets of blue spots, six sets of red spots',
                },
                {
                  label: 'Tiny brown beetle (PSHB)',
                  value: 'Tiny brown beetle (PSHB)',
                },
                {
                  label: 'Bright green winged insect (Citrus psyllid)',
                  value: 'Bright green winged insect (Citrus psyllid)',
                },
                {
                  label: 'Other',
                  value: 'Other',
                },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .adult_insect_or_larva_appearance,
              help: '/help/pest_detection/adultinsects-pager.pdf',
            },
            {
              label: 'Damaged fruits / tree buds',
              options: [
                { label: 'None', value: 'None' },
                { label: 'Misshapen fruits', value: 'Misshapen fruits' },
                {
                  label: 'Discolored fruits',
                  value: 'Discolored fruits',
                },
                {
                  label: 'Buds appear shriveled',
                  value: 'Buds appear shriveled',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .fruit_damage,
              help: '/help/pest_detection/damagedfruits-pager.pdf',
            },
            {
              label: 'Holes in leaves',
              options: [
                { label: 'None', value: 'None' },
                {
                  label: 'Holes on leaves are near outer edge of leaf',
                  value: 'Holes on leaves are near outer edge of leaf',
                },
                {
                  label:
                    'Holes are closer to the central vein (midrib) of the leaf',
                  value:
                    'Holes are closer to the central vein (midrib) of the leaf',
                },
                {
                  label: 'Both',
                  value: 'Both',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .holes_in_leaves,
              help: '/help/pest_detection/holesinleave-pager.pdf',
            },
          ]
        : [
            {
              label: 'Exit holes',
              options: [
                { label: 'None', value: 'None' },
                {
                  label: 'Pencil-width round exit holes',
                  value: 'Pencil-width round exit holes',
                },
                {
                  label: 'Small, D-shaped exit holes',
                  value: 'Small, D-shaped exit holes',
                },
                {
                  label:
                    'Multiple exit holes roughly the size of a pen tip (resembling shotgun spray)',
                  value: 3,
                },
                {
                  label:
                    'Tiny holes surrounded by cankers (or abnormal growth)',
                  value:
                    'Tiny holes surrounded by cankers (or abnormal growth)',
                },
                {
                  label: 'Other',
                  value: 'Other',
                },
              ],
              model: null,
              help: '/help/pest_detection/exitholes-pager.pdf',
            },
            {
              label: 'Exudation',
              options: [
                {
                  label: 'None',
                  value: 'None',
                },
                {
                  label: 'Red or black staining ("ooze")',
                  value: 'Red or black staining ("ooze")',
                },
                {
                  label: 'Black fungal mat present below cracked bark',
                  value: 'Black fungal mat present below cracked bark',
                },
                {
                  label:
                    'Brown fungal mat present in sapwood (internal tree, only visible through craked bark)',
                  value:
                    'Brown fungal mat present in sapwood (internal tree, only visible through craked bark)',
                },
                { label: 'Apple cider odor', value: 'Apple cider odor' },
                {
                  label: 'Wet or dry discoloration of bark around exit holes',
                  value: 'Wet or dry discoloration of bark around exit holes',
                },
                {
                  label:
                    'White powdery substance around exit holes (sometimes called "sugar volcanoes")',
                  value:
                    'White powdery substance around exit holes (sometimes called "sugar volcanoes")',
                },
                {
                  label:
                    'Black or reddish ooze around cankers (3 ft to 6 ft off the ground)',
                  value:
                    'Black or reddish ooze around cankers (3 ft to 6 ft off the ground)',
                },
                {
                  label: 'White woolly egg masses on the undersides of leaves',
                  value: 'White woolly egg masses on the undersides of leaves',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: null,
              help: '/help/pest_detection/exudation-pager.pdf',
            },
            {
              label: 'Egg sites / Eggs',
              options: [
                {
                  label: 'None',
                  value: 'None',
                },
                {
                  label: 'Waxy, woolly white egg masses at base of needles',
                  value: 'Waxy, woolly white egg masses at base of needles',
                },
                {
                  label: 'Fluffy brown egg masses on trunk',
                  value: 'Fluffy brown egg masses on trunk',
                },
                {
                  label: 'Clusters of small oval eggs',
                  value: 'Clusters of small oval eggs',
                },
                {
                  label: 'Shallow, round discolored "divots"',
                  value: 'Shallow, round discolored "divots"',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: null,
              help: '/help/pest_detection/eggsite_eggs-pager.pdf',
            },
            {
              label: 'Appearance of adult insect or larvae',
              options: [
                { label: 'None', value: 'None' },
                {
                  label: 'Moth with white to light brown wings',
                  value: 'Moth with white to light brown wings',
                },
                {
                  label: 'Black beetle with white spots and long antennae',
                  value: 'Black beetle with white spots and long antennae',
                },
                {
                  label: 'Small emerald green beetle',
                  value: 'Small emerald green beetle',
                },
                {
                  label:
                    'Small brown beetle with orange spots on its outer wings',
                  value:
                    'Small brown beetle with orange spots on its outer wings',
                },
                {
                  label:
                    'Caterpillar with three sets of blue spots, six sets of red spots',
                  value:
                    'Caterpillar with three sets of blue spots, six sets of red spots',
                },
                {
                  label: 'Tiny brown beetle (PSHB)',
                  value: 'Tiny brown beetle (PSHB)',
                },
                {
                  label: 'Bright green winged insect (Citrus psyllid)',
                  value: 'Bright green winged insect (Citrus psyllid)',
                },
                {
                  label: 'Other',
                  value: 'Other',
                },
              ],
              model: null,
              help: '/help/pest_detection/adultinsects-pager.pdf',
            },
            {
              label: 'Damaged fruits / tree buds',
              options: [
                { label: 'None', value: 'None' },
                { label: 'Misshapen fruits', value: 'Misshapen fruits' },
                {
                  label: 'Discolored fruits',
                  value: 'Discolored fruits',
                },
                {
                  label: 'Buds appear shriveled',
                  value: 'Buds appear shriveled',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: null,
              help: '/help/pest_detection/damagedfruits-pager.pdf',
            },
            {
              label: 'Holes in leaves',
              options: [
                { label: 'None', value: 'None' },
                {
                  label: 'Holes on leaves are near outer edge of leaf',
                  value: 'Holes on leaves are near outer edge of leaf',
                },
                {
                  label:
                    'Holes are closer to the central vein (midrib) of the leaf',
                  value:
                    'Holes are closer to the central vein (midrib) of the leaf',
                },
                {
                  label: 'Both',
                  value: 'Both',
                },
                { label: 'Other', value: 'Other' },
              ],
              model: null,
              help: '/help/pest_detection/holesinleave-pager.pdf',
            },
          ],
      pestBtns: this.$store.state.treesStore.editTreeMode
        ? [
            {
              label: 'Frass',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails.frass,
              help: '/help/pest_detection/frass-pager.pdf',
            },
            {
              label: 'Epicormic Sprouts',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .epicormic_sprouts,
              help: '/help/pest_detection/epicorimicsprouts-pager.pdf',
            },
            {
              label: 'Blonding',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails.blonding,
              help: '/help/pest_detection/bonding-pager.pdf',
            },
            {
              label: 'S-shaped Galleries',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .s_shaped_galleries,
              help: '/help/pest_detection/sshaped-pager.pdf',
            },
            {
              label: 'Bark Fissures',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .bark_fissures,
              help: '/help/pest_detection/barkfissures-pager.pdf',
            },
            {
              label: 'Cankers / Dead Bark',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .dead_bark,
              help: '/help/pest_detection/cankers-pager.pdf',
            },
            {
              label: 'Galls on Twigs / Branches / Leaves',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails.galls,
              help: '/help/pest_detection/gailontwigs-pager.pdf',
            },
            {
              label: 'Fine Twig Dieback / Dead Twigs',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .dead_twigs,
              help: '/help/pest_detection/finetwigdieback-pager.pdf',
            },
            {
              label: 'Leaf Discoloration (deciduous)',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .discoloration_deciduous,
              help: '/help/pest_detection/leafdiscolor-pager.pdf',
            },
            {
              label: 'Needle Discoloration',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .discoloration_conifer,
              help: '/help/pest_detection/needlediscolor-pager.pdf',
            },
            {
              label: 'Wilted or Browning Leaves',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .wilted_leaves,
              help: '/help/pest_detection/wiltedbrownleaves-pager.pdf',
            },
            {
              label: 'Premature Leaf Loss',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: this.$store.state.treesStore.selectedVisitDetails
                .premature_leaf_loss,
              help: '/help/pest_detection/prematureleafloss_pager.pdf',
            },
          ]
        : [
            {
              label: 'Frass',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/frass-pager.pdf',
            },
            {
              label: 'Epicormic Sprouts',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/epicorimicsprouts-pager.pdf',
            },
            {
              label: 'Blonding',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/bonding-pager.pdf',
            },
            {
              label: 'S-shaped Galleries',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/sshaped-pager.pdf',
            },
            {
              label: 'Bark Fissures',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/barkfissures-pager.pdf',
            },
            {
              label: 'Cankers / Dead Bark',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/cankers-pager.pdf',
            },
            {
              label: 'Galls on Twigs / Branches / Leaves',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/gailontwigs-pager.pdf',
            },
            {
              label: 'Fine Twig Dieback / Dead Twigs',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/finetwigdieback-pager.pdf',
            },
            {
              label: 'Leaf Discoloration (deciduous)',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/leafdiscolor-pager.pdf',
            },
            {
              label: 'Needle Discoloration',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/needlediscolor-pager.pdf',
            },
            {
              label: 'Wilted or Browning Leaves',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/wiltedbrownleaves-pager.pdf',
            },
            {
              label: 'Premature Leaf Loss',
              options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
              model: '',
              help: '/help/pest_detection/prematureleafloss_pager.pdf',
            },
          ],
    };
  },
  methods: {
    onRejected() {
      console.log('rejected');
    },
    handleFileAdded() {
      console.log('file added');
    },
    onSubmit() {
      this.handleResize();
      console.log(this.pestSelects[0].model);
      let obj = {
        visitId: this.$store.state.treesStore.selectedVisitDetails.visit_id,
        projectId: this.selectedTree.project_id,
        treeId: this.selectedTree.tree_id,
        inDbh: this.tDBH,
        inTreeheight: this.tHeight,
        inNotes: this.tNotes,
        exitHoles: this.pestSelects[0].model,
        inExudation: this.pestSelects[1].model,
        inEggs: this.pestSelects[2].model,
        adultInsectOrLarvaAppearance: this.pestSelects[3].model,
        fruitDamage: this.pestSelects[4].model,
        holesInLeaves: this.pestSelects[5].model,
        inFrass: this.pestBtns[0].model,
        epicormicSprouts: this.pestBtns[1].model,
        blonding: this.pestBtns[2].model,
        sShapedGalleries: this.pestBtns[3].model,
        barkFissures: this.pestBtns[4].model,
        deadBark: this.pestBtns[5].model,
        inGalls: this.pestBtns[6].model,
        deadTwigs: this.pestBtns[7].model,
        discolorationDeciduous: this.pestBtns[8].model,
        discolorationConifer: this.pestBtns[9].model,
        wiltedLeaves: this.pestBtns[10].model,
        prematureLeafLoss: this.pestBtns[11].model,
      };
      if (this.$store.state.treesStore.editTreeMode) {
        this.$store.dispatch('editPestDetection', obj);
      } else {
        this.$store.dispatch('insertPestDetection', obj);
      }
      console.log(obj);
    },
    onReset() {
      this.handleResize();
      this.tDBH = null;
      this.tHeight = null;
      this.tNotes = null;
      this.tPhoto = null;
      this.pestSelects[0].model = null;
      this.pestSelects[1].model = null;
      this.pestSelects[2].model = null;
      this.pestSelect[3].model = null;
      this.pestSelects[4].model = null;
      this.pestSelects[5].model = null;
      this.pestBtns[0].model = null;
      this.pestBtns[1].model = null;
      this.pestBtns[2].model = null;
      this.pestBtns[3].model = null;
      this.pestBtns[4].model = null;
      this.pestBtns[5].model = null;
      this.pestBtns[6].model = null;
      this.pestBtns[7].model = null;
      this.pestBtns[8].model = null;
      this.pestBtns[9].model = null;
      this.pestBtns[10].model = null;
      this.pestBtns[11].model = null;
    },
    //what this does is reset the size of the viewport.  This handles the issue on mobile
    //where the browser resets the size of the viewport in order to move the input up above
    //the virtual keyboard.  Setting the font size to 16px prevents it froom zooming.
    handleResize() {
      const currentViewportHeight = window.visualViewport.height;
      const heightDifference =
        this.originalViewportHeight - currentViewportHeight;
      window.scrollTo(0, window.pageYOffset + heightDifference);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
