<template>
  <q-toolbar class="bg-blue-grey-9 text-white">
    <q-btn
      icon="menu"
      flat
      round
      color=""
      @click="toggleDrawer"
      v-if="$q.screen.lt.md"
    >
    </q-btn>
    <q-toolbar-title class="text-subtitle1"
      >Find a Project to Join</q-toolbar-title
    >
    <q-space></q-space>

    <q-btn padding="xs" round flat color="white" icon="help"
      ><q-menu class="">
        <div class="q-ma-md">
          Find a project to join
        </div></q-menu
      ></q-btn
    >
  </q-toolbar>

  <!-- find projects-->
  <div
    class="bg-white q-pa-md shadow-1"
    style="height:calc(100vh - 86px); overflow-y: clip;"
  >
    <p class="text-body2 text-bold q-mb-none">Choose search option:</p>
    <q-radio
      v-model="searchOption"
      val="nearby"
      color="primary"
      label="Nearby"
      @click="checkLocation"
    />
    <q-radio v-model="searchOption" val="name" label="Project Name" />
    <div v-if="searchOption == 'name'">
      <q-form @submit="searchByProjectName()" class="q-ma-lg">
        <q-input
          class="q-my-md"
          outlined
          label="search by project name"
          v-model="searchterm"
          clearable
          dense
          @clear="
            this.$store.commit('updateProjectSearchResults', []);
            this.showTextSearchResults = false;
          "
        >
          <template v-slot:after>
            <q-btn
              flat
              size="md"
              :color="searchterm ? 'primary' : 'secondary'"
              :text-color="searchterm ? 'primary' : 'secondary'"
              icon="search"
              unelevated
              @click="searchByProjectName()"
              >search</q-btn
            >
          </template>
        </q-input>
      </q-form>
      <div v-if="showTextSearchResults">
        <q-list
          v-if="this.$store.state.userStore.projectSearchResults.length > 0"
        >
          <q-toolbar class="bg-blue-grey-1 q-pa-sm q-mt-md">
            <span class="text-subtitle2">
              Search Results:
            </span>
            <q-space></q-space>
            <div>
              {{ this.$store.state.userStore.projectSearchResults.length }}
              projects found
            </div>
          </q-toolbar>
          <q-scroll-area style="height: calc(100vh - 320px)">
            <q-item
              v-for="(proj, key) in this.$store.state.userStore
                .projectSearchResults"
              :key="key"
              class="text-left"
              style="border-top: 1px solid darkgreen"
            >
              <q-item-section avatar>
                <q-avatar color="secondary" text-color="white"
                  ><q-icon name="forest"></q-icon>
                </q-avatar>
              </q-item-section>

              <q-item-section class="text-body1 text-bold"
                >{{ proj.project_name }}
                <br />

                <q-item-label class="text-caption">
                  {{ proj.description }}
                </q-item-label></q-item-section
              >
              <!--q-item-section
                v-if="proj.project_user_relationship == 'AT Project Leader'"
              >
                <q-btn
                  color="secondary"
                  icon="sym_s_pending_actions"
                  flat
                  @click="
                    this.$store.dispatch('getMemberRequests', proj.project_id)
                  "
                  ><span class="text-caption">approve members</span></q-btn
                >
              </q-item-section-->
              <q-item-section side>
                <q-btn
                  v-if="proj.project_user_relationship == 'Ask to Join'"
                  color="primary"
                  label="Ask to Join"
                  @click="joinProject(proj)"
                ></q-btn>
                <div
                  v-if="proj.project_user_relationship == 'Approval Pending'"
                  class="text-red text-caption"
                >
                  APPROVAL PENDING
                </div>
                <div
                  v-if="proj.project_user_relationship == 'AT Project Leader'"
                  class="text-blue-grey-9 text-caption"
                >
                  PROJECT LEADER
                </div>
                <div
                  v-if="proj.project_user_relationship == 'Project Leader'"
                  class="text-blue-grey-9 text-caption"
                >
                  PROJECT LEADER
                </div>
                <div
                  v-if="proj.project_user_relationship == 'Participant'"
                  class="text-blue-grey-9 text-caption"
                >
                  PARTICIPANT
                </div>
              </q-item-section>
            </q-item>
          </q-scroll-area>
        </q-list>
        <div v-else class="text-primary text-subtitle1 q-ma-md">
          No projects were found using the text provided. Consider changing the
          text or chaning the search option to Nearby to find projects in your
          area.
        </div>
      </div>
    </div>
    <q-list v-if="searchOption == 'nearby'">
      <q-toolbar class="row bg-blue-grey-1 q-pa-sm q-mt-md">
        <div style="width: 120px" class="col-2 self-center text-subtitle2">
          Projects Within:
        </div>
        <div class="col-2 self-center">
          <q-select
            dense
            :options="distanceOptions"
            v-model="distance"
            style="width: 95px"
            @update:model-value="getNearbyProjects()"
          ></q-select>
        </div>
        <q-space></q-space>

        {{ this.nearbyProjects.length }} projects found
      </q-toolbar>
      <q-scroll-area style="height: calc(100vh - 250px)">
        <div v-if="locationFound">
          <div v-if="this.nearbyProjects.length > 0">
            <q-item
              v-for="(proj, key) in this.nearbyProjects"
              :key="key"
              class="text-left"
              style="border-top: 1px solid darkgreen"
            >
              <q-item-section avatar>
                <q-avatar color="secondary" text-color="white"
                  ><q-icon name="forest"></q-icon>
                </q-avatar>
              </q-item-section>

              <q-item-section class="text-body1 text-bold"
                >{{ proj.project_name }}
                <br />
                <q-item-label class="text-caption">
                  {{ proj.description }}
                </q-item-label></q-item-section
              >

              <q-item-section side>
                <q-btn
                  v-if="proj.project_user_relationship == 'Ask to Join'"
                  color="primary"
                  label="Ask to Join"
                  @click="joinProject(proj)"
                ></q-btn>
                <div
                  v-if="proj.project_user_relationship == 'Approval Pending'"
                  class="text-red text-caption"
                >
                  APPROVAL PENDING
                </div>
                <div
                  v-if="proj.project_user_relationship == 'AT Project Leader'"
                  class="text-blue-grey-9 text-caption"
                >
                  PROJECT LEADER
                </div>
                <div
                  v-if="proj.project_user_relationship == 'Project Leader'"
                  class="text-blue-grey-9 text-caption"
                >
                  PROJECT LEADER
                </div>
                <div
                  v-if="proj.project_user_relationship == 'Participant'"
                  class="text-blue-grey-9 text-caption"
                >
                  PARTICIPANT
                </div>
              </q-item-section>
            </q-item>
          </div>
          <div v-else class="text-primary text-subtitle1 q-ma-md">
            No projects were found within your search radius. Consider
            increasing your search radius or changing the search option to
            Project Name.
          </div>
        </div>
        <div v-else class="row items-center q-mt-sm">
          <q-spinner-rings color="primary" size="4em" />
          <div class="text-primary text-h6">..Finding Location</div>
        </div>
      </q-scroll-area>
    </q-list>
  </div>
</template>

<script>
import * as turf from '@turf/turf';

export default {
  components: {},
  name: 'DashboardJoinProject',
  computed: {
    userLocation() {
      return this.$store.state.userStore.usersLocation;
    },
    knownLocation() {
      return this.$store.state.userStore.knownLocation;
    },
    nearbyProjects() {
      return this.$store.state.userStore.nearbyProjects;
    },
    showApprovals: {
      get() {
        return this.$store.state.userStore.showApprovals;
      },
      set(value) {
        this.$store.commit('updateShowApprovals', value);
      },
    },
  },
  activated() {
    console.log('activated');
    //open most recent project in session
  },
  data() {
    return {
      projectSelected: '',
      searchOption: 'name',
      searchterm: '',
      distance: { label: '2 miles', value: 2 },
      distanceOptions: [
        { label: '2 miles', value: 2 },
        { label: '10 miles', value: 10 },
        { label: '50 miles', value: 50 },
      ],
      showTextSearchResults: false,
      locationFound: false,
    };
  },
  mounted() {},
  methods: {
    // getLocation() {
    //   this.showSpinner = true;
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       this.successCallback,
    //       this.errorCallback
    //     );
    //   } else {
    //     this.showSpinner = false;
    //     console.log('Geolocation is not supported by this browser.');
    //   }
    // },
    // successCallback(position) {
    //   console.log('got location');
    //   this.showSpinner = false;
    //   this.$store.commit('updateUsersLocation', position.coords);
    //   this.getNearbyProjects();
    // },
    // errorCallback(error) {
    //   this.showSpinner = false;
    //   console.log(
    //     'Error occurred while retrieving the location:',
    //     error.message
    //   );
    // },
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
    goToProject() {
      //update the active project index in the store and route to selected project
      let index = this.projectSelected;
      this.$store.commit('updateOpenedProjects', index);
      this.$router.push('/dashboard/project/' + index + '/map');
    },
    async goToLatLon(item) {
      await this.$store.commit('updateGoToLatLon', {
        lat: item.latitude,
        lon: item.longitude,
      });
      await this.$store.commit('updateSelectedView', 'map');
      await this.$store.commit('updateLeftDrawer', { open: false });
    },
    getTrees(id) {
      this.loading = true;
      if (this.showNearby) {
        // if show nearby try to get extent
        let extent = this.getUserbuffer(0.03);
        console.log(extent);
        if (extent) {
          this.$store.dispatch('getAllTreesInProjectNearMe', [id, extent]);
        } else {
          //if no location default to all trees in project
          this.showNearby = false;
          this.$store.dispatch('getAllTreesInProject', id);
          this.notify(
            'Unable to access user location to get nearby trees. Showing all trees.'
          );
        }
      } else {
        this.showNearby = false;
        this.$store.dispatch('getAllTreesInProject', id);
      }
    },
    getUserbuffer(miles) {
      // first get user location from map then create 500 m (diamond)buffer
      if (this.knownLocation) {
        let lon = this.userLocation[0];
        let lat = this.userLocation[1];
        const p = turf.point([lon, lat]);
        //using 200 miles for test to nyc.  update with new value
        let poly = turf.buffer(p, miles, { units: 'miles', steps: 1 });
        let polyBuffer = turf.featureCollection([poly]);
        let coords = polyBuffer.features[0].geometry.coordinates[0];
        let extent = '';
        for (var i = 0; i < coords.length; i++) {
          extent = extent + coords[i][1] + ' ' + coords[i][0] + ',';
        }
        //remove last comma
        extent = extent.substring(0, extent.length - 1);
        return extent;
      } else {
        return '';
      }
    },
    notify(message) {
      this.$q.notify({
        message: message,

        actions: [
          {
            color: 'white',
            icon: 'close',
            handler: () => {
              //do someting
            },
          },
        ],
      });
    },
    async noZscore(tree) {
      await this.$store.dispatch('getHealthcheckCounts', [
        tree.sub_id,
        tree.tree_id,
      ]);
      this.showHealthCheckCounts = true;
    },
    async getNearbyProjects() {
      let extent = this.getUserbuffer(this.distance.value);
      let polyText = `POLYGON((${extent}))`;
      await this.$store.dispatch('getNearbyProjects', polyText);
      this.locationFound = true;
    },
    async checkLocation() {
      if (!this.knownLocation) {
        await this.$store.dispatch('getUserLocationPromise');
        this.getNearbyProjects();
      } else {
        this.getNearbyProjects();
      }
    },
    async searchByProjectName() {
      let searchterm = this.searchterm;
      await this.$store.dispatch('getProjectByName', searchterm);
      this.showTextSearchResults = true;
    },
    joinProject(proj) {
      let polyText = proj.polygon_text;
      if (this.searchOption == 'nearby') {
        let extent = this.getUserbuffer(this.distance.value);
        polyText = `POLYGON((${extent}))`;
      }
      let searchterm = this.searchterm;
      this.$store.dispatch('joinProject', {
        projectId: proj.project_id,
        polyText: polyText,
        searchterm: searchterm,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
