<template>
  <q-toolbar class="bg-blue-grey-9 text-white" ref="toolbar">
    <q-btn
      icon="menu"
      flat
      round
      color=""
      @click="toggleDrawer"
      v-if="$q.screen.lt.md"
    >
    </q-btn>
    <q-toolbar-title class="text-subtitle1">Create Project</q-toolbar-title>
    <q-space></q-space>

    <q-btn padding="xs" round flat color="white" icon="help"
      ><q-menu class="">
        <div class="q-ma-md">
          Use the form to create a new project.
        </div></q-menu
      ></q-btn
    >
  </q-toolbar>

  <q-scroll-area style="height:calc(100vh - 86px)" class="shadow-1">
    <!-- <div
      :style="$q.screen.lt.sm ? 'padding-bottom:500px' : 'padding-bottom:500px'"
    > -->
    <div>
      <q-stepper
        v-model="step"
        ref="stepper"
        inactive-color="blue-grey-1"
        active-color="primary"
        done-color="primary"
        class=""
        flat
        animated
        keep-alive
        :contracted="$q.screen.lt.sm ? true : false"
      >
        <q-step
          :name="1"
          title="Project details"
          icon="settings"
          :done="step > 1"
          class="create-project-steps"
        >
          <div class="row">
            <div class="col-md-2 col-sm-0"></div>
            <div class="col-md-8 col-sm-12" style="min-height: 500px;">
              <q-form ref="form">
                <p class="text-h6 text-blue-grey-9 text-center q-mb-none">
                  New Project Form
                </p>
                <div id="doubleclick">
                  <p class=" text-grey-8 text-center">
                    Define your project and include details. By creating this
                    project, you become the Project Leader. You can transfer
                    this role to others on your team later.
                  </p>
                </div>

                <q-input
                  square
                  outlined
                  v-model="projectName"
                  label="Project Name*"
                  class="q-mb-none q-pb-sm"
                  lazy-rules
                  :rules="[(val) => !!val]"
                  style="font-size:16px"
                />
                <q-input
                  square
                  outlined
                  v-model="inDescription"
                  type="textarea"
                  label="Project Description"
                  class="q-mb-sm"
                  autogrow
                  style="font-size:16px"
                />
                <q-input
                  square
                  outlined
                  v-model="inExtent"
                  type="textarea"
                  label="Project Extent"
                  class="q-mb-sm"
                  autogrow
                  style="font-size:16px"
                />
                <q-input
                  square
                  outlined
                  v-model="inObjectives"
                  type="textarea"
                  label="Project Objectives"
                  class="q-mb-sm"
                  autogrow
                  style="font-size:16px"
                />
                <q-input
                  square
                  outlined
                  v-model="inCity"
                  label="City"
                  class="q-mb-md"
                  style="font-size:16px"
                  @blur="handleResize()"
                />
              </q-form>
            </div>
            <div class="col-md-2 col-sm-0"></div>
          </div>
        </q-step>
        <q-step
          :name="2"
          title="Settings"
          icon="settings"
          :done="step > 2"
          class="create-project-steps"
        >
          <div class="row">
            <div class="col-md-2 col-sm-0"></div>
            <div class="col-md-8 col-sm-12" style="min-height: 500px;">
              <q-form ref="form">
                <p class="text-h6 text-blue-grey-9 text-center q-mb-none">
                  Settings
                </p>
                <p class=" text-grey-8 text-center">
                  Project visibility and field collection tool settings
                </p>

                <p class="text-subtitle-2 q-mb-none q-pb-none q-mt-md">
                  Project Status:
                </p>
                <div style="width: 85%">
                  <p class="text-caption text-grey-8 q-mb-none">
                    *Inactive projects are not visible in the field collection
                    tool.
                  </p>
                </div>
                <div>
                  <q-option-group
                    v-model="inVisible"
                    :options="visibleOptions"
                    color="primary"
                    inline
                  />
                </div>
                <div v-if="inVisible == 1">
                  <p class="text-subtitle-2 q-mb-none q-pb-none q-mt-md">
                    Select activities available to users in the field collection
                    tool.
                  </p>
                  <p class="text-caption text-grey-8 q-mb-none">
                    Only the checked activities will be available in the field
                    collection tool for this project.
                  </p>
                  <q-checkbox
                    name="fieldtool"
                    v-model="healthCheck"
                    true-value="1"
                    false-value="0"
                    label="Health Check"
                  />
                  <q-checkbox
                    name="fieldtool"
                    v-model="pestDetection"
                    true-value="1"
                    false-value="0"
                    label="Pest Detection"
                  />
                  <q-checkbox
                    name="fieldtool"
                    v-model="treeCare"
                    true-value="1"
                    false-value="0"
                    label="Tree Care"
                  />
                </div>
              </q-form>
            </div>
            <div class="col-md-2 col-sm-0"></div>
          </div>
        </q-step>

        <q-step
          :name="3"
          title="Select a site"
          icon="add_location"
          :done="step > 3"
          class="create-project-steps"
        >
          <div
            :class="$q.screen.lt.sm ? '' : 'q-ml-xl q-mr-xl'"
            style="min-height: 500px;"
          >
            <p class="text-h6 text-blue-grey-9 q-mb-none text-center">
              Select a site boundary
            </p>
            <p class="text-grey-8 text-center q-mb-none">
              Pan and zoom the map to define your site boundary. Or, click the
              polygon tool on the map to draw a custom box.
            </p>

            <MapEdits :newProject="true"></MapEdits>
          </div>
        </q-step>

        <q-step :name="5" title="Submit" icon="assignment" class="text-center">
          <p class="text-h6 text-blue-grey-9 text-center q-mb-md">
            Find your project on the My Projects Page:
          </p>
          <q-btn unelevated color="secondary" to="/dashboard/myprojects">
            Go to your project
          </q-btn>
        </q-step>

        <template v-slot:navigation>
          <q-stepper-navigation
            style="border-top:1px solid rgba(0,0,0,0.12); height:70px"
          >
            <div class="q-mt-md">
              <q-btn
                v-if="step < 3"
                @click="validate()"
                color="primary"
                label="Continue"
                type="submit"
              />
              <q-btn
                v-if="
                  !$store.state.userStore.newProjectExtent.polyText && step == 3
                "
                @click="this.$store.commit('updateCreateNewProject')"
                color="primary"
                label="Set Extent"
              />
              <q-btn
                v-if="
                  $store.state.userStore.newProjectExtent.polyText && step == 3
                "
                @click="
                  $refs.stepper.next();
                  this.submitForm();
                "
                color="primary"
                label="Finish"
              />
              <q-btn
                v-if="step > 1 && step < 5"
                flat
                color="primary"
                @click="$refs.stepper.previous()"
                label="Back"
                class="q-ml-sm"
              />
            </div>
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </div>
  </q-scroll-area>
</template>
<script>
import MapEdits from '../AppTools/MapEdits.vue';
import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  name: 'NewProject',
  components: { MapEdits },
  computed: {
    newIndex() {
      let newIndex =
        '/dashboard/project/' + this.$store.state.projects.length + '/map';
      console.log(this.$store.state.projects.length);

      return newIndex;
    },
  },

  data() {
    return {
      focus: false,
      windowHeight: 'calc(100vh - 60px)',
      step: 1,
      success: false,
      addTrees: true,
      projectName: '',
      inDescription: '',
      inExtent: '',
      inObjectives: '',
      inCity: '',
      inVisible: 1,
      healthCheck: '1',
      treeCare: '1',
      pestDetection: '1',
      acceptingUsers: '1',
      originalViewportHeight: null,
      visibleOptions: [
        {
          label: 'Active',
          value: 1,
        },
        {
          label: 'Inactive',
          value: 0,
        },
      ],
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$store.commit('updateActiveProject', '');
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    //what this does is reset the size of the viewport.  This handles the issue on mobile
    //where the browser resets the size of the viewport in order to move the input up above
    //the virtual keyboard.  Setting the font size to 16px prevents it froom zooming.
    handleResize() {
      const currentViewportHeight = window.visualViewport.height;
      const heightDifference =
        this.originalViewportHeight - currentViewportHeight;

      // Adjust your layout or perform any necessary actions here
      // For example, you could scroll the window to the original position
      window.scrollTo(0, window.pageYOffset + heightDifference);
    },

    handleScroll() {
      const ele = document.getElementById('test'); // You need to get your element here
      const target = getScrollTarget(ele);
      const offset = ele.offsetTop - ele.scrollHeight;
      const duration = 1000;
      setScrollPosition(target, offset, duration);
    },

    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },

    async validate() {
      this.handleResize();
      let valid = await this.$refs.form.validate();
      console.log(valid);
      document.body.scrollTop = 0;
      if (valid == true) {
        this.$refs.stepper.next();
      }
    },

    async submitForm() {
      this.handleResize();

      let obj = {
        inCity: this.inCity,
        projectName: this.projectName,
        inDescription: this.inDescription,
        inExtent: this.inExtent,
        inObjectives: this.inObjectives,
        healthCheck: parseInt(this.healthCheck),
        treeCare: parseInt(this.treeCare),
        pestDetection: parseInt(this.pestDetection),
        inVisible: parseInt(this.inVisible),
        polyText: this.$store.state.userStore.newProjectExtent.polyText,
        includeTrees: parseInt(
          this.$store.state.userStore.newProjectExtent.includeTrees
        ),
        acceptingUsers: parseInt(this.acceptingUsers),
      };
      this.$store.dispatch('createNewProject', obj);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.create-project-steps {
  height: calc(100vh - 229px) !important;
}
.q-stepper {
  border-radius: 0 !important;
}
</style>
