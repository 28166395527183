<template>
  <q-card>
    <q-card-section>
      <div class="row">
        <div class="text-h6 col">Health Index Score</div>
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
    </q-card-section>
    <q-separator inset />
    <q-card-section>
      <div>
        Health index scores will be calculated for a tree when 100 or more
        health checks are performed on that tree species. If less than 100
        health checks were performed on that species, a question mark will
        appear. Once there are 100 health checks recorded for that species, HTHC
        will generate a health index score and replace the question mark with
        that score. A green number indicates more than 100 health checks were
        performed on that species; a red number indicates less than 100 health
        checks were performed on that species.
      </div>
      <div class="q-mt-md">
        Species health checks:
        <span
          style="font-weight: bold"
          :class="{
            'text-green': speciesHealthCheck > 99,
            'text-red': speciesHealthCheck < 100,
          }"
          >{{ speciesHealthCheck }}</span
        >
        of 100
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
export default {
  props: ['speciesHealthCheck', 'treeHealthCheck'],
};
</script>
