import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Quasar } from 'quasar';
import quasarUserOptions from './quasar-user-options';
import VueApexCharts from 'vue3-apexcharts';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Quasar, quasarUserOptions);
app.use(VueApexCharts);
app.mount('#app');
