<template>
  <!--pdf viewer-->
  <div class="fullscreen" v-show="show" style="z-index: 9999;">
    <div class="bg-white text-center">
      <q-btn
        size="md"
        color="white"
        text-color="primary"
        label=""
        class="q-mt-xs"
        @click="this.closePDF()"
        v-show="show"
        style="width: 150px;"
        >CLOSE</q-btn
      >
    </div>
    <div id="adobe" style="height: calc(100vh - 41px)"></div>
  </div>
  <q-layout
    view="lhh lpr lFf"
    :class="
      $q.screen.lt.sm || $q.screen.lt.sm
        ? 'bg-blue-grey-1 '
        : 'bg-blue-grey-1 q-pt-md'
    "
  >
    <!--dashboard left drawer menu-->
    <q-drawer
      show-if-above
      v-model="drawer"
      side="left"
      bordered
      class="shadow-1"
    >
      <!-- drawer content -->
      <div class="">
        <q-item class="q-mt-sm">
          <a href="https://hthc-dashboard.netlify.app/home" target="_blank"
            ><img src="/img/hthc-short-logo.png" style="height: 48px"
          /></a>
        </q-item>
      </div>
      <q-separator spaced inset> </q-separator>
      <div class="">
        <p
          class="text-blue-grey-9 q-ml-sm q-mt-md q-mb-none  text-body1 text-weight-light"
        >
          Dashboard
        </p>

        <q-list class="text-blue-grey-9  ">
          <q-item
            clickable
            to="/dashboard/home"
            active-class="bg-green-1 text-blue-grey-9 text-bold"
            :active="active"
          >
            <q-item-section avatar>
              <q-icon name="dashboard" />
            </q-item-section>

            <q-item-section class="text-subtitle2">
              Home
            </q-item-section>
          </q-item>
        </q-list>

        <p
          class="text-blue-grey-9 q-ml-sm q-mb-none q-pb-none q-mt-md text-body1 text-weight-light"
        >
          Projects
        </p>

        <q-list padding class="text-blue-grey-9 q-pt-none">
          <q-item
            clickable
            to="/dashboard/newproject"
            active-class="bg-green-1 text-blue-grey-9"
            :active="active"
          >
            <q-item-section avatar>
              <q-icon name="add" />
            </q-item-section>

            <q-item-section class="text-subtitle2">
              Create Project
            </q-item-section>
          </q-item>
          <q-item
            clickable
            to="/dashboard/joinproject"
            active-class="bg-green-1  text-blue-grey-9 "
            :active="active"
          >
            <q-item-section avatar>
              <q-icon name="search" />
            </q-item-section>

            <q-item-section class="text-subtitle2">
              Find a Project to Join
            </q-item-section>
          </q-item>
          <q-item
            clickable
            to="/dashboard/myprojects"
            active-class="bg-green-1 text-blue-grey-9 text-bold"
            :active="active"
          >
            <q-item-section avatar>
              <q-icon
                :name="
                  $store.state.openedProjects.length > 0
                    ? 'folder_opened'
                    : 'folder'
                "
              />
            </q-item-section>

            <q-item-section class="text-subtitle2">
              My Projects
            </q-item-section>
          </q-item>
          <div v-if="userType">
            <q-item
              v-if="userType === 'admin'"
              clickable
              to="/dashboard/admin"
              active-class="bg-green-1 text-blue-grey-9 text-bold"
              :active="active"
            >
              <q-item-section avatar>
                <q-icon name="settings" />
              </q-item-section>

              <q-item-section class="text-subtitle2">
                Admin Page
              </q-item-section>
            </q-item>
          </div>
        </q-list>
        <q-list class="q-ml-md q-mr-md" dense>
          <q-item
            v-for="(proj, key) in $store.state.openedProjects"
            :key="key"
            clickable
            active-class="bg-green-1"
            :active="active"
            class="text-caption q-mb-xs q-ml-sm"
            @click="
              this.activeProject = proj;
              this.$router.push(
                '/dashboard/project/' + proj.project_id + '/map'
              );
            "
            dense
          >
            <q-item-section thumbnail>
              <q-icon name="folder_opened" size="xs" />
            </q-item-section>
            <q-item-section class="">
              {{ proj.project_name }}
            </q-item-section>
            <q-space></q-space>
            <q-btn
              flat
              padding="none"
              round
              size="sm"
              icon="close"
              @click.stop.prevent="
                $store.commit('closeProject', proj.project_id)
              "
            ></q-btn>
          </q-item>
        </q-list>
        <p
          class="text-subtitle2 text-blue-grey-9 q-ml-sm q-mb-none q-pb-none q-mt-md text-body1 text-weight-light"
        >
          Field Data Collection
        </p>
        <q-list class="text-blue-grey-9  ">
          <q-item
            clickable
            to="/dashboard/fieldtool"
            active-class="bg-green-1 text-blue-grey-9"
            :active="active"
          >
            <q-item-section avatar>
              <q-icon name="smartphone" />
            </q-item-section>

            <q-item-section class="text-subtitle2">
              Field Tool Info
            </q-item-section>
          </q-item>
          <q-item
            clickable
            to="/dashboard/resources"
            active-class="bg-green-1 text-blue-grey-9"
            :active="active"
          >
            <q-item-section avatar>
              <q-icon name="play_circle" />
            </q-item-section>

            <q-item-section class="text-subtitle2">
              Resources
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-drawer>

    <!--dashboard contents-->
    <q-page-container :class="$q.screen.lt.sm ? '' : 'q-mx-md'">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
let adobeDCView = '';
export default {
  name: 'Dashboard',
  mounted() {
    this.$store.dispatch('getUserLocation');
    this.success = false;
    window.addEventListener('resize', function() {
      var windowHeight = window.innerHeight;
      this.windowHeight = windowHeight + 'px';
    });
  },
  computed: {
    activeProject: {
      get() {
        return this.$store.state.userStore.activeProject;
      },
      set(value) {
        this.$store.commit('updateActiveProject', value);
      },
    },
    drawerState() {
      return this.$store.state.drawerState;
    },
    userLoggedIn() {
      return this.$store.state.userLoggedIn;
    },
    selectedProjectName() {
      let prjs = $store.state.userStore.usersProjects;
    },
    userType() {
      return this.$store.state.authStore.userInfo['custom:usertype'];
    },
    showHelp() {
      return this.$store.state.showHelp;
    },
  },
  watch: {
    drawerState() {
      console.log(this.drawerState);
      this.drawer = !this.drawer;
    },
    showHelp() {
      this.showPDF();
    },
  },

  data() {
    return {
      drawer: true,
      active: false,
      slide: 1,
      autoplay: true,
      selected: 'home',
      windowHeight: 0,
      show: false,
    };
  },
  methods: {
    closePDF() {
      this.show = false;
      document.getElementById('adobe').hidden = true;
    },

    showPDF() {
      document.getElementById('adobe').hidden = false;
      this.show = true;

      //clientid
      //localhost: '1d5dd0457ccc4c4b92e0e41dc64a372a'
      //dev: ''
      //prod: '2669da6e471d4a268c6056c0978b80ff'
      let clientId = '';
      console.log(window.location.hostname);
      if (window.location.hostname === 'localhost') {
        clientId = '1d5dd0457ccc4c4b92e0e41dc64a372a';
      }
      //if (window.location.hostname === 'dev-hthc-dashboard.netlify.app') {
      //  clientId = 'e65ece398c974b8ba63633c00fece23b';
      //}
      if (window.location.hostname === 'hthc-dashboard.netlify.app') {
        clientId = '2669da6e471d4a268c6056c0978b80ff';
      }
      adobeDCView = new AdobeDC.View({
        clientId: clientId,
        divId: 'adobe',
      });

      adobeDCView.previewFile(
        {
          content: {
            location: {
              url: this.$store.state.showHelp.url,
            },
          },
          metaData: { fileName: this.$store.state.showHelp.filename },
        },
        { ZOOM_LEVEL: 100 }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border-left {
  border-left: 5px solid white;
}
</style>
