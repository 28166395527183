<template>
  <q-scroll-area class="bg-white shadow-1" style="height:calc(100vh - 86px)">
    <div
      class="q-ma-xl text-left"
      :style="$q.screen.lt.sm ? 'padding-bottom:50px' : ''"
    >
      <q-page-sticky
        position="top-right"
        :offset="[50, 100]"
        style="z-index: 3"
      >
        <q-btn round color="primary" icon="filter_alt">
          <q-menu>
            <div style="max-width: 500px">
              <q-card-actions align="left">
                <q-btn flat icon="sym_s_arrow_back" label="" color="primary" />
                <q-chip size="lg" color="white" class="text-primary"
                  >Filter Project Log</q-chip
                >
              </q-card-actions>
              <div class="text-left q-ma-md">
                <div>
                  <p class="semi-bold q-mb-none q-mt-lg text-body1">
                    By task performed:
                  </p>
                  <q-option-group
                    style="width: 100%"
                    v-model="tasksModel"
                    color="primary"
                    type="checkbox"
                    :options="tasksOptions"
                  ></q-option-group>
                </div>

                <div>
                  <p class="semi-bold q-mb-none q-mt-lg text-body1">
                    By contributor:
                  </p>

                  <div style="min-width: 250px; max-width: 300px">
                    <q-select
                      filled
                      v-model="this.selectedMembers"
                      multiple
                      :options="this.users"
                      use-chips
                      stack-label
                      label="Select Contributors"
                      options-selected-class="text-deep-orange"
                    />
                  </div>
                </div>

                <div>
                  <p class="semi-bold q-mb-none q-mt-lg text-body1">
                    By date range:
                  </p>
                  <div class="q-pa-md">
                    <q-date
                      title="Filter by activity date"
                      subtitle="Select range"
                      v-model="dateRange"
                      style="width: 100%"
                      range
                    >
                      <div class="row items-center justify-end q-gutter-sm">
                        <q-btn
                          label="Clear date filter"
                          color="primary"
                          outline
                          @click="this.dateRange = ''"
                        /></div
                    ></q-date>
                  </div>
                </div>
              </div>
            </div>
          </q-menu>
        </q-btn>
      </q-page-sticky>

      <q-timeline color="secondary">
        <div v-for="(value, name, index) in activitiesByMonth" :key="index">
          <q-timeline-entry heading>{{ name }}</q-timeline-entry>

          <q-timeline-entry v-for="(activity, key) in value" :key="key">
            <template v-slot:title>
              {{ activity.visit_name }}
            </template>
            <template v-slot:subtitle>
              {{ activity.visit_date_formatted }}
            </template>
            <template v-slot:avatar>
              <q-avatar size="24px" color="orange">{{
                activity.user_name
              }}</q-avatar>
            </template>

            <div>
              {{ activity.tree_name }}<br />
              {{ activity.common_name }}, {{ activity.scientific_name }} <br />
              <p class="text-caption text-blue-grey-9">
                performed by: {{ activity.user_name }}
              </p>
              <div v-if="activity.notes" class="bg-blue-grey-1 q-ma-md q-pa-md">
                Notes: {{ activity.notes }}
              </div>
            </div>
            <div>
              <q-btn flat color="primary" @click="getVisitDetails(activity)"
                >Details</q-btn
              >
              <q-btn
                flat
                color="primary"
                @click="
                  this.goToLatLon({
                    latitude: activity.latitude,
                    longitude: activity.longitude,
                  })
                "
                >View on Map</q-btn
              >
              <q-btn
                flat
                color="primary"
                @click="this.viewTree(activity.tree_id)"
                >View Tree home</q-btn
              >
            </div>
          </q-timeline-entry>
        </div>
      </q-timeline>
      <!-- if there are no activities while using the log filter display message-->
      <div
        v-if="
          Object.keys(activitiesByMonth).length === 0 && activities.length > 0
        "
      >
        <p class="text-primary text-subtitle1">
          There are no results for the current filter. Try expanding your search
          parameters.
        </p>
      </div>
      <!-- if there are no activities in the log display message-->
      <div v-if="activities.length == 0">
        <p class="text-primary text-subtitle1">
          There are currently no activities for this project. Use the field tool
          to perform health check, pest detection or tree care. Activities will
          show up here.
        </p>
      </div>
    </div>
  </q-scroll-area>
  <!-- these forms will hide/show in a dialog -->
  <q-dialog v-model="this.$store.state.treesStore.pestDetection" full-width>
    <div class="q-ml-xl q-mr-xl q-mb-xl bg-white">
      <q-toolbar class="bg-blue-grey-9">
        <q-toolbar-title class="text-white"
          >Pest Detection Form</q-toolbar-title
        >
        <q-space></q-space>
        <q-btn
          outline
          @click="this.$store.commit('updatePestDetection', false)"
          color="white"
          style=""
          icon="close"
        ></q-btn>
      </q-toolbar>
      <q-separator></q-separator>
      <form-pest-detection></form-pest-detection>
    </div>
  </q-dialog>

  <q-dialog v-model="this.$store.state.treesStore.healthCheck" full-width>
    <div class="q-ml-xl q-mr-xl q-mb-xl bg-white">
      <q-toolbar class="bg-blue-grey-9">
        <q-toolbar-title class="text-white">Health Check Form</q-toolbar-title>
        <q-space></q-space>
        <q-btn
          outline
          @click="this.$store.commit('updateHealthCheck', false)"
          color="white"
          icon="close"
          style=""
        ></q-btn>
      </q-toolbar>
      <q-separator></q-separator>
      <form-health-check></form-health-check>
    </div>
  </q-dialog>

  <q-dialog v-model="this.$store.state.treesStore.treeCare" full-width>
    <div class="q-ml-xl q-mr-xl q-mb-xl bg-white">
      <q-toolbar class="bg-blue-grey-9">
        <q-toolbar-title class="text-white">Tree Care Form</q-toolbar-title>
        <q-space></q-space>
        <q-btn
          outline
          @click="this.$store.commit('updateTreeCare', false)"
          color="white"
          style=""
          icon="close"
        ></q-btn>
      </q-toolbar>
      <q-separator></q-separator>
      <form-tree-care></form-tree-care>
    </div>
  </q-dialog>
</template>

<script>
import FormHealthCheck from './FormHealthCheck.vue';
import FormTreeCare from './FormTreeCare.vue';
import FormPestDetection from './FormPestDetection.vue';

export default {
  name: 'ActiveProjectActivites',
  props: ['index'],
  components: { FormHealthCheck, FormTreeCare, FormPestDetection },
  computed: {
    activities() {
      return this.$store.state.userStore.projectVisitLog;
    },

    activitiesByMonth() {
      let activities = this.$store.state.userStore.projectVisitLog;
      let tasks = this.tasksModel;
      let members = this.selectedMembers;
      let dateRange = this.dateRange;
      //filter by visit type
      let result = activities.filter(function(item) {
        if (tasks.indexOf(item.visit_name) > -1) {
          return item;
        }
      });
      //filter by member
      let result2 = [];
      if (members.length > 0) {
        result2 = result.filter(function(item) {
          if (members.indexOf(item.user_name) > -1) {
            return item;
          }
        });
      } else {
        result2 = result;
      }

      let result3 = [];
      //filter by date range
      if (dateRange !== '') {
        let from = new Date(dateRange.from);
        let to = new Date(dateRange.to);
        result3 = result.filter(function(item) {
          let itemDate = new Date(item.visit_date * 1000);
          if (itemDate >= from && itemDate <= to) {
            return item;
          }
        });
      } else {
        result3 = result2;
      }

      let groups = result3.reduce((groups, item) => {
        let group = groups[item.visit_month_year] || [];
        group.push(item);
        groups[item.visit_month_year] = group;
        return groups;
      }, {});
      console.log(groups);
      return groups;
    },
    users() {
      const array = this.activities;
      const unique = array
        .map((item) => item.user_name)
        .filter((value, index, self) => self.indexOf(value) === index);
      return unique;
    },
  },
  data() {
    return {
      tasksOptions: [
        { label: 'Health Check', value: 'Health Check' },
        { label: 'Tree Care', value: 'Tree Care' },
        { label: 'Pest Detection', value: 'Pest Detection' },
      ],
      tasksModel: ['Health Check', 'Tree Care', 'Pest Detection'],
      selectedMembers: [],
      members: this.users,
      dateRange: '',
    };
  },
  methods: {
    viewTree(treeId) {
      let projectId = this.$router.currentRoute.value.params.index;
      this.$router.push('/dashboard/project/' + projectId + '/tree/' + treeId);
    },
    async goToLatLon(obj) {
      await this.$store.commit('updateGoToLatLon', {
        lat: obj.latitude,
        lon: obj.longitude,
      });
      let projectId = this.$router.currentRoute.value.params.index;
      this.$router.push('/dashboard/project/' + projectId + '/map');
    },
    async getVisitDetails(activity) {
      await this.$store.dispatch('getTreeById', [
        activity.tree_id,
        this.$router.currentRoute.value.params.index,
      ]);
      await this.$store.dispatch('getVisitDetails', activity.visit_id);
      await this.$store.commit('updateEditTreeMode', true);
      if (activity.visit_name == 'Health Check') {
        this.$store.commit('updateHealthCheck', true);
      }
      if (activity.visit_name == 'Pest Detection') {
        this.$store.commit('updatePestDetection', true);
      }
      if (activity.visit_name == 'Tree Care') {
        this.$store.commit('updateTreeCare', true);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scroll-height {
  height: calc(100vh - 140px);
}
</style>
