import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index.js';

/* LANDING PAGE */
import Landing from '../components/AppTools/Landing.vue';
import LandingContent from '../components/AppTools/LandingContent.vue';
import Resources from '../components/AppTools/Resources.vue';
import DashboardFieldTool from '../components/AppTools/DashboardFieldTool.vue';

// Login logic
import TheLoginForm from '../components/authentication/TheLoginForm';

/* DASHBOARD PAGES */
import Dashboard from '../components/AppTools/Dashboard.vue';
import MyProjects from '../components/AppTools/MyProjects.vue';
import NewProject from '../components/AppTools/FormNewProject.vue';
import DashboardHome from '../components/AppTools/DashboardHome.vue';
import DashboardJoinProject from '../components/AppTools/DashboardJoinProject.vue';
import DashboardResources from '../components/AppTools/DashboardResources';

/* PROJECTS PAGES*/
import ActiveProject from '../components/AppTools/ActiveProject.vue';
import ActiveProjectMap from '../components/AppTools/ActiveProjectMap.vue';
import ActiveProjectActivities from '../components/AppTools/ActiveProjectActivities.vue';
import ActiveProjectTrees from '../components/AppTools/ActiveProjectTrees.vue';
import ActiveProjectUsers from '../components/AppTools/ActiveProjectUsers.vue';
import ActiveProjectSettings from '../components/AppTools/ActiveProjectSettings.vue';
import TreeHomePage from '../components/AppTools/TreeHomePage.vue';
import ActiveProjectQAQC from '../components/AppTools/ActiveProjectQAQC.vue';

/* ADMIN PAGES */
import AdminHome from '../components/AppTools/AdminHome.vue';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    redirect: {
      name: 'LandingContent',
    },
    children: [
      {
        name: 'LandingContent',
        path: 'home',
        component: LandingContent,
      },
      {
        name: 'Resources',
        path: 'resources',
        component: Resources,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: TheLoginForm,
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    redirect: {
      name: 'DashboardHome',
    },
    beforeEnter(to, from, next) {
      store.dispatch('getDashboardStats');
      next();
    },
    children: [
      {
        path: 'home',
        name: 'DashboardHome',
        component: DashboardHome,
      },
      {
        path: 'joinproject',
        name: 'DashboardJoinProject',
        component: DashboardJoinProject,
      },
      {
        path: 'newproject',
        name: 'NewProject',
        component: NewProject,
      },
      {
        path: 'myprojects',
        name: 'MyProjects',
        component: MyProjects,
        beforeEnter(to, from, next) {
          store.dispatch('getUsersProjects');
          next();
        },
      },
      {
        path: 'admin',
        name: 'AdminHome',
        component: AdminHome,
      },
      {
        path: 'project/:index',
        name: 'Project',
        component: ActiveProject,
        props: true,
        redirect: {
          name: 'Map',
        },

        children: [
          {
            name: 'Map',
            path: 'map',
            component: ActiveProjectMap,
            async beforeEnter(to, from, next) {
              await store.dispatch('getProjectById', to.params.index);
              next();
            },
          },
          {
            path: 'activities',
            component: ActiveProjectActivities,
            async beforeEnter(to, from, next) {
              await store.dispatch('getVisitsByProject', to.params.index);
              next();
            },
          },
          {
            path: 'trees',
            component: ActiveProjectTrees,
            props: true,
            async beforeEnter(to, from, next) {
              await store.dispatch('getTreeStatsByProject', to.params.index);
              await store.dispatch('getAllTreesInProject', to.params.index);
              await store.dispatch('getProjectById', to.params.index);
              next();
            },
          },
          {
            path: 'users',
            component: ActiveProjectUsers,
            props: true,
            async beforeEnter(to, from, next) {
              await store.dispatch('getProjectUsers', to.params.index);
              await store.dispatch('getProjectById', to.params.index);
              next();
            },
          },
          {
            path: 'qaqc',
            component: ActiveProjectQAQC,
            props: true,
            async beforeEnter(to, from, next) {
              await store.dispatch('getQAQCByProject', to.params.index);
              await store.dispatch('getProjectById', to.params.index);
              next();
            },
          },
          {
            path: 'settings',
            component: ActiveProjectSettings,
            props: true,
            async beforeEnter(to, from, next) {
              await store.dispatch('getProjectById', to.params.index);
              next();
            },
          },
        ],
      },
      {
        path: 'project/:index/tree/:treeid',
        component: TreeHomePage,
        props: false,
        async beforeEnter(to, from, next) {
          await store.dispatch('getVisitsByTreeId', to.params.treeid);
          await store.dispatch('getUsersProjects');
          await store.dispatch('getTreeById', [
            to.params.treeid,
            to.params.index,
          ]);
          next();
        },
      },
      {
        path: 'fieldtool',
        name: 'fieldtool',
        component: DashboardFieldTool,
      },
      {
        path: 'resources',
        name: 'resources',
        component: DashboardResources,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.fullPath.includes('dashboard')) {
    const userSession = await store.dispatch('getCurrentUserSession');
    if (!userSession) {
      router.push('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
