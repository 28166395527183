<template>
  <q-scroll-area style="height:calc(100vh - 86px)" class="bg-white shadow-1">
    <div
      :style="$q.screen.lt.sm ? 'padding-bottom:50px' : ''"
      class="bg-white q-pa-md"
    >
      <q-form @submit="onSubmit" class="q-ma-md">
        <p class="text-h6 text-blue-grey-9 text-center q-mb-none">
          Project Settings
        </p>
        <p class=" text-grey-8 text-center">
          Project visibility and field collection tool settings
        </p>
        <q-input
          square
          outlined
          v-model="projectName"
          label="Project Name*"
          class="q-mb-none q-pb-sm"
          lazy-rules
          :rules="[(val) => !!val]"
          style="font-size:16px"
          :disable="admin ? false : true"
        />
        <q-input
          square
          outlined
          v-model="inDescription"
          type="textarea"
          label="Project Description"
          class="q-mb-sm"
          autogrow
          style="font-size:16px"
          :disable="admin ? false : true"
        />
        <q-input
          square
          outlined
          v-model="inExtent"
          type="textarea"
          label="Project Extent"
          class="q-mb-sm"
          autogrow
          style="font-size:16px"
          :disable="admin ? false : true"
        />
        <q-input
          square
          outlined
          v-model="inObjectives"
          type="textarea"
          label="Project Objectives"
          class="q-mb-sm"
          autogrow
          style="font-size:16px"
          :disable="admin ? false : true"
        />
        <q-input
          square
          outlined
          v-model="inCity"
          label="City"
          class="q-mb-xs"
          style="font-size:16px"
          @blur="handleResize()"
          :disable="admin ? false : true"
        />

        <p class="text-subtitle-2 q-mb-none q-pb-none q-mt-md">
          Project Status:
        </p>
        <div style="width: 85%">
          <p class="text-caption text-grey-8 q-mb-none">
            *Inactive projects are not visible in the field collection tool.
          </p>
        </div>
        <div>
          <q-option-group
            v-model="inVisible"
            :options="visibleOptions"
            color="primary"
            inline
            :disable="admin ? false : true"
          />
        </div>
        <div v-if="inVisible == 1">
          <p class="text-subtitle-2 q-mb-none q-pb-none q-mt-md">
            Select activities available to users in the field collection tool.
          </p>
          <p class="text-caption text-grey-8 q-mb-none">
            Only the checked activities will be available in the field
            collection tool for this project.
          </p>
          <q-checkbox
            name="fieldtool"
            v-model="healthCheck"
            true-value="1"
            false-value="0"
            label="Health Check"
            :disable="admin ? false : true"
          />
          <q-checkbox
            name="fieldtool"
            v-model="pestDetection"
            true-value="1"
            false-value="0"
            label="Pest Detection"
            :disable="admin ? false : true"
          />
          <q-checkbox
            name="fieldtool"
            v-model="treeCare"
            true-value="1"
            false-value="0"
            label="Tree Care"
            :disable="admin ? false : true"
          />
        </div>
        <div class="q-mb-xl q-mt-md">
          <q-btn label="Save" type="submit" color="primary" v-if="admin" />
        </div>
      </q-form>
    </div>
  </q-scroll-area>
</template>

<script>
export default {
  name: 'ActiveProjectSettings',
  props: ['index'],
  mounted() {},
  computed: {
    admin() {
      let bool = false;
      if (
        this.$store.state.userStore.activeProject.role_name == 'Project Leader'
      ) {
        bool = true;
      }
      return bool;
    },
    project() {
      return this.$store.state.userStore.activeProject;
    },
    stringVisible() {
      let val = this.$store.state.userStore.activeProject.visible;
      let str = val.toString();
      console.log(str);
      return str;
    },
  },
  data() {
    return {
      mode: 'view',
      addTrees: true,
      visibleOptions: [
        {
          label: 'Active',
          value: 1,
        },
        {
          label: 'Inactive',
          value: 0,
        },
      ],
      projectName: this.$store.state.userStore.activeProject.project_name
        ? this.$store.state.userStore.activeProject.project_name
        : null,
      inDescription: this.$store.state.userStore.activeProject.description
        ? this.$store.state.userStore.activeProject.description
        : null,
      inExtent: this.$store.state.userStore.activeProject.extent
        ? this.$store.state.userStore.activeProject.extent
        : null,
      inObjectives: this.$store.state.userStore.activeProject.objectives
        ? this.$store.state.userStore.activeProject.objectives
        : null,
      inCity: this.$store.state.userStore.activeProject.city
        ? this.$store.state.userStore.activeProject.city
        : null,
      inVisible: this.$store.state.userStore.activeProject.visible
        ? this.$store.state.userStore.activeProject.visible
        : 0,
      healthCheck: this.$store.state.userStore.activeProject.healthCheck
        ? this.$store.state.userStore.activeProject.healthCheck
        : '1',
      treeCare: this.$store.state.userStore.activeProject.treeCare
        ? this.$store.state.userStore.activeProject.treeCare
        : '1',
      pestDetection: this.$store.state.userStore.activeProject.pestDetection
        ? this.$store.state.userStore.activeProject.pestDetection
        : '1',
    };
  },
  methods: {
    onSubmit() {
      let obj = {
        inCity: this.inCity,
        projectName: this.projectName,
        inDescription: this.inDescription,
        inExtent: this.inExtent,
        inObjectives: this.inObjectives,
        healthCheck: parseInt(this.healthCheck),
        treeCare: parseInt(this.treeCare),
        pestDetection: parseInt(this.pestDetection),
        inVisible: parseInt(this.inVisible),
        projectId: this.project.project_id,
      };
      this.$store.dispatch('editProject', obj);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
