// import AWS from 'aws-sdk';
// import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const state = () => ({
  treesGeoJson: { features: [] },
  selectedTreesGeoJson: { features: [] },
  treesInProject: [],
  treesInProjectNearMe: [],
  treeList: [],
  selectedTree: [],
  selectedTreeVisits: [],
  selectedVisitDetails: {},
  latitude: 0,
  longitude: 0,
  boundingBoxString: '',
  polygonString: '',
  showAddNewTree: false,
  goToLatLon: {},
  editTreeMode: false,
  healthcheckCounts: [],
  treeStatsByProject: [],
  goToLatLon: '',
  editTreeMode: false,
  selectedVisitDetails: {},
  healthCheck: false,
  treeCare: false,
  pestDetection: false,
  addTree: false,
});

const getters = {};

const mutations = {
  updateTreesInProject(state, data) {
    state.treesInProject = data;
  },
  updateTreesInProjectNearMe(state, data) {
    state.treesInProjectNearMe = data;
  },
  updateTreeList(state, data) {
    state.treeList = data;
  },
  updateSelectedTree(state, data) {
    state.selectedTree = data;
  },
  updateLatLong(state, data) {
    state.latitude = data[0];
    state.longitude = data[1];
  },
  updateBoundingBox(state, data) {
    return new Promise(async (resolve, reject) => {
      state.boundingBoxString = data;
      resolve();
    });
  },
  updatePolygonString(state, data) {
    return new Promise(async (resolve, reject) => {
      console.log(data);
      state.polygonString = data;
      resolve();
    });
  },
  updateShowAddNewTree(state, data) {
    state.showAddNewTree = data;
  },
  updateGoToLatLon(state, obj) {
    state.goToLatLon = obj;
  },
  updateSelectedTreeVisits(state, arr) {
    state.selectedTreeVisits = arr;
  },
  updateSelectedVisitDetails(state, obj) {
    state.selectedVisitDetails = obj;
  },
  updateEditTreeMode(state, bool) {
    state.editTreeMode = bool;
  },
  updateHealthcheckCounts(state, data) {
    state.healthcheckCounts = data;
  },
  updateTreesStatsByProject(state, data) {
    return new Promise(async (resolve, reject) => {
      state.treeStatsByProject = data;
      resolve();
    });
  },
  updateGoToLatLon(state, obj) {
    state.goToLatLon = obj;
  },

  updateSelectedVisitDetails(state, obj) {
    state.selectedVisitDetails = obj;
  },
  updatePestDetection(state, bool) {
    state.pestDetection = bool;
  },
  updateTreeCare(state, bool) {
    state.treeCare = bool;
  },
  updateHealthCheck(state, bool) {
    state.healthCheck = bool;
  },
  updateAddTree(state, bool) {
    state.addTree = bool;
  },
  updateSelectedTreesGeoJson(state, obj) {
    state.selectedTreesGeoJson = obj;
  },
};

const actions = {
  async getTreesByMapExtent(context) {
    return new Promise(async (resolve, reject) => {
      const polygonExtent = context.state.boundingBoxString;
      const response = await context.dispatch('protectedApiRequest', {
        route: `trees/extent/${polygonExtent}`,
        type: 'GET',
      });
      if (response.status === 200) {
        await context.dispatch('convertToGeojson', response.data.data[0]);
        resolve();
      } else {
        reject();
      }
    });
  },
  async getSelectedTreesByPoly(context) {
    return new Promise(async (resolve, reject) => {
      const polygonExtent = context.state.polygonString;
      const response = await context.dispatch('protectedApiRequest', {
        route: `trees/extent/${polygonExtent}`,
        type: 'GET',
      });
      if (response.status === 200) {
        console.log(response.data.data[0]);
        await context.dispatch(
          'convertSelectedToGeojson',
          response.data.data[0]
        );
        resolve();
      } else {
        reject();
      }
    });
  },

  async getTreeById(context, arr) {
    return new Promise(async (resolve, reject) => {
      let treeId = arr[0];
      let projectId = arr[1];
      const response = await context.dispatch('protectedApiRequest', {
        route: `tree?treeId=${treeId}&projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          console.log(response.data.data[0]);
          const treeData = response.data.data[0];

          const photos = await context.dispatch(
            'populateTreePhotoDownloadUrls',
            treeData
          );
          treeData.photosUrlArray = photos;

          await context.commit('updateSelectedTree', treeData);
          resolve();
        } catch (error) {
          reject(error);
          console.log(error);
        }
      } else {
        reject(error);
        console.log(error);
      }
    });
  },
  async getTreeById_openMap(context, arr) {
    try {
      let treeId = arr[0];
      let projectId = arr[1];
      const response = await context.dispatch('protectedApiRequest', {
        route: `tree?treeId=${treeId}&projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          const treeData = response.data.data[0];

          const photos = await context.dispatch(
            'populateTreePhotoDownloadUrls',
            treeData
          );
          treeData.photosUrlArray = photos;

          await context.commit('updateSelectedTree', treeData);
          await context.commit('updateGoToLatLon', {
            lat: treeData.latitude,
            lon: treeData.longitude,
          });
          await context.commit('updateSelectedView', 'map');
          // call procedure to get activies from revisit table
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async populateTreePhotoDownloadUrls(context, treeData) {
    return new Promise((resolve, reject) => {
      try {
        let photos = treeData.photo_file;
        let photoIds = treeData.photo_unique_id;
        console.log(treeData);

        if (photos) {
          let photosArray = photos.split(',');
          let photosIdArray = photoIds.split(',');

          let photoUrlArray = [];
          console.log(photosArray);
          let i = 0;
          if (photosArray.length > 0) {
            console.log(photosArray);
            photosArray.forEach(async (photo, index) => {
              let params = {
                key: photo,
              };
              const url = await context.dispatch(
                'getDownloadUrlFromS3',
                params
              );
              console.log(url);
              photoUrlArray.push({
                url: url,
                id: photosIdArray[index],
                photoKey: photo,
              });
              i++;
              console.log(photoUrlArray);
              // send each trimmed photo to s3 node to get download URL
              if (i == photosArray.length) {
                console.log(photoUrlArray);
                resolve(photoUrlArray);
              }
            });
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  async getAllTreesInProject(context, projectId) {
    return new Promise(async (resolve, reject) => {
      const response = await context.dispatch('protectedApiRequest', {
        route: `trees/project/all?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        context.commit('updateTreesInProject', response.data.data);
        resolve();
      } else {
        reject(error);
      }
    });
  },
  async getTreeStatsByProject(context, projectId) {
    return new Promise(async (resolve, reject) => {
      const response = await context.dispatch('protectedApiRequest', {
        route: `trees/stats?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        await context.commit('updateTreesStatsByProject', response.data.data);
        resolve();
      } else {
        reject(error);
      }
    });
  },
  async getAllTreesInProjectNearMe(context, arr) {
    const projectId = arr[0];
    const extent = arr[1];
    const response = await context.dispatch('protectedApiRequest', {
      route: `trees/project?projectId=${projectId}&extent=${extent}`,
      type: 'GET',
    });
    context.commit('updateTreesInProjectNearMe', response.data.data);
  },
  async getTreeList(context) {
    const response = await context.dispatch('protectedApiRequest', {
      route: `trees/list`,
      type: 'GET',
    });
    context.commit('updateTreeList', response.data.data);
  },
  async convertToGeojson(context, geoJson) {
    let geoJsonReformat = {
      type: 'FeatureCollection',
      features: [],
    };
    geoJson.forEach((item) => {
      geoJsonReformat.features.push(JSON.parse(item.point_geojson));
    });

    context.state.treesGeoJson = geoJsonReformat;
  },
  async convertSelectedToGeojson(context, geoJson) {
    let geoJsonReformat = {
      type: 'FeatureCollection',
      features: [],
    };
    geoJson.forEach((item) => {
      geoJsonReformat.features.push(JSON.parse(item.point_geojson));
    });
    context.state.selectedTreesGeoJson = geoJsonReformat;
  },
  async createNewTree(context, data) {
    const projectId = data.projectId;
    const subId = data.subId;
    const inName = data.inName;
    const inLatitude = data.inLatitude;
    const inLongitude = data.inLongitude;
    const inAddress = data.inAddress;
    const rootConfig = data.rootConfig;
    const containerSize = data.containerSize;
    const caliperSize = data.caliperSize;
    const inDbh = data.inDbh;
    const inTreeheight = data.inTreeheight;
    const inNotes = data.inNotes;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: 'tree',
      type: 'POST',
      body: {
        projectId,
        subId,
        inName,
        inLatitude,
        inLongitude,
        inAddress,
        rootConfig,
        containerSize,
        caliperSize,
        inDbh,
        inTreeheight,
        inNotes,
      },
    });
    if (response.status === 200) {
      try {
        context.commit('updateSavingEdit', 1);
        await context.commit('updateLeftDrawer', { open: false });
        await context.commit('updateShowAddNewTree', false);
        await context.dispatch('getTreesByMapExtent');
        await context.commit('updateSelectedTree', response.data.data[0]);
        // call procedure to get activies from revisit table
        context.commit('updateLeftDrawer', {
          open: true,
          content: 'treehome',
        });
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async editTree(context, data) {
    const projectId = data.projectId;
    const treeId = data.treeId;
    const subId = data.subId;
    const inName = data.inName;
    const inAddress = data.inAddress;
    const rootConfig = data.rootConfig;
    const containerSize = data.containerSize;
    const caliperSize = data.caliperSize;
    const inDbh = data.inDbh;
    const inTreeheight = data.inTreeheight;
    const inNotes = data.inNotes;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: 'tree',
      type: 'PUT',
      body: {
        projectId,
        treeId,
        subId,
        inName,
        inAddress,
        rootConfig,
        containerSize,
        caliperSize,
        inDbh,
        inTreeheight,
        inNotes,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        await context.dispatch('getTreeById', [data.treeId, data.projectId]);
        await context.commit('updateLeftDrawer', {
          open: false,
          content: '',
        });
        await context.commit('updateLeftDrawer', {
          open: true,
          content: 'treehome',
        });
        await context.commit('updateEditTreeMode', false);
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async deletePhotoTree(context, params) {
    const response = await context.dispatch('protectedApiRequest', {
      route: `photo/tree/delete`,
      type: 'POST',
      body: {
        id: params.id,
        key: params.photoKey,
      },
    });
    console.log(response);

    if (response.status == 200) {
      context.dispatch('getTreeById', [
        context.state.selectedTree.tree_id,
        context.state.selectedTree.project_id,
      ]);
    }
  },
  async addPhotoToTree(context, file) {
    const uuid = uuidv4();
    console.log(file.type);

    const params = {
      file: file,
      fileName: 'testFile.png',
      uuid: uuidv4(),
    };
    await context.dispatch('uploadPhotoToS3', params); // wait for response from s3 upload
    // we need a tree ID, we will get tree id from tree details
    // 1. on upload photo trigger, get file and metadata
    // 2. convert file to BLOB
    // 3. make a request to teh API for an uploadURL, this takes the bucket, filetype, key/name
    // 4. make PUT fetch request with uploadUrl and BLOB, and filetype, and key
    // 5. make POST request: procedure name: call insert_photo_tree(treeId (INT), photoFile (STR));
    // api path: POST photo/tree
    // let body = {
    //   treeId: '986687',
    //   photoFile: 'mattsPhoto.jpg'
    //   projectId: '1262' // admin project id for testing, can also be null
    // }
    // 6. refresh photos on screen, by calling getTreeById(treeId)
  },
  async getVisitsByTreeId(context, val) {
    return new Promise(async (resolve, reject) => {
      let treeId = val;
      const response = await context.dispatch('protectedApiRequest', {
        route: `trees/visits?treeId=${treeId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          const treeVisits = response.data.data;
          await context.commit('updateSelectedTreeVisits', treeVisits);
          resolve();
        } catch (error) {
          reject(error);
          console.log(error);
        }
      } else {
        reject(error);
        console.log(error);
      }
    });
  },
  async getVisitDetails(context, val) {
    try {
      let visitId = val;
      const response = await context.dispatch('protectedApiRequest', {
        route: `trees/visit?visitId=${visitId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          const visitDetails = response.data.data[0];
          await context.commit('updateSelectedVisitDetails', visitDetails);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getHealthcheckCounts(context, data) {
    const subId = data[0];
    const treeId = data[1];
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `tree/healthcheck?subId=${subId}&treeId=${treeId}`,
        type: 'GET',
      });
      context.commit('updateHealthcheckCounts', response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async insertHealthCheck(context, obj) {
    const treeId = obj.treeId;
    const subId = obj.subId;
    const projectId = obj.projectId;
    const inDbh = obj.inDbh;
    const inTreeheight = obj.inTreeheight;
    const inNotes = obj.inNotes;
    const fineTwigDieback = obj.fineTwigDieback;
    const leafDefoliation = obj.leafDefoliation;
    const leafDiscoloration = obj.leafDiscoloration;
    const crownVigor = obj.crownVigor;
    const crownLightExposure = obj.crownLightExposure;
    const crownDensityTrans = obj.crownDensityTrans;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/tree/healthcheck',
      type: 'POST',
      body: {
        treeId,
        subId,
        projectId,
        inDbh,
        inTreeheight,
        inNotes,
        fineTwigDieback,
        leafDefoliation,
        leafDiscoloration,
        crownVigor,
        crownLightExposure,
        crownDensityTrans,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        obj.tree;
        await context.dispatch('getTreeById', [obj.treeId, obj.projectId]);
        await context.commit('updateLeftDrawer', {
          open: false,
          content: '',
        });
        await context.commit('updateLeftDrawer', {
          open: true,
          content: 'treehome',
        });
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async editHealthCheck(context, obj) {
    const visitId = obj.visitId;
    const treeId = obj.treeId;
    const subId = obj.subId;
    const projectId = obj.projectId;
    const inDbh = obj.inDbh;
    const inTreeheight = obj.inTreeheight;
    const inNotes = obj.inNotes;
    const fineTwigDieback = obj.fineTwigDieback;
    const leafDefoliation = obj.leafDefoliation;
    const leafDiscoloration = obj.leafDiscoloration;
    const crownVigor = obj.crownVigor;
    const crownLightExposure = obj.crownLightExposure;
    const crownDensityTrans = obj.crownDensityTrans;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/tree/healthcheck',
      type: 'PUT',
      body: {
        visitId,
        projectId,
        inDbh,
        inTreeheight,
        inNotes,
        fineTwigDieback,
        leafDefoliation,
        leafDiscoloration,
        crownVigor,
        crownLightExposure,
        crownDensityTrans,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        obj.tree;
        await context.dispatch('getTreeById', [obj.treeId, obj.projectId]);
        await context.commit('updateEditTreeMode', false);
        await context.dispatch('getVisitDetails', obj.visitId);
        await context.commit('updateHealthCheck', false);
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async insertPestDetection(context, obj) {
    const treeId = obj.treeId;
    const projectId = obj.projectId;
    const inDbh = obj.inDbh;
    const inTreeheight = obj.inTreeheight;
    const inNotes = obj.inNotes;
    const exitHoles = obj.exitHoles;
    const inExudation = obj.inExudation;
    const inEggs = obj.inEggs;
    const adultInsectOrLarvaAppearance = obj.adultInsectOrLarvaAppearance;
    const fruitDamage = obj.fruitDamage;
    const holesInLeaves = obj.holesInLeaves;
    const inFrass = obj.inFrass;
    const epicormicSprouts = obj.epicormicSprouts;
    const blonding = obj.blonding;
    const sShapedGalleries = obj.sShapedGalleries;
    const barkFissures = obj.barkFissures;
    const deadBark = obj.deadBark;
    const inGalls = obj.inGalls;
    const deadTwigs = obj.deadTwigs;
    const discolorationDeciduous = obj.discolorationDeciduous;
    const discolorationConifer = obj.discolorationConifer;
    const wiltedLeaves = obj.wiltedLeaves;
    const prematureLeafLoss = obj.prematureLeafLoss;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/tree/pestdetection',
      type: 'POST',
      body: {
        treeId,
        projectId,
        inDbh,
        inTreeheight,
        inNotes,
        exitHoles,
        inExudation,
        inEggs,
        adultInsectOrLarvaAppearance,
        fruitDamage,
        holesInLeaves,
        inFrass,
        epicormicSprouts,
        blonding,
        sShapedGalleries,
        barkFissures,
        deadBark,
        inGalls,
        deadTwigs,
        discolorationDeciduous,
        discolorationConifer,
        wiltedLeaves,
        prematureLeafLoss,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        obj.tree;
        await context.dispatch('getTreeById', [obj.treeId, obj.projectId]);
        await context.commit('updateLeftDrawer', {
          open: false,
          content: '',
        });
        await context.commit('updateLeftDrawer', {
          open: true,
          content: 'treehome',
        });
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async editPestDetection(context, obj) {
    const visitId = obj.visitId;
    const projectId = obj.projectId;
    const inDbh = obj.inDbh;
    const inTreeheight = obj.inTreeheight;
    const inNotes = obj.inNotes;
    const exitHoles = obj.exitHoles;
    const inExudation = obj.inExudation;
    const inEggs = obj.inEggs;
    const adultInsectOrLarvaAppearance = obj.adultInsectOrLarvaAppearance;
    const fruitDamage = obj.fruitDamage;
    const holesInLeaves = obj.holesInLeaves;
    const inFrass = obj.inFrass;
    const epicormicSprouts = obj.epicormicSprouts;
    const blonding = obj.blonding;
    const sShapedGalleries = obj.sShapedGalleries;
    const barkFissures = obj.barkFissures;
    const deadBark = obj.deadBark;
    const inGalls = obj.inGalls;
    const deadTwigs = obj.deadTwigs;
    const discolorationDeciduous = obj.discolorationDeciduous;
    const discolorationConifer = obj.discolorationConifer;
    const wiltedLeaves = obj.wiltedLeaves;
    const prematureLeafLoss = obj.prematureLeafLoss;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/tree/pestdetection',
      type: 'PUT',
      body: {
        visitId,
        projectId,
        inDbh,
        inTreeheight,
        inNotes,
        exitHoles,
        inExudation,
        inEggs,
        adultInsectOrLarvaAppearance,
        fruitDamage,
        holesInLeaves,
        inFrass,
        epicormicSprouts,
        blonding,
        sShapedGalleries,
        barkFissures,
        deadBark,
        inGalls,
        deadTwigs,
        discolorationDeciduous,
        discolorationConifer,
        wiltedLeaves,
        prematureLeafLoss,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        obj.tree;
        await context.dispatch('getTreeById', [obj.treeId, obj.projectId]);
        await context.commit('updateEditTreeMode', false);
        await context.dispatch('getVisitDetails', obj.visitId);
        await context.commit('updatePestDetection', false);
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async insertTreeCare(context, obj) {
    const treeId = obj.treeId;
    const projectId = obj.projectId;
    const inDbh = obj.inDbh;
    const inTreeheight = obj.inTreeheight;
    const inNotes = obj.inNotes;
    const timeSpent = obj.timeSpent;
    const stewardshipTasks = obj.stewardshipTasks;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/tree/treecare',
      type: 'POST',
      body: {
        treeId,
        projectId,
        inDbh,
        inTreeheight,
        inNotes,
        timeSpent,
        stewardshipTasks,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        obj.tree;
        await context.dispatch('getTreeById', [obj.treeId, obj.projectId]);
        await context.commit('updateLeftDrawer', {
          open: false,
          content: '',
        });
        await context.commit('updateLeftDrawer', {
          open: true,
          content: 'treehome',
        });
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async editTreeCare(context, obj) {
    const visitId = obj.visitId;
    const projectId = obj.projectId;
    const inDbh = obj.inDbh;
    const inTreeheight = obj.inTreeheight;
    const inNotes = obj.inNotes;
    const timeSpent = obj.timeSpent;
    const stewardshipTasks = obj.stewardshipTasks;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/tree/treecare',
      type: 'PUT',
      body: {
        visitId,
        projectId,
        inDbh,
        inTreeheight,
        inNotes,
        timeSpent,
        stewardshipTasks,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      console.log(obj);
      try {
        obj.tree;
        await context.dispatch('getTreeById', [obj.treeId, obj.projectId]);
        await context.commit('updateEditTreeMode', false);
        await context.dispatch('getVisitDetails', obj.visitId);
        await context.commit('updateTreeCare', false);
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  async addTreeToProject(context, obj) {
    let projectId = obj.projectId;
    let treeId = obj.treeId;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/tree/projectxtree',
      type: 'POST',
      body: {
        projectId,
        treeId,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        obj.tree;
      } catch (error) {
        console.log(error);
      }
    } else {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async removeTreeFromProject(context, obj) {
    try {
      let projectId = obj.projectId;
      let treeId = obj.treeId;
      context.commit('updateSavingEdit', 0);
      const response = await context.dispatch('protectedApiRequest', {
        route: `/tree/projectxtree?treeId=${treeId}&projectId=${projectId}`,
        type: 'DELETE',
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
