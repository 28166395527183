<template>
  <q-card-section class="bg-blue-grey-1">
    <div class="text-body1">
      Project Participation Requests
    </div>
  </q-card-section>
  <q-separator />
  <q-card-section>
    <q-list class="q-mx-md">
      <q-item v-if="this.$store.state.userStore.memberRequests.length == 0">
        <q-item-section class="col text-left">
          <q-item-label>No requests for this project</q-item-label>
        </q-item-section>
      </q-item>
      <div
        v-else
        v-for="(member, key) in this.$store.state.userStore.memberRequests"
        :key="key"
        class="row items-center no-wrap q-py-md"
      >
        <div class="text-left col-auto q-mr-md">
          <q-item-label class="text-bold">
            {{ member.requester_name }}
          </q-item-label>
          <q-item-label class="text-caption">
            {{ member.requester_email }}
          </q-item-label>
        </div>
        <div class="col">
          <q-radio
            v-model="member.role"
            label="Participant"
            val="3"
            color="primary"
            dense
          ></q-radio>

          <q-radio
            dense
            v-model="member.role"
            label="Project Leader"
            val="2"
            class="q-ml-sm"
          ></q-radio>
        </div>
        <div class="row col-auto">
          <q-btn
            color="secondary"
            class="q-mr-md"
            flat
            @click="approveMember(member)"
            label="approve"
            style="width:90px;"
            padding="sm"
          ></q-btn>
          <q-btn
            flat
            padding="sm"
            color="red"
            label="deny"
            style="width:90px;"
            @click="denyMember(member)"
          ></q-btn>
        </div>
      </div>
    </q-list>
  </q-card-section>
  <q-separator />
</template>

<script>
export default {
  name: 'approvals',
  mounted() {
    let projectId = this.$router.currentRoute.value.params.index;
    this.$store.dispatch('getMemberRequests', projectId);
  },
  data() {
    return {
      role: 3,
    };
  },
  methods: {
    approveMember(member) {
      let role = parseInt(member.role);
      if (!member.role) {
        role = 3;
      }
      this.$store.dispatch('approveMember', {
        projectId: member.project_id,
        requestId: member.request_id,
        roleId: role,
      });
    },
    denyMember(member) {
      console.log(member);
      this.$store.dispatch('denyMember', {
        projectId: member.project_id,
        requestId: member.request_id,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.semi-bold {
  font-weight: 600;
}
</style>
