<template>
  <q-scroll-area class="bg-white shadow-1" style="height:calc(100vh - 86px)">
    <div
      :style="$q.screen.lt.sm ? 'padding-bottom:50px' : ''"
      class="bg-white q-pa-md"
    >
      <dialog-approvals
        v-if="activeProject.role_name == 'Project Leader'"
      ></dialog-approvals>
      <q-card-section class="bg-blue-grey-1">
        <div class="text-body1">
          Project Members ({{ projectUsers.length }})
        </div>
      </q-card-section>
      <div>
        <q-item v-for="(user, key) in projectUsers" :key="key">
          <q-item-section avatar>
            <q-avatar
              color="secondary"
              text-color="white"
              font-size="20px"
              size="40px"
            >
              {{ Array.from(user.fullname)[0] }}
            </q-avatar>
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ user.fullname }}</q-item-label>
            <q-item-label caption lines="1">{{ user.role_name }}</q-item-label>
            <!-- <q-item-label caption lines="1">{{ user.email }}</q-item-label> -->
          </q-item-section>

          <q-item-section side>
            <q-select
              v-if="activeProject.role_name == 'Project Leader'"
              label="Change Role"
              style="min-width:130px"
              v-model="user.role_name"
              @update:model-value="changeUserRole(user)"
              :options="['Participant', 'Project Leader']"
            ></q-select>
          </q-item-section>
          <q-item-section side>
            <q-btn
              v-if="activeProject.role_name == 'Project Leader'"
              class="gt-xs"
              size="12px"
              flat
              dense
              round
              icon="delete"
              color="red-8"
              @click="removeUser(user)"
            >
              <q-tooltip>
                Remove User
              </q-tooltip></q-btn
            >
          </q-item-section>
        </q-item>
      </div>
    </div>
  </q-scroll-area>
</template>

<script>
import DialogApprovals from './DialogApprovals.vue';
export default {
  name: 'ActiveProjectUsers',
  computed: {
    projectUsers() {
      return this.$store.state.userStore.projectUsers;
    },
    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
    userSelectOptions() {
      let userSelectOptions = [];
      for (let i = 0; i < this.projectUsers.length; i++) {
        userSelectOptions.push(this.projecUsers[i].fullname);
      }
      return userSelectOptions;
    },
  },
  components: { DialogApprovals },
  data() {
    return {};
  },
  methods: {
    removeUser(user) {
      //you cannot delete last member
      if (this.projectUsers.length > 1) {
        let obj = {
          projectId: this.activeProject.project_id,
          userId: user.user_id,
        };
        this.$store.dispatch('removeMember', obj);
      } else {
        alert('There must be at least one project leader on a project');
      }
    },
    changeUserRole(user) {
      console.log(user);
      let roleId = user.role_name == 'Project Leader' ? 2 : 3;
      console.log(roleId);
      //check to see if there is a project leader

      let lead = this.projectUsers.find(
        (o) => o.role_name === 'Project Leader'
      );
      console.log(lead);
      if (lead) {
        let obj = {
          projectId: this.activeProject.project_id,
          roleId: roleId,
          userId: user.user_id,
        };
        //there needs to be at least one project lead
        this.$store.dispatch('updateUserRole', obj);
      } else {
        alert('There must be at least one project leader on a project');
        let u = this.projectUsers.find((o) => o.user_id == user.user_id);
        u.role_name = 'Project Leader';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
