<template>
  <q-toolbar class="bg-blue-grey-9 text-white">
    <q-btn
      icon="menu"
      flat
      round
      color=""
      @click="toggleDrawer"
      v-if="$q.screen.lt.md"
    >
    </q-btn>
    <q-toolbar-title class="text-subtitle1">My Projects</q-toolbar-title>
    <q-space></q-space>
    <q-btn-toggle
      v-model="projectStatus"
      unelevated
      toggle-color="secondary"
      toggle-text-color="white"
      class="text-white q-mr-md "
      :options="[
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]"
    />
    <q-btn padding="xs" round flat color="white" icon="help"
      ><q-menu class="" style="width:50%">
        <div class="q-ma-md">
          This is a list of all projects where you are a participant or project
          leader. Open a project to see more information. Click the
          active/inactive toggle button to view all projects.
        </div></q-menu
      ></q-btn
    >
  </q-toolbar>
  <q-scroll-area class="bg-white shadow-1" style="height:calc(100vh - 86px)">
    <div :style="$q.screen.lt.sm ? 'padding-bottom:100px' : ''">
      <div class="q-mx-lg q-my-md">
        <q-select
          filled
          v-model="selectedProject"
          use-input
          hide-selected
          fill-input
          input-debounce="0"
          :options="this.options"
          option-value="project_id"
          option-label="project_name"
          @filter="filterFn"
          label="Begin typing to search projects"
          @update:model-value="goToProject(selectedProject)"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <q-list>
        <q-item
          v-for="(proj, key) in projects"
          :key="key"
          class="text-left q-pa-md q-mx-lg"
          style="border-bottom: 1px solid lightgray"
        >
          <q-item-section avatar>
            <q-avatar color="grey-5" text-color="white">
              <img
                src="/img/hthc-logo.png"
                :style="this.projectStatus == 'inactive' ? 'opacity:.3' : ''"
              />
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ proj.project_name }}</q-item-label>
            <q-item-label caption class="text-bold">{{
              proj.role_name
            }}</q-item-label>
          </q-item-section>
          <q-item-section side v-if="proj.requests_pending == 1">
            <span class="text-blue-grey-6 text-caption"
              ><q-icon size="sm" name="pending_actions"></q-icon> APPROVE
              MEMBERS</span
            >
          </q-item-section>
          <q-item-section side>
            <q-btn
              flat
              name=""
              label="Open"
              color="secondary"
              @click="
                //this.activeProject = proj;
                goToProject(proj)
              "
            />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-scroll-area>
</template>

<script>
export default {
  components: {},
  name: 'MyProjects',
  computed: {
    projects() {
      let projects = this.$store.state.userStore.usersProjects;
      let status = this.projectStatus == 'active' ? 1 : 0;
      let visibleProj = projects.filter(function(val) {
        return val.visible == status;
      });
      console.log(visibleProj);
      return visibleProj;
    },

    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
  },
  activated() {
    console.log('activated');
    //open most recent project in session
  },
  data() {
    return {
      projectStatus: 'active',
      options: [],
      selectedProject: '',
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
    goToProject(proj) {
      //update the active project index in the store and route to selected project
      console.log(proj);
      this.$router.push('/dashboard/project/' + proj.project_id + '/map');
    },
    filterFn(val, update, abort) {
      update(() => {
        console.log(val);
        const needle = val.toLowerCase();
        this.options = this.projects.filter((option) => {
          return option.project_name.toLowerCase().indexOf(needle) > -1;
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
