<template>
  <q-scroll-area class="bg-white shadow-1" style="height:calc(100vh - 86px)">
    <div
      :style="$q.screen.lt.sm ? 'padding-bottom:50px' : ''"
      class="bg-white q-pa-md"
    >
      <div>
        <p class="text-h6 text-blue-grey-9 text-center q-mb-none">
          QAQC Process
        </p>
        <p class=" text-grey-8 text-center">
          This tool allows project leaders to create a sample subset of health
          checks performed by users that can be verified using the field tool.
          Results will show which users have incorrectly input tree health data
          within an accuracy range. Visit the help on this page for more
          details.
        </p>
        <q-list v-for="project in qaqcProjects" :key="project.qaqc_id">
          <q-item class="shadow-1" style="border: 1px solid lightgray">
            <q-item-section class="text-body1">
              <q-item-label>Begin Date: {{ project.date_added }}</q-item-label>
              <q-item-label v-if="project.status !== 'COMPLETED'">
                <q-btn
                  flat
                  label="set status"
                  icon="settings"
                  size="sm"
                  color="blue-grey-5"
                  padding="none"
                >
                  <q-menu style="width: 300px; height: 200px">
                    <div class="q-ma-md">
                      <p class="text-body1">
                        Set QAQC status
                      </p>
                      <q-separator></q-separator>
                      <q-checkbox v-model="setstatus"
                        >Set status to complete</q-checkbox
                      >
                      <p class="text-caption text-red">
                        *Warning, this cannot be undone
                      </p>
                      <q-btn
                        label="save"
                        color="primary"
                        @click="
                          setCompleted(project.qaqc_id, project.project_id)
                        "
                      ></q-btn>
                    </div>
                  </q-menu>
                </q-btn>
              </q-item-label>
            </q-item-section>

            <q-item-section side class="">
              <q-item-label class="text-secondary text-caption"
                >STATUS:
                {{ project.status }}
                &nbsp; &nbsp;

                <q-linear-progress
                  v-if="project.status !== 'COMPLETED'"
                  size="20px"
                  :value="progressValue"
                  color="blue-grey-9"
                  class="q-mt-xs"
                  style="width:200px"
                >
                  <div class="absolute-full flex flex-center">
                    <q-badge
                      color="white"
                      text-color="primary"
                      :label="progressLabel"
                    />
                  </div> </q-linear-progress
              ></q-item-label>
            </q-item-section>

            <q-item-section side>
              <q-btn
                unelevated
                outline
                color="secondary"
                :icon-right="project.showVisits ? 'expand_less' : 'expand_more'"
                @click="
                  project.showVisits = !project.showVisits;
                  project.showMap = false;
                  this.view = 'table';
                  closeVisitTable(project.qaqc_id);
                  getVisits(
                    project.qaqc_id,
                    project.project_id,
                    project.showVisits
                  );
                "
                >VISITS</q-btn
              >
            </q-item-section>
          </q-item>
          <q-slide-transition>
            <div v-show="project.showVisits">
              <q-btn-toggle
                flat
                unelevated
                v-model="view"
                spread
                toggle-color="primary"
                color="blue-grey-1"
                text-color="black"
                :options="[
                  { label: 'View Table', value: 'table' },
                  { label: 'View Map', value: 'map' },
                ]"
                @update:model-value="changeView(project)"
              ></q-btn-toggle>
              <q-table
                :rows="qaqcVisits"
                :columns="columns"
                row-key="visitId"
                flat
                bordered
                v-if="view == 'table'"
              >
                <template v-slot:header="props">
                  <q-tr :props="props">
                    <q-th auto-width />
                    <q-th
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props"
                    >
                      {{ col.label }}
                    </q-th>
                  </q-tr>
                </template>

                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td auto-width>
                      <q-btn
                        size="sm"
                        color="secondary"
                        round
                        dense
                        @click="props.expand = !props.expand"
                        :icon="props.expand ? 'remove' : 'add'"
                        :disable="
                          props.cols[0].value == 'Completed' ? false : true
                        "
                      />
                    </q-td>
                    <q-td
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props"
                    >
                      {{ col.value }}
                    </q-td>
                  </q-tr>
                  <q-tr v-show="props.expand" :props="props" dense>
                    <q-td colspan="100%">
                      <div class="text-left">
                        <q-table
                          v-if="qaqcVisits[props.cols[6].value]"
                          flat
                          bordered
                          dense
                          title="QAQC Measurement Comparison"
                          title-class="text-body2"
                          :rows="qaqcVisits[props.cols[6].value].data"
                          :columns="columns2"
                          row-key="name"
                          separator="vertical"
                          hide-bottom
                          class="bg-blue-grey-1 text-dark"
                          :pagination="{ rowsPerPage: 0 }"
                        >
                          <template v-slot:body="props">
                            <q-tr :props="props" style="height:20px">
                              <q-td key="measurement" :props="props">{{
                                props.row.measurement
                              }}</q-td>
                              <q-td key="initialVal" :props="props">{{
                                props.row.initialVal
                              }}</q-td>
                              <q-td key="qaqcVal" :props="props">{{
                                props.row.qaqcVal
                              }}</q-td>
                              <q-td key="range" :props="props">{{
                                props.row.range
                              }}</q-td>
                              <q-td key="withinRange" :props="props"
                                ><q-icon
                                  :name="
                                    props.row.withinRange ? 'check' : 'close'
                                  "
                                  :color="
                                    props.row.withinRange ? 'green' : 'red'
                                  "
                                  size="sm"
                              /></q-td>
                            </q-tr> </template
                        ></q-table>
                      </div>
                    </q-td>
                  </q-tr> </template
              ></q-table>
              <div>
                <p
                  v-if="
                    view == 'map' &&
                      project.showMap == true &&
                      project.status == 'COMPLETED'
                  "
                  key=""
                  class="q-ma-md text-center"
                >
                  Map is disabled for completed QAQC
                </p>
              </div>
              <div
                v-if="
                  view == 'map' &&
                    project.showMap == true &&
                    project.status == 'ACTIVE'
                "
              >
                <MapQAQC></MapQAQC>
              </div>
            </div>
          </q-slide-transition>
        </q-list>
      </div>
      <div class="text-center q-pt-xl">
        <q-btn
          color="secondary"
          unelevated
          @click="showForm = true"
          label="Start New QAQC"
          v-if="!this.activeQAQC"
        ></q-btn>
      </div>

      <q-form
        @submit="onSubmit"
        @reset="onReset"
        class="q-gutter-sm bg-grey-1 shadow-1 q-ma-lg"
        v-if="this.showForm"
      >
        <div class="q-pa-lg">
          <p class="text-subtitle2 text-center q-mb-none">
            Create New QAQC for this project
          </p>
          <p class="text-center">
            Customize start and end dates to create a sample subset of health
            checks for quality review. Defaults to current year.
          </p>
          <div class="row justify-center">
            <div class="col-md-4 col-sm-12">
              <q-input
                filled
                bottom-slots
                v-model="startDateFormat"
                label="Start Date"
                dense
                class="q-pa-md"
                :readonly="true"
              >
                <template v-slot:append>
                  <q-btn
                    dense
                    flat
                    icon="calendar_month"
                    size="lg"
                    color="primary"
                    padding="none"
                    class=""
                    @click="this.showStartDate = !this.showStartDate"
                    ><q-menu :offset="[0, 10]">
                      <q-date
                        class=""
                        v-model="startDate"
                        minimal
                        this.showForm="false;"
                      ></q-date> </q-menu
                  ></q-btn>
                </template>
              </q-input>
            </div>
            <div class="col-md-4 col-sm-12">
              <q-input
                filled
                bottom-slots
                v-model="endDateFormat"
                label="End Date"
                dense
                class="q-pa-md"
                :readonly="true"
              >
                <template v-slot:append>
                  <q-btn
                    dense
                    flat
                    icon="calendar_month"
                    size="lg"
                    color="primary"
                    padding="none"
                    @click="this.showEndDate = !this.showEndDate"
                    ><q-menu :offset="[0, 10]">
                      <q-date
                        class=""
                        v-model="endDate"
                        minimal
                      ></q-date></q-menu
                  ></q-btn>
                </template>
              </q-input>
            </div>
          </div>

          <div
            v-if="showError"
            class="text-red text caption q-mb-md text-center"
          >
            End date must be after start date.
          </div>
          <div class="text-center">
            <q-btn
              class="q-mr-xs"
              color="secondary"
              unelevated
              outline
              type="submit"
              >submit</q-btn
            >
            <q-btn
              class="q-ml-xs"
              color="orange"
              unelevated
              outline
              type="reset"
              >cancel</q-btn
            >
          </div>
        </div>
      </q-form>
    </div>
  </q-scroll-area>
</template>

<script>
import MapQAQC from './MapQAQC.vue';
const year = new Date().getFullYear();
export default {
  name: 'ActiveProjectQAQC',
  components: { MapQAQC },
  computed: {
    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
    qaqcProjects() {
      //format qaqc projects
      let proj = this.$store.state.qaqcStore.qaqcByProject;
      this.activeQAQC = false;
      let _this = this;

      proj.forEach(function(proj) {
        proj.date_added = new Date(proj.date_added).toLocaleDateString(
          'en-us',
          {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }
        );
        proj.status = proj.date_completed ? 'COMPLETED' : 'ACTIVE';

        proj.showVisits = false;
        proj.showMap = false;

        if (proj.status == 'ACTIVE') {
          _this.getVisits(proj.qaqc_id, proj.project_id);
          _this.$store.commit('updateActiveQAQCProject', proj);
          _this.activeQAQC = true;
        }
      });

      console.log(proj);
      return proj;
    },
    qaqcVisits() {
      //format qaqcVisits
      let visits = this.$store.state.qaqcStore.qaqcVisits;
      let compare = this.$store.state.qaqcStore.qaqcCompare;
      let rows = [];
      this.totalVisitsCount = visits.length;
      this.completeVisitsCount = compare.length;

      for (let i = 0; i < compare.length; i++) {
        let getcompare = this.qaqcCompare(compare[i]);
        rows.push({
          visitId: compare[i].visit_id,
          status: 'Completed',
          user: compare[i].qaqc_user,
          visitDate: new Date(compare[i].qaqc_date_visited).toLocaleDateString(
            'en-us',
            {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }
          ),
          values: compare[i],
          accuracy: Math.round(getcompare.accuracy) + '%',
          initVisitDate: new Date(
            compare[i].first_date_visited
          ).toLocaleDateString('en-us', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          initUser: compare[i].first_user,
          index: i,
          data: getcompare.rows,
        });
      }
      for (let i = 0; i < visits.length; i++) {
        if (!visits[i].date_visited) {
          rows.push({
            visitId: visits[i].visit_id,
            status: visits[i].date_visited ? 'Completed' : 'Not Started',
            user: visits[i].date_visited ? visits[i].user_name : '',
            visitDate: visits[i].date_visited
              ? new Date(visits[i].date_visited).toLocaleDateString('en-us', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
              : '',
            accuracy: '',
          });
        }
      }

      return rows;
    },
    startDateFormat() {
      let dateFormat = new Date(this.startDate).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return dateFormat;
    },
    endDateFormat() {
      let dateFormat = new Date(this.endDate).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return dateFormat;
    },
    progressValue() {
      let percent =
        Number(this.completeVisitsCount) / Number(this.totalVisitsCount);
      return percent;
    },
    progressLabel() {
      let label = this.progressValue * 100;
      return label + '%';
    },
  },
  data() {
    return {
      activeQAQC: false,
      setstatus: true,
      totalVisitsCount: 0,
      completeVisitsCount: 0,
      showForm: false,
      showStartDate: false,
      showEndDate: false,
      startDate: year + '/1/1',
      endDate: year + '/12/31',
      name: '',
      view: 'table',
      showError: false,
      showRecords: false,
      columns: [
        {
          name: 'status',
          label: 'QAQC Visit Status',
          align: 'left',
          field: (row) => row.status,
          sortable: true,
        },

        {
          name: 'qaqcDate',
          label: 'QAQC Visit Date',
          align: 'left',
          field: (row) => row.visitDate,
          sortable: true,
        },
        {
          name: 'qaqcUser',
          label: 'QAQC Completed By',
          align: 'left',
          field: (row) => row.user,
          sortable: true,
        },
        {
          name: 'initDate',
          label: 'Initial Visit Date',
          align: 'left',
          field: (row) => row.initVisitDate,
          sortable: true,
        },

        {
          name: 'initUser',
          label: 'Initial User',
          align: 'left',
          field: (row) => row.initUser,
          sortable: true,
        },
        {
          name: 'accuracy',
          label: 'Accuracy',
          align: 'left',
          field: (row) => row.accuracy,
          sortable: true,
        },
        {
          name: 'index',
          label: 'ID',
          align: 'left',
          field: (row) => row.index,
          sortable: false,
        },
      ],
      visibleColumns: [
        'status',
        'qaqcDate',
        'qaqcUser',
        'initDate',
        'initUser',
        'accuracy',
      ],

      columns2: [
        {
          name: 'measurement',
          required: true,
          label: 'Measurement',
          align: 'left',
          field: (row) => row.measurement,
          sortable: true,
        },
        {
          name: 'initialVal',
          required: true,
          label: 'Initial Visit Value',
          align: 'left',
          field: (row) => row.initialVal,
          sortable: true,
        },

        {
          name: 'qaqcVal',
          required: true,
          label: 'QAQC Visit Value',
          align: 'left',
          field: (row) => row.qaqcVal,
          sortable: true,
        },
        {
          name: 'range',
          required: true,
          label: 'Range',
          align: 'left',
          field: (row) => row.range,
          sortable: true,
        },
        {
          name: 'withinRange',
          required: true,
          label: 'Within Range',
          align: 'center',
          field: (row) => row.withinRange,
          sortable: true,
        },
      ],
      rows2: [],
    };
  },
  methods: {
    dateCheck() {
      let validated = true;
      let start = new Date(this.startDate);
      let end = new Date(this.endDate);

      if (start > end) {
        validated = false;
      }
      return validated;
    },
    onSubmit() {
      let validated = this.dateCheck();
      if (validated) {
        this.showForm = false;
        this.showError = false;
        this.showRecords = true;
        let obj = {
          projectId: this.activeProject.project_id,
          startDate: this.startDate.replace('/', '-'),
          endDate: this.endDate.replace('/', '-'),
        };
        this.$store.dispatch('createQAQCVisits', obj);
      } else {
        this.showError = true;
      }
    },
    onReset() {
      this.startDate = year + '/1/1';
      this.endDate = year + '/12/31';
      this.showForm = false;
    },
    closeVisitTable(id) {
      this.qaqcProjects.forEach(function(proj) {
        if (proj.qaqc_id !== id) {
          proj.showVisits = false;
        }
      });
    },
    getVisits(qaqcId, projId) {
      this.$store.dispatch('getQAQCVisits', {
        qaqcId: qaqcId,
        projectId: projId,
      });
      this.$store.dispatch('getQAQCCompare', {
        qaqcId: qaqcId,
        projectId: projId,
      });
    },
    setCompleted(qaqcId, projId) {
      this.$store.dispatch('editQAQC', {
        qaqcId: qaqcId,
        projectId: projId,
      });
    },
    changeView(project) {
      if (this.view == 'map') {
        this.$store.dispatch('getQAQCTrees', {
          qaqcId: project.qaqc_id,
          projectId: project.project_id,
        });
        project.showMap = true;
      }
    },
    qaqcCompare(obj) {
      console.log(obj);
      let rows2 = [];
      let DBH = {
        measurement: 'DBH',
        initialVal: obj.first_dbh,
        qaqcVal: obj.qaqc_dbh,
        range: '+/- 0.5',
        withinRange:
          Math.abs(obj.qaqc_dbh - obj.first_dbh) <= 0.5 ? true : false, // +- 2
      };
      let FTD = {
        measurement: 'Fine Twig Dieback',
        initialVal: obj.first_fine_twig_dieback.substring(
          0,
          obj.first_fine_twig_dieback.indexOf(' ')
        ),
        qaqcVal: obj.qaqc_fine_twig_dieback.substring(
          0,
          obj.qaqc_fine_twig_dieback.indexOf(' ')
        ),
        range: '+/- 5',
        withinRange:
          Math.abs(
            obj.qaqc_fine_twig_dieback.substring(
              0,
              obj.qaqc_fine_twig_dieback.indexOf(' ')
            ) -
              obj.first_fine_twig_dieback.substring(
                0,
                obj.first_fine_twig_dieback.indexOf(' ')
              )
          ) <= 5
            ? true
            : false, // +- 5
      };
      let LD = {
        measurement: 'Leaf Discoloration',
        initialVal: obj.first_leaf_discoloration,
        qaqcVal: obj.qaqc_leaf_discoloration,
        range: '+/- 1',
        withinRange:
          Math.abs(
            obj.qaqc_leaf_discoloration - obj.first_leaf_discoloration
          ) <= 1
            ? true
            : false, // +- 2
      };
      let LDE = {
        measurement: 'Leaf Defoliation',
        initialVal: obj.first_leaf_defoliation,
        qaqcVal: obj.qaqc_leaf_defoliation,
        range: '+/- 1',
        withinRange:
          Math.abs(obj.qaqc_leaf_defoliation - obj.first_leaf_defoliation) <= 1
            ? true
            : false, // +- 2
      };
      let CLE = {
        measurement: 'Crown Light Exposure',
        initialVal: obj.first_crown_light_exposure,
        qaqcVal: obj.qaqc_crown_light_exposure,
        range: '+/- 1',
        withinRange:
          Math.abs(
            obj.qaqc_crown_light_exposure - obj.first_crown_light_exposure
          ) <= 1
            ? true
            : false, // +- 2
      };
      let CV = {
        measurement: 'Crown Vigor',
        initialVal: obj.first_crown_vigor,
        qaqcVal: obj.qaqc_crown_vigor,
        range: '+/- 1',
        withinRange:
          Math.abs(obj.qaqc_crown_vigor - obj.first_crown_vigor) <= 1
            ? true
            : false, // +- 2
      };
      let CT = {
        measurement: 'Crown Transparency',
        initialVal: obj.first_crown_transparency,
        qaqcVal: obj.qaqc_crown_transparency,
        range: '+/- 1',
        withinRange:
          Math.abs(
            obj.qaqc_crown_transparency - obj.first_crown_transparency
          ) <= 1
            ? true
            : false, // +- 2
      };

      // let score = (#correct / #total) × 100

      rows2.push(DBH, FTD, LD, LDE, CLE, CV, CT);
      console.log(rows2);
      let correct = 0;
      for (let i = 0; i < rows2.length; i++) {
        if (rows2[i].withinRange) {
          correct = correct + 1;
        }
      }
      let accuracy = (correct / 7) * 100;
      return { accuracy: accuracy, rows: rows2 };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
