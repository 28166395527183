<template>
  <div class="text-center q-mb-sm">
    <q-checkbox
      v-model="$store.state.userStore.newProjectExtent.includeTrees"
      class="text-caption"
      size="xs"
      true-value="1"
      false-value="0"
    >
      Add trees within site boundary to project</q-checkbox
    >
  </div>
  <div class="q-header">
    <div
      id="map"
      :style="
        useBounds
          ? 'z-index: 99; height: calc(100vh - 380px); border: 4px solid red'
          : 'z-index: 99;height: calc(100vh - 380px);'
      "
    ></div>
  </div>
</template>

<script>
import mapboxgl from '/node_modules/mapbox-gl/dist/mapbox-gl.js';
import MapboxDraw from '/node_modules/@mapbox/mapbox-gl-draw';
import '/node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

let mapbox = {
  map: '',
  geolocate: '',
  draw: '',
};

export default {
  name: 'MapEdits',
  components: {},
  computed: {
    treesData() {
      return this.$store.state.treesStore.treesGeoJson;
    },
    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
    triggerNewProject() {
      return this.$store.state.createNewProject;
    },
  },
  watch: {
    triggerNewProject() {
      this.submitNewProject();
    },
  },

  props: ['newProject'],

  data() {
    return {
      useBounds: true,
      drawPolygon: '',
    };
  },
  mounted() {
    console.log('mounted');
    //since I need a different size map in various components I am passing a height prop and
    //resizing the map container on mounted. ie:  'calc(100vh - 200px)'
    document.getElementById('map').style.height = this.height;
    this.createMap();
    /*this.$q.notify({
      message:
        "Looks like you don''t have any trees in your project yet.  Would you like to see instructions for adding a tree?.",
      color: 'white',
      actions: [
        {
          color: 'primary',
          label: 'Show Me',
          handler: () => {
            //do something else
          },
        },
      ],
    });*/
  },

  methods: {
    getProjectBoundingBox() {
      // make geojson from polygon string
      let polygonString = this.activeProject.polygon_text;
      // Extract the coordinates substring from the polygon string
      const coordinatesString = polygonString.substring(
        polygonString.indexOf('((') + 2,
        polygonString.lastIndexOf('))')
      );
      // Split the coordinates substring into an array of coordinate strings
      const coordinateStrings = coordinatesString.split(',');
      // Convert each coordinate string to an array of latitude and longitude pairs
      const coordinates = coordinateStrings.map((coord) => {
        const [longitude, latitude] = coord.trim().split(' ');
        return [parseFloat(latitude), parseFloat(longitude)];
      });
      var geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        ],
      };
      var bbox = turf.bbox(geojson);
      return [bbox, geojson];
    },
    createMap() {
      let bbox = '';
      let geojson = '';
      /*   if (this.activeProject.polygon_text) {
        console.log(this.activeProject.polygon_text);
        let bboxArray = this.getProjectBoundingBox();
        bbox = bboxArray[0];
        console.log(bbox);
        geojson = bboxArray[1];
      } else {
        coords = this.$store.state.userStore.usersLocation;
      }*/
      let coords = this.$store.state.userStore.usersLocation;

      // First, create a new Mapbox map and set the center and zoom level
      mapboxgl.accessToken =
        'pk.eyJ1IjoidG5jbWFwYm94IiwiYSI6ImNsYmZpNzE0MDA2aHozbm1wazV1aWp3NHUifQ.7plkZIxeS9mzUXB06i-CLg';
      console.log;
      mapbox.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center: [coords[0], coords[1]],
        zoom: coords[2],
      });
      let _this = this;
      mapbox.map.on('load', function() {
        // mapbox.map.fitBounds(bbox, { padding: 20 });
        /*if (_this.activeProject.polygon_text) {
          mapbox.map.addSource('project-boundary', {
            type: 'geojson',
            data: geojson,
          });

          mapbox.map.addLayer({
            id: 'geojsonLayer',
            type: 'line',
            source: 'project-boundary',
            paint: {
              'line-width': 4,
              'line-color': '#000',
            },
          });

          mapbox.map.addLayer({
            id: 'geojsonLayer2',
            type: 'line',
            source: 'project-boundary',
            paint: {
              'line-width': 2,
              'line-color': '#ffff00',
              'line-dasharray': [4, 4],
            },
          });
        }*/
        _this.getMapBoundingBox();
        _this.addTreeLayerAndSource();
      });
      mapbox.draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          trash: true,
        },
        styles: [
          // ACTIVE (being drawn)
          // line stroke
          {
            id: 'gl-draw-line',
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'LineString'],
              ['==', 'active', 'true'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#D20C0C',
              'line-dasharray': [0.2, 2],
              'line-width': 2,
            },
          },
          // polygon fill
          {
            id: 'gl-draw-polygon-fill',
            type: 'fill',
            filter: [
              'all',
              ['==', '$type', 'Polygon'],
              ['==', 'active', 'true'],
            ],
            paint: {
              'fill-color': '#D20C0C',
              'fill-outline-color': '#D20C0C',
              'fill-opacity': 0.1,
            },
          },
          // polygon mid points
          {
            id: 'gl-draw-polygon-midpoint',
            type: 'circle',
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['==', 'meta', 'midpoint'],
            ],
            paint: {
              'circle-radius': 3,
              'circle-color': '#fbb03b',
            },
          },
          // polygon outline stroke
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            id: 'gl-draw-polygon-stroke-active',
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'Polygon'],
              ['==', 'active', 'true'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#D20C0C',
              'line-dasharray': [0.2, 2],
              'line-width': 2,
            },
          },
          // vertex point halos
          {
            id: 'gl-draw-polygon-and-line-vertex-halo-active',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
            paint: {
              'circle-radius': 5,
              'circle-color': '#FFF',
            },
          },
          // vertex points
          {
            id: 'gl-draw-polygon-and-line-vertex-active',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
            paint: {
              'circle-radius': 3,
              'circle-color': '#D20C0C',
            },
          },

          // INACTIVE
          // line stroke
          {
            id: 'gl-draw-line-inactive',
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'LineString'],
              ['==', 'active', 'false'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#D20C0C',
              'line-width': 3,
            },
          },
          // polygon fill
          {
            id: 'gl-draw-polygon-fill-inactive',
            type: 'fill',
            filter: [
              'all',
              ['==', '$type', 'Polygon'],
              ['==', 'active', 'false'],
            ],
            paint: {
              'fill-color': '#000',
              'fill-outline-color': '#000',
              'fill-opacity': 0.1,
            },
          },
          // polygon outline
          {
            id: 'gl-draw-polygon-stroke-inactive',
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'Polygon'],
              ['==', 'active', 'false'],
            ],
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#D20C0C',
              'line-width': 3,
            },
          },
        ],
      });
      mapbox.map.on('draw.create', function(e) {
        console.log(e.features);
        _this.drawPolygon = e.features[0];
        _this.useBounds = false;
      });
      mapbox.map.on('draw.delete', function(e) {
        let fc = mapbox.draw.getAll();
        console.log(fc.features);
        if (fc.features.length === 0) {
          _this.useBounds = true;
          _this.drawPolygon = '';
        }
      });
      mapbox.map.on('moveend', async function() {
        await _this.getMapBoundingBox();
        await _this.updateTreesLayer();
      });
      mapbox.map.addControl(mapbox.draw, 'top-left');
      // _this.addMapControls();
      this.getMapBoundingBox();
      this.updateTreesLayer();
      mapbox.map.resize();
      // map.on('load', function() {
      //   map.on('draw.create', console.log('draw create'));
      //   map.on('draw.delete', console.log('drew delete'));
      //   map.on('draw.update', console.log('draw update'));
      // });
    },
    async getMapBoundingBox() {
      return new Promise(async (resolve, reject) => {
        try {
          let boundingBoxString = `${
            mapbox.map.getBounds().getNorthWest().lat
          } ${mapbox.map.getBounds().getNorthWest().lng}, ${
            mapbox.map.getBounds().getNorthEast().lat
          } ${mapbox.map.getBounds().getNorthEast().lng}, ${
            mapbox.map.getBounds().getSouthEast().lat
          } ${mapbox.map.getBounds().getSouthEast().lng}, ${
            mapbox.map.getBounds().getSouthWest().lat
          } ${mapbox.map.getBounds().getSouthWest().lng}, ${
            mapbox.map.getBounds().getNorthWest().lat
          } ${mapbox.map.getBounds().getNorthWest().lng}`;

          // set bounding box to state
          await this.$store.commit('updateBoundingBox', boundingBoxString);
          await this.$store.dispatch('getTreesByMapExtent');
          resolve();
        } catch {
          reject();
        }
      });
    },
    addTreeLayerAndSource() {
      mapbox.map.addSource('trees-data', {
        type: 'geojson',
        data: this.treesData,
        generateId: true,
      });
      mapbox.map.addLayer({
        id: 'trees-layer',
        type: 'circle',
        source: 'trees-data',
        paint: {
          'circle-radius': 8,
          'circle-color': [
            'match', // Use the match expression
            ['get', 'symbology'], // Get the value of the "type" property
            1,
            'red', // If the "type" value is "Type A", use a red color
            0,
            'blue', // If the "type" value is "Type B", use a green color
            'green', // If the "type" value is,
            // 'case',
            // ['boolean', ['feature-state', 'hover'], false],
            // 'yellow',
            // 'red',
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
    },
    async updateTreesLayer() {
      var source = mapbox.map.getSource('trees-data');
      source.setData(this.treesData);
    },
    // addMapControls() {
    // const that = this;
    // add map controls ************************************
    // mapbox.geolocate = new mapboxgl.GeolocateControl({
    //   positionOptions: {
    //     enableHighAccuracy: true,
    //   },
    //   // When active the map will receive updates to the device's location as it changes.
    //   trackUserLocation: true,
    //   // Draw an arrow next to the location dot to indicate which direction the device is heading.
    //   showUserHeading: true,
    //   showUserLocation: true,
    // });
    // console.log('after add geolocate');

    // mapbox.map.addControl(mapbox.geolocate, 'top-right');

    // when a geolocate event occurs.
    //   mapbox.geolocate.on('geolocate', (position) => {
    //     console.log(position);
    //     // You can access the user's current location here
    //     var userLocation = position.coords;
    //     var coordinates = position.coords;
    //     if (mapbox.first === 'yes') {
    //       mapbox.map.setZoom(18);
    //       mapbox.first = 'no';
    //     }
    //     console.log('geolocate');

    //     //this passes the users location to the store
    //     this.$store.commit('updateUsersLocation', position);
    //   });
    // },
    async submitNewProject() {
      let treeCount = this.treesData.features.length;
      let polyText = '';
      if (this.drawPolygon == '') {
        polyText = `POLYGON((${mapbox.map.getBounds().getNorthWest().lat} ${
          mapbox.map.getBounds().getNorthWest().lng
        }, ${mapbox.map.getBounds().getNorthEast().lat} ${
          mapbox.map.getBounds().getNorthEast().lng
        }, ${mapbox.map.getBounds().getSouthEast().lat} ${
          mapbox.map.getBounds().getSouthEast().lng
        }, ${mapbox.map.getBounds().getSouthWest().lat} ${
          mapbox.map.getBounds().getSouthWest().lng
        }, ${mapbox.map.getBounds().getNorthWest().lat} ${
          mapbox.map.getBounds().getNorthWest().lng
        }))`;
      } else {
        let fc = mapbox.draw.getAll();
        let feat = fc.features[0];
        console.log(feat.geometry.coordinates[0]);
        let poly = 'POLYGON((';
        console.log(feat.geometry.coordinates[0].length);
        for (let i = 0; i < feat.geometry.coordinates[0].length; i++) {
          console.log(feat.geometry.coordinates[0][i][0]);
          poly =
            poly +
            feat.geometry.coordinates[0][i][1] +
            ' ' +
            feat.geometry.coordinates[0][i][0] +
            ',';
        }

        console.log(poly);
        //remove last comma
        let newfeat = poly.substring(0, poly.length - 1);
        //close the string
        polyText = newfeat + '))';
        //loop through drawed polygon and put coords in string
      }

      this.$store.commit('updateNewProjectExtent', {
        polyText: polyText,
        treeCount: treeCount,
        includeTrees: this.$store.state.userStore.newProjectExtent.includeTrees,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="mapbox-gl/dist/mapbox-gl.css"></style>
<style scoped>
#map {
  position: absolute;

  width: 100%;
}
.outlineToggle {
  border: 1px solid green;
}
</style>
