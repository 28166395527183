<template>
  <q-dialog
    v-model="savingEdit"
    seamless
    allow-focus-outside
    no-focus
    position="top"
  >
    <q-card class="bg-grey-1 text-h6 q-pa-sm text-center">
      <div style="color: var(--q-primary) !important">{{ savingMessage }}</div>
    </q-card>
  </q-dialog>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'App',
  components: {},
  computed: {
    savingEdit: {
      get() {
        return this.$store.state.savingEdit;
      },
      set(value) {
        this.$store.commit('updateSavingEdit', value);
      },
    },
    savingMessage() {
      return this.$store.state.savingMessage;
    },
  },
  watch: {},

  data() {
    return {};
  },
  mounted() {
    // console.log('app mounted');
    // this.$store.dispatch('loginAtAppLoad');
  },
};
</script>
