<template>
  <q-scroll-area class="bg-white shadow-1" style="height:calc(100vh - 86px)">
    <div class="q-pa-md project-trees-tables-wrap" v-if="menu == 'home'">
      <q-table
        bordered
        title="Treats"
        :rows="treesInProject"
        :columns="columns"
        row-key="tree_name"
        :visible-columns="visibleColumns"
        :filter="filter"
        selection="single"
        v-model:selected="selected"
        @selection="viewTree"
        class="project-trees-tables"
        :pagination="{ rowsPerPage: 50 }"
        :rows-per-page-options="[25, 50, 100, 0]"
      >
        <template v-slot:top="">
          <div style="width:100%;">
            <div
              :class="
                $q.screen.lt.sm
                  ? 'q-ma-md items-center no-wrap'
                  : 'row q-my-sm items-center no-wrap'
              "
            >
              <q-btn
                label="Download Tree Data"
                @click="statsDownload(treeStats)"
                class="col-auto q-mr-lg text-primary q-mb-sm"
              ></q-btn>
              <br v-if="$q.screen.lt.sm" />
              <q-input
                color="secondary"
                class="col q-mr-lg q-mb-sm"
                outlined
                dense
                v-model="filter"
                placeholder="Search"
              >
              </q-input>
              <q-select
                color="secondary"
                class="col q-mr-lg q-mb-sm"
                v-model="visibleColumns"
                multiple
                dense
                outlined
                options-dense
                display-value="Visible Columns"
                emit-value
                map-options
                :options="columns"
                option-value="name"
              />
            </div>
            <div class="text-caption q-mt-sm">
              Click checkboxes for more info on individual trees
            </div>
          </div>
        </template>
      </q-table>
    </div>
  </q-scroll-area>
</template>

<script>
import Map from '../AppTools/Map.vue';
import TreeHomePage from './TreeHomePage.vue';
const columns = [
  {
    name: 'tree_name',
    label: 'Tree Name',
    align: 'left',
    field: 'tree_name',
    sortable: true,
  },
  {
    name: 'common_name',
    label: 'Common Name',
    align: 'left',
    field: 'common_name',
    sortable: true,
  },
  {
    name: 'scientific_name',
    label: 'Scientific Name',
    align: 'left',
    field: 'scientific_name',
    sortable: true,
  },
  {
    name: 'healthcheck_count',
    label: 'Health Check Count',
    align: 'left',
    field: 'healthcheck_count',
    sortable: true,
  },
  {
    name: 'treecare_count',
    label: 'Tree Care Count',
    align: 'left',
    field: 'treecare_count',
    sortable: true,
  },
  {
    name: 'pestdetection_count',
    label: 'Pest Detection Count',
    align: 'left',
    field: 'pestdetection_count',
    sortable: true,
  },
  {
    name: 'z_score',
    label: 'Z Score',
    align: 'left',
    field: 'z_score',
    sortable: true,
  },
  {
    name: 'dbh',
    label: 'DBH',
    align: 'left',
    field: 'dbh',
    sortable: true,
  },
];

export default {
  name: 'ActiveProjectTrees',
  props: ['index'],
  computed: {
    treeStats() {
      return this.$store.state.treesStore.treeStatsByProject;
    },
    treesInProject() {
      return this.$store.state.treesStore.treesInProject;
    },
    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
  },
  components: { Map, TreeHomePage },
  created() {
    if (this.$q.screen.lt.sm) {
      this.visibleColumns = ['tree_name'];
    }
  },
  data() {
    return {
      menu: 'home',
      selected: [],
      filter: '',
      visibleColumns: [
        'tree_name',
        'common_name',
        'scientific_name',
        'healthcheck_count',
        'treecare_count',
        'pestdetection_count',
      ],
      columns,
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
    statsDownload(objArray) {
      const csv = this.convertToCSV(objArray);
      const filename = `${this.activeProject.project_name}.csv`;
      this.downloadCSV(csv, filename);
    },
    convertToCSV(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));

      for (const row of data) {
        const values = headers.map((header) => {
          const value = row[header];
          const escapedValue =
            value !== null && value !== undefined
              ? value.toString().replace(/"/g, '\\"')
              : '';
          return `"${escapedValue}"`;
        });
        csvRows.push(values.join(','));
      }

      return csvRows.join('\n');
    },
    downloadCSV(csvContent, fileName) {
      const link = document.createElement('a');
      link.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
      link.target = '_blank';
      link.download = fileName;
      link.click();
    },
    viewTree(details) {
      let treeId = details.rows[0].tree_id;
      let projectId = this.$router.currentRoute.value.params.index;
      this.$router.push('/dashboard/project/' + projectId + '/tree/' + treeId);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.project-trees-tables {
  max-height: calc(100vh - 120px);
}
.project-trees-tables-wrap thead tr:first-child th {
  font-size: 14px;
  color: var(--q-primary);
  top: 0;
  position: sticky;
  z-index: 9;
  background: #fff !important;
  box-shadow: 0px 2px 2px -2px rgb(51 51 51 / 42%);
  border-top: 1pt solid rgba(0, 0, 0, 0.12);
}
.q-table thead tr,
.q-table tbody td {
  height: 42px !important;
}
</style>
