<template>
  <q-toolbar class="bg-blue-grey-9 text-white">
    <q-btn
      icon="menu"
      flat
      round
      color=""
      @click="toggleDrawer"
      v-if="$q.screen.lt.md"
    >
    </q-btn>
    <q-toolbar-title class="text-subtitle2">Field Tool Info</q-toolbar-title>
    <q-space></q-space>

    <q-btn padding="xs" round flat color="white" icon="help"
      ><q-menu class=""> <div class="q-ma-md"></div></q-menu
    ></q-btn>
  </q-toolbar>

  <div class="bg-white">
    <q-scroll-area style="height:calc(100vh - 82px)" class="shadow-1">
      <div
        class="text-body1 q-ma-md"
        :style="$q.screen.lt.sm ? 'padding-bottom:50px' : ''"
      >
        <div class="text-body1 text-center q-mt-xl">
          The HTHC field tool contains four modules that can be used as tools to
          manage key aspects of tree stewardship while in the field
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="q-ma-md" flat bordered>
              <q-item>
                <q-item-section avatar>
                  <q-avatar>
                    <q-icon
                      name="nature_people"
                      size="md"
                      color="secondary"
                    ></q-icon>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label class="">ADD A TREE</q-item-label>
                </q-item-section>
              </q-item>

              <q-separator />

              <q-card-section class="text-body2">
                Add individual trees to the HTHC database by inventorying them,
                providing basic information like species, tree diameter, and
                location.
              </q-card-section>
            </q-card>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="q-ma-md" flat bordered>
              <q-item>
                <q-item-section avatar>
                  <q-avatar>
                    <q-icon name="water_drop" size="md" color="blue-4"></q-icon>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label>TREE CARE</q-item-label>
                </q-item-section>
              </q-item>

              <q-separator />

              <q-card-section class="text-body2">
                Record stewardship activities like pruning, watering, and more,
                to ensure a tree receives the proper care it needs.
              </q-card-section>
            </q-card>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="q-ma-md" flat bordered>
              <q-item>
                <q-item-section avatar>
                  <q-avatar>
                    <q-icon
                      name="health_and_safety"
                      size="md"
                      color="amber"
                    ></q-icon>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label>HEALTH CHECK</q-item-label>
                </q-item-section>
              </q-item>

              <q-separator />

              <q-card-section class="text-body2">
                Assess an individual tree based on visual signs of stress to
                acquire insights into the stress level of that tree.
              </q-card-section>
            </q-card>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <q-card class="q-ma-md" flat bordered>
              <q-item>
                <q-item-section avatar>
                  <q-avatar>
                    <q-icon
                      name="pest_control"
                      size="md"
                      color="light-green-4"
                    ></q-icon>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label>PEST DETECTION</q-item-label>
                </q-item-section>
              </q-item>

              <q-separator />

              <q-card-section class="text-body2">
                Check for serious forest insects or pathogens, using a checklist
                that guides users through common signs and symptoms of known and
                unknown pests.
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div class="text-body1 text-center q-mt-xl q-mb-md">
          Getting started with the field tool
        </div>
        <q-list padding bordered class="rounded-borders">
          <q-expansion-item
            dense
            dense-toggle
            label="WHAT IS A PWA?"
            class="bg-white text-primary"
            default-opened
            expand-icon-class="text-primary"
          >
            <q-card>
              <q-card-section class="text-black text-body2">
                A progressive web app (PWA) is an app that's built using web
                platform technologies, but that provides a user experience like
                that of a platform-specific app. Like a website, a PWA can run
                on multiple platforms and devices from a single codebase. Like a
                platform-specific app, it can be installed on the device, can
                operate while offline and in the background, and can integrate
                with the device and with other installed apps.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator color="blue-grey-1"></q-separator>
          <q-expansion-item
            dense
            dense-toggle
            label="HOW TO ADD A WEB APP TO THE HOME SCREEN"
            class="bg-white text-primary"
            expand-icon-class="text-primary"
          >
            <q-card>
              <q-card-section class="text-black text-body2">
                <ol class="q-ma-none">
                  <li>
                    Open a native browser on your phone. (Safari on IOS, Chrome
                    on Andriod)
                  </li>
                  <li>
                    Enter the URL address of the PWA: &nbsp;
                    <b> https://hthc-mobile.netlify.app/</b>
                  </li>
                  <li>
                    <b>IOS:</b> Click the share button on the bottom
                    <q-icon size="sm" name="ios_share"></q-icon>
                    Scroll to select 'Add to Home Screen' option.
                  </li>
                  <li>
                    <b>Android:</b> Click the three dot icon in the upper right
                    <q-icon size="sm" name="more_vert"></q-icon>
                    Select 'Add to Home Screen' option.
                  </li>
                </ol>
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator color="blue-grey-1"></q-separator>
          <q-expansion-item
            dense
            dense-toggle
            label="TROUBLESHOOTING ISSUES"
            class="bg-white text-primary"
            expand-icon-class="text-primary"
          >
            <q-card>
              <q-card-section class="text-black text-body2">
                <b>Location Access</b><br />
                The app requires location access. If you are getting a location
                access error, make sure you have enabled location access for the
                browser you are using in settings. Once you have enabled
                location access, the app will ask permission to use your
                location. By enabling location access for the browser, web apps
                are allowed to ask for permission to use your location and will
                not be able to access your location without your permission.
                <br /><br />
                <b>Popup Blocker</b><br />
                On some browsers the pop-up blocker will prevent the forms from
                opening. If you are having an issue seeing forms (ie: adding a
                tree), try to turn off your pop-up blocker in settings.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator color="blue-grey-1"></q-separator>
          <q-expansion-item
            dense
            dense-toggle
            label="USING THE FIELD TOOL"
            class="bg-white text-primary"
            expand-icon-class="text-primary"
          >
            <q-card>
              <q-card-section class="text-black text-body2">
                <b>Create a Project</b>
                <ol>
                  <li>
                    Click on the Map tab (make sure your location services in
                    your phone are turned on to ensure location point will be
                    visible)
                  </li>
                  <li>Click on the plus (+) icon</li>
                  <li>Click: Create project</li>
                  <li>
                    Set your project extent. A red boarder will appear, zoom in
                    and out to set your project extent.
                  </li>
                  <li>
                    Add your project details such as project name, description,
                    extent, objectives, and city. Check the boxes if you want to
                    include trees in your extent, allow other users to request
                    into your project, and the data collection modules you will
                    be using in your project
                  </li>
                  <li>
                    Once you click submit your project will show up in the Trees
                    Tab.
                  </li>
                </ol>
                <b>Adding Users to a Project</b>
                <ol>
                  <li>
                    In the Trees tab you will see My Projects or Find Projects.
                  </li>
                  <li>
                    In order to be added to the project the requesting user must
                    select Find Projects.
                  </li>
                  <li>
                    They have the option of searching for Nearby projects or by
                    Project Name.
                  </li>
                  <li>Select the project the user wishes to join.</li>
                  <li>
                    Your approval will be pending on the project leader (person
                    who create the project) approves the member.
                  </li>
                  <li>
                    The project leader will decide if they want this requesting
                    user to be a project leader or a participant and then click
                    approve.
                  </li>
                </ol>
                <b>Adding a tree to your project</b>
                <ol>
                  <li>
                    Click on the Map tab (make sure your location services in
                    your phone are turned on to ensure location point will be
                    visible.
                  </li>
                  <li>Click on the plus (+) icon.</li>
                  <li>Click: Add New Tree</li>
                  <li>
                    Choose tree location by moving the circle with the X in it
                    to the center of the tree canopy and then click set.
                  </li>
                  <li>
                    Once you click set, you will then add the tree information
                    for either an existing tree or a tree you’ve just planted.
                  </li>
                </ol>
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'Field Tool',
  computed: {},

  data() {
    return {};
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
