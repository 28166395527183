<template>
  <div class="q-header">
    <div id="map" style="z-index: 99;height: calc(100vh - 300px)"></div>
  </div>
</template>

<script>
import mapboxgl from '/node_modules/mapbox-gl/dist/mapbox-gl.js';
import MapboxDraw from '/node_modules/@mapbox/mapbox-gl-draw';
import '/node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

let mapbox = {
  map: '',
  geolocate: '',
  draw: '',
};

export default {
  name: 'MapQAQC',
  components: {},
  computed: {
    treesData() {
      return this.$store.state.qaqcStore.qaqcTreesGeoJson;
    },
    activeProject() {
      return this.$store.state.userStore.activeProject;
    },
  },
  watch: {},

  props: [],

  data() {
    return {
      useBounds: true,
      drawPolygon: '',
    };
  },
  mounted() {
    console.log('mounted');
    //since I need a different size map in various components I am passing a height prop and
    //resizing the map container on mounted. ie:  'calc(100vh - 200px)'
    document.getElementById('map').style.height = this.height;
    this.createMap();
    /*this.$q.notify({
      message:
        "Looks like you don''t have any trees in your project yet.  Would you like to see instructions for adding a tree?.",
      color: 'white',
      actions: [
        {
          color: 'primary',
          label: 'Show Me',
          handler: () => {
            //do something else
          },
        },
      ],
    });*/
  },

  methods: {
    getProjectBoundingBox() {
      // make geojson from polygon string
      let polygonString = this.activeProject.polygon_text;
      // Extract the coordinates substring from the polygon string
      const coordinatesString = polygonString.substring(
        polygonString.indexOf('((') + 2,
        polygonString.lastIndexOf('))')
      );
      // Split the coordinates substring into an array of coordinate strings
      const coordinateStrings = coordinatesString.split(',');
      // Convert each coordinate string to an array of latitude and longitude pairs
      const coordinates = coordinateStrings.map((coord) => {
        const [longitude, latitude] = coord.trim().split(' ');
        return [parseFloat(latitude), parseFloat(longitude)];
      });
      var geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        ],
      };
      var bbox = turf.bbox(geojson);
      return [bbox, geojson];
    },
    createMap() {
      let bbox = '';
      let geojson = '';
      if (this.activeProject.polygon_text) {
        console.log(this.activeProject.polygon_text);
        let bboxArray = this.getProjectBoundingBox();
        bbox = bboxArray[0];
        console.log(bbox);
        geojson = bboxArray[1];
      } else {
        coords = this.$store.state.userStore.usersLocation;
      }
      let coords = this.$store.state.userStore.usersLocation;

      // First, create a new Mapbox map and set the center and zoom level
      mapboxgl.accessToken =
        'pk.eyJ1IjoidG5jbWFwYm94IiwiYSI6ImNsYmZpNzE0MDA2aHozbm1wazV1aWp3NHUifQ.7plkZIxeS9mzUXB06i-CLg';
      console.log;
      mapbox.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center: [coords[0], coords[1]],
        zoom: coords[2],
      });
      let _this = this;
      mapbox.map.on('load', function() {
        mapbox.map.fitBounds(bbox, { padding: 20 });
        if (_this.activeProject.polygon_text) {
          mapbox.map.addSource('project-boundary', {
            type: 'geojson',
            data: geojson,
          });

          mapbox.map.addLayer({
            id: 'geojsonLayer',
            type: 'line',
            source: 'project-boundary',
            paint: {
              'line-width': 2,
              'line-color': 'red',
            },
          });
        }

        _this.addTreeLayerAndSource();
      });

      mapbox.map.on('moveend', async function() {
        await _this.updateTreesLayer();
      });
    },
    addTreeLayerAndSource() {
      mapbox.map.addSource('trees-data', {
        type: 'geojson',
        data: this.treesData,
        generateId: true,
      });
      mapbox.map.addLayer({
        id: 'trees-layer',
        type: 'circle',
        source: 'trees-data',
        paint: {
          'circle-radius': 8,
          'circle-color': [
            'match', // Use the match expression
            ['get', 'symbology'], // Get the value of the "type" property
            1,
            'gray', // If the "type" value is "Type A", use a red color
            0,
            '#ab47bc', // If the "type" value is "Type B", use a green color
            'green', // If the "type" value is,
            // 'case',
            // ['boolean', ['feature-state', 'hover'], false],
            // 'yellow',
            // 'red',
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
    },
    async updateTreesLayer() {
      var source = mapbox.map.getSource('trees-data');
      source.setData(this.treesData);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="mapbox-gl/dist/mapbox-gl.css"></style>
<style scoped>
#map {
  position: absolute;

  width: 100%;
}
.outlineToggle {
  border: 1px solid green;
}
</style>
