const state = () => ({
  usersProjects: [], //all projects the user is associated with
  projectList: [], //
  treeProjectList: [], //if a tree-user has more than one project
  selectedProject: {}, //users selection if a tree-user has more than one project
  usersLocation: [],
  knownLocation: false,
  newProjectExtent: { polyText: null, treeCount: null, includeTrees: '1' },
  nearbyProjects: [1],
  projectSearchResults: [],
  memberRequests: [],
  projectsTab: 'myprojects',
  activeProject: {},
  projectUsers: [],
  showApprovals: false,
  projectVisitLog: {},
});

const getters = {};

const mutations = {
  updateActiveProject(state, data) {
    state.activeProject = '';
    state.activeProject = data;
  },
  updateProjectsTab(state, data) {
    state.projectsTab = data;
  },
  updateUsersProjects(state, data) {
    state.usersProjects = data;
    state.projectList = [];
    data.forEach((d) => {
      state.projectList.push({ label: d.project_name, value: d.project_id });
    });
  },
  updateUsersLocation(state, obj) {
    state.usersLocation = obj;
    state.knownLocation = true;
  },
  updateSelectedProject(state, obj) {
    state.selectedProject = obj;
  },
  updateTreeProjectList(state, arr) {
    state.treeProjectList = arr;
  },
  updateNewProjectExtent(state, obj) {
    state.newProjectExtent = obj;
  },
  updateNearbyProjects(state, arr) {
    state.nearbyProjects = arr;
  },
  updateProjectSearchResults(state, arr) {
    state.projectSearchResults = arr;
  },
  updateMemberRequests(state, arr) {
    state.memberRequests = arr;
  },
  updateProjectUsers(state, data) {
    state.projectUsers = data;
  },
  updateShowApprovals(state, data) {
    state.showApprovals = data;
  },
  updateProjectVisitLog(state, arr) {
    state.projectVisitLog = arr;
  },
};

const actions = {
  async getUserInfo(context) {
    const response = await context.dispatch('protectedApiRequest', {
      route: `test`,
      type: 'GET',
    });
    context.state.userInfo = response.data.data[0];
  },
  async getUsersProjects(context) {
    const response = await context.dispatch('protectedApiRequest', {
      route: `user/projects`,
      type: 'GET',
    });
    context.commit('updateUsersProjects', response.data.data);
  },
  async createNewProject(context, obj) {
    let inCity = obj.inCity;
    let projectName = obj.projectName;
    let inDescription = obj.inDescription;
    let inExtent = obj.inExtent;
    let inObjectives = obj.inObjectives;
    let healthCheck = obj.healthCheck;
    let treeCare = obj.treeCare;
    let pestDetection = obj.pestDetection;
    let inVisible = obj.inVisible;
    let polyText = obj.polyText;
    let includeTrees = obj.includeTrees;
    let acceptingUsers = obj.acceptingUsers;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/project',
      type: 'POST',
      body: {
        inCity,
        projectName,
        inDescription,
        inExtent,
        inObjectives,
        healthCheck,
        treeCare,
        pestDetection,
        inVisible,
        polyText,
        includeTrees,
        acceptingUsers,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      context.commit('updateNewProjectExtent', {
        polyText: null,
        treeCount: null,
        includeTrees: '1',
      });
      try {
        console.log(response);
        await context.dispatch('getUsersProjects');
      } catch (error) {
        console.log(error);
      }
    } else {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async editProject(context, obj) {
    let inCity = obj.inCity;
    let projectName = obj.projectName;
    let inDescription = obj.inDescription;
    let inExtent = obj.inExtent;
    let inObjectives = obj.inObjectives;
    let healthCheck = obj.healthCheck;
    let treeCare = obj.treeCare;
    let pestDetection = obj.pestDetection;
    let inVisible = obj.inVisible;
    let projectId = obj.projectId;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/project',
      type: 'PUT',
      body: {
        inCity,
        projectName,
        inDescription,
        inExtent,
        inObjectives,
        healthCheck,
        treeCare,
        pestDetection,
        inVisible,
        projectId,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      //context.commit('updateNewProjectExtent', {
      //  polyText: null,
      //  treeCount: null,
      //  includeTrees: '1',
      //});
      try {
        console.log(response);
        //await context.dispatch('getUsersProjects');
      } catch (error) {
        console.log(error);
      }
    } else {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async getProjectById(context, projectId) {
    return new Promise(async (resolve, reject) => {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        console.log(response.data.data[0]);
        try {
          let obj = response.data.data[0];
          await context.commit('updateActiveProject', obj);
          resolve();
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    });
  },
  async getProjectUsers(context, projectId) {
    return new Promise(async (resolve, reject) => {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/users?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let obj = response.data.data;
          await context.commit('updateProjectUsers', obj);
          resolve();
        } catch (error) {
          reject(error);
          console.log(error);
        }
      } else {
        reject(error);
        console.log(error);
      }
    });
  },
  async getNearbyProjects(context, polyText) {
    return new Promise(async (resolve, reject) => {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/nearme?polyText=${polyText}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let obj = response.data.data;
          await context.commit('updateNearbyProjects', obj);
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      } else {
        console.log(error);
      }
    });
  },
  async getProjectByName(context, searchterm) {
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/byname?searchText=${searchterm}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let obj = response.data.data;
          await context.commit('updateProjectSearchResults', obj);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async joinProject(context, obj) {
    try {
      context.commit('updateSavingEdit', 0);
      let projectId = obj.projectId;
      console.log(obj.projectId);
      const response = await context.dispatch('protectedApiRequest', {
        route: '/project/requests',
        type: 'POST',
        body: { projectId },
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
        try {
          context.dispatch('getNearbyProjects', obj.polyText);
          if (obj.searchterm) {
            context.dispatch('getProjectByName', obj.searchterm);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async getMemberRequests(context, val) {
    let projectId = val;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/approvals?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let obj = response.data.data;
          await context.commit('updateMemberRequests', obj);
          await context.commit('updateShowApprovals', true);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async approveMember(context, obj) {
    try {
      context.commit('updateSavingEdit', 0);
      let projectId = obj.projectId;
      let roleId = obj.roleId;
      let requestId = obj.requestId;

      const response = await context.dispatch('protectedApiRequest', {
        route: '/project/approvals',
        type: 'POST',
        body: { projectId, roleId, requestId },
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
        try {
          context.dispatch('getMemberRequests', obj.projectId);
          context.dispatch('getProjectUsers', obj.projectId);
        } catch (error) {
          console.log(error);
        }
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async denyMember(context, obj) {
    try {
      context.commit('updateSavingEdit', 0);
      let projectId = obj.projectId;
      let requestId = obj.requestId;

      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/approvals?requestId=${requestId}&projectId=${projectId}`,
        type: 'DELETE',
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
        try {
          context.dispatch('getMemberRequests', obj.projectId);
        } catch (error) {
          console.log(error);
        }
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async removeMember(context, obj) {
    try {
      context.commit('updateSavingEdit', 0);
      let projectId = obj.projectId;
      let userId = obj.userId;
      const response = await context.dispatch('protectedApiRequest', {
        route: `/user/projects?projectId=${projectId}&userId=${userId}`,
        type: 'DELETE',
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
        try {
          context.dispatch('getProjectUsers', obj.projectId);
        } catch (error) {
          console.log(error);
        }
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async updateUserRole(context, obj) {
    const projectId = obj.projectId;
    const roleId = obj.roleId;
    const userId = obj.userId;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: 'project/users',
      type: 'PUT',
      body: {
        projectId,
        roleId,
        userId,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        context.dispatch('getProjectUsers', obj.projectId);
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
  getUserLocation(context) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;
          let coords = [long, lat, 18];
          context.commit('updateUsersLocation', coords);
        },
        (error) => {
          let coords = [-101.299591, 47.116386, 3];
          context.commit('updateUsersLocation', coords);
          console.log(
            'Error occurred while retrieving the location:',
            error.message
          );
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  },
  async getUserLocationPromise(context) {
    return new Promise(async (resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const lat = position.coords.latitude;
            const long = position.coords.longitude;
            let coords = [long, lat, 18];
            await context.commit('updateUsersLocation', coords);
            resolve();
          },
          async (error) => {
            let coords = [-101.299591, 47.116386, 3];
            await context.commit('updateUsersLocation', coords);
            resolve();
            console.log(
              'Error occurred while retrieving the location:',
              error.message
            );
          }
        );
      } else {
        reject();
        console.log('Geolocation is not supported by this browser.');
      }
    });
  },
  async getVisitsByProject(context, val) {
    let projectId = val;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/visits?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let arr = response.data.data;
          await context.commit('updateProjectVisitLog', arr);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async editProjectLocation(context, obj) {
    let projectId = obj.projectId;
    let polygonText = obj.polygonText;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/project/location',
      type: 'PUT',
      body: {
        projectId,
        polygonText,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      context.dispatch('getProjectById', projectId);
      console.log('done');
      //context.commit('updateNewProjectExtent', {
      //  polyText: null,
      //  treeCount: null,
      //  includeTrees: '1',
      //});
      try {
        console.log(response);
        //await context.dispatch('getUsersProjects');
      } catch (error) {
        console.log(error);
      }
    } else {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
