<template>
  <!--pdf viewer-->
  <div class="fullscreen" v-show="show" style="z-index: 9999;">
    <div class="bg-white text-center">
      <q-btn
        size="md"
        color="white"
        text-color="primary"
        label=""
        class="q-mt-xs"
        @click="this.closePDF()"
        v-show="show"
        style="width: 150px;"
        >CLOSE</q-btn
      >
    </div>
    <div id="adobe2" style="height: calc(100vh - 41px)"></div>
  </div>
  <q-layout view="lhh lpr lFf" class="bg-blue-grey-1">
    <q-scroll-area class="shadow-2" style="height: calc(100vh)">
      <div class="bg-blue-grey-1 row" style="margin-top:92px;">
        <div class="col-md-0 col-sm-0 col-lg-1"></div>
        <div class="col-md-12 col-sm-12 col-lg-10 bg-white">
          <!--this is the page content-->
          <router-view></router-view>
        </div>
      </div>
    </q-scroll-area>
    <!--this is the page Header-->
    <q-page-sticky expand position="top" class="row">
      <div class="col-md-0 col-sm-0 col-lg-1 col-xs-0"></div>
      <div class="col-md-12 col-sm-12 col-lg-10 col-xs-12 bg-white">
        <q-toolbar
          width="100%"
          class="bg-white shadow-2"
          style="border-top: 5px solid #058c5d; "
        >
          <div class="q-pa-md">
            <img src="/img/hthc-long-logo.png" style="height: 48px" />
          </div>
          <q-space></q-space>
          <div v-if="$q.screen.width > 625">
            <q-btn
              padding="sm"
              @click="this.selected = 'home'"
              :class="
                this.selected == 'home'
                  ? 'bg-secondary text-white q-mr-xs'
                  : 'bg-white text-secondary q-mr-xs'
              "
              unelevated
              label="Home"
              to="/"
            ></q-btn>
            <q-btn
              padding="sm"
              @click="this.selected = 'resources'"
              :class="
                this.selected == 'resources'
                  ? 'bg-secondary text-white q-mr-xs'
                  : 'bg-white text-secondary q-mr-xs'
              "
              unelevated
              label="Resources"
              to="/resources"
            ></q-btn>
            <q-btn
              padding="sm"
              to="/dashboard/home"
              @click="this.selected = 'login'"
              :class="
                this.selected == 'login'
                  ? 'bg-secondary text-white q-mr-sm'
                  : 'bg-white text-secondary q-mr-sm'
              "
              unelevated
              label="Dashboard"
            ></q-btn>
          </div>
          <div v-if="$q.screen.width <= 625">
            <q-btn flat icon="menu" color="secondary" padding="sm">
              <q-menu>
                <q-item
                  to="/"
                  clickable
                  @click="this.selected = 'home'"
                  :class="
                    this.selected == 'home'
                      ? 'bg-secondary text-white'
                      : 'bg-white text-secondary text-bold'
                  "
                  ><q-item-section>HOME</q-item-section></q-item
                >
                <q-item
                  to="/resources"
                  clickable
                  @click="this.selected = 'resources'"
                  :class="
                    this.selected == 'resources'
                      ? 'bg-secondary text-white'
                      : 'bg-white text-secondary text-bold'
                  "
                  ><q-item-section>RESOURCES</q-item-section></q-item
                >
                <q-item
                  to="/dashboard"
                  clickable
                  @click="this.selected = 'login'"
                  :class="
                    this.selected == 'login'
                      ? 'bg-secondary text-white'
                      : 'bg-white text-secondary text-bold'
                  "
                  ><q-item-section>DASHBOARD</q-item-section></q-item
                >
              </q-menu>
            </q-btn>
          </div>
        </q-toolbar>
      </div>
      <div class="col-md-0 col-sm-0 col-lg-1 col-xs-12"></div>
    </q-page-sticky>
  </q-layout>
</template>

<script>
let adobeDCView = '';
export default {
  name: 'Landing',
  computed: {
    drawerState() {
      return this.$store.state.drawerState;
    },
    userLoggedIn() {
      return this.$store.state.userLoggedIn;
    },
    showHelp() {
      return this.$store.state.showHelp;
    },
  },
  watch: {
    drawerState() {
      console.log(this.drawerState);
      this.drawer = !this.drawer;
    },
    showHelp() {
      console.log('triggered');
      this.showPDF();
    },
  },

  data() {
    return {
      drawer: true,
      active: false,
      slide: 1,
      autoplay: true,
      selected: 'home',
      show: false,
    };
  },
  methods: {
    closePDF() {
      this.show = false;
      document.getElementById('adobe2').hidden = true;
    },

    showPDF() {
      document.getElementById('adobe2').hidden = false;
      this.show = true;

      //clientid
      //localhost: '1d5dd0457ccc4c4b92e0e41dc64a372a'
      //dev: ''
      //prod: '2669da6e471d4a268c6056c0978b80ff'
      let clientId = '';
      console.log(window.location.hostname);
      if (window.location.hostname === 'localhost') {
        clientId = '1d5dd0457ccc4c4b92e0e41dc64a372a';
      }
      //if (window.location.hostname === 'dev-hthc-dashboard.netlify.app') {
      //  clientId = 'e65ece398c974b8ba63633c00fece23b';
      //}
      if (window.location.hostname === 'hthc-dashboard.netlify.app') {
        clientId = '2669da6e471d4a268c6056c0978b80ff';
      }
      adobeDCView = new AdobeDC.View({
        clientId: clientId,
        divId: 'adobe2',
      });

      adobeDCView.previewFile(
        {
          content: {
            location: {
              url: this.$store.state.showHelp.url,
            },
          },
          metaData: { fileName: this.$store.state.showHelp.filename },
        },
        { ZOOM_LEVEL: 100 }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
