<template>
  <div class="">
    <div class="">
      <q-responsive :ratio="16 / 9" style="max-height: 500px;max-width: 100%;">
        <q-carousel animated v-model="slide" :autoplay="autoplay" infinite>
          <q-carousel-slide :name="1" img-src="/img/IntroPhoto1.jpg" />
          <q-carousel-slide :name="2" img-src="/img/IntroPhoto2.jpg" />
          <q-carousel-slide :name="3" img-src="/img/IntroPhoto3.jpg" />
          <q-carousel-slide :name="4" img-src="/img/IntroPhoto4.jpg" />
        </q-carousel>
      </q-responsive>
    </div>
    <div class="bg-secondary">
      <p
        :class="
          $q.screen.lt.sm
            ? 'text-subtitle2 q-pa-md text-white'
            : 'text-h6 q-pa-xl text-white text-bold'
        "
      >
        Healthy Trees, Healthy Cities (HTHC) is a collaborative initiative to
        promote the long-term health of urban trees by providing free tools and
        resources such as a field companion tool, web-based project management
        dashboard, and training resources.
      </p>
    </div>
    <div class="row q-ma-xl">
      <div class="col-md-6 col-sm-12">
        <div>
          <div
            :class="$q.screen.lt.sm ? 'q-mb-xl' : 'q-pr-xl q-mt-none q-mb-xl'"
          >
            <h3 class="q-mt-none q-mb-lg" style="font-weight: 900;">
              BECOME A CIVIC SCIENTIST
            </h3>
            <p class="text-body1">
              Since its inception HTHC's inception in 2014, civic scientists
              have been powering scientific analyses and management responses
              for urban trees in over 15 metropolitan areas across the country.
              Watch these short videos to learn a simple, scientifically-based
              methodology you can use to check on the health of your community's
              trees.
            </p>
            <q-btn class="q-mt-lg q-mr-sm" size="lg" color="secondary" outline
              >Sign Up</q-btn
            >
            <q-btn
              class="q-mt-lg"
              size="lg"
              color="blue-grey-9"
              outline
              to="/resources"
              >View Resources</q-btn
            >
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 text-center">
        <p class="text-h6">
          Watch this video to learn more about the Healthy Trees, Healthy Cities
          Initiative.
        </p>
        <q-video
          :ratio="16 / 9"
          src="https://www.youtube.com/embed/y-o4kGhYTfw"
        />
      </div>
    </div>
    <div class="row justify-between">
      <q-img src="/img/IntroPhoto4.jpg" style="height:300px">
        <div class="absolute-full  flex flex-center text-center">
          <p class="text-h4" style="line-height: 20px">
            Join the Movement for Healthier Trees <br /><br />
            <span style="font-size:18px; line-height:20px"
              >Life in cities is made so much better through healthy trees. Tree
              care professionals nationwide are actively working to improve tree
              health in cities and you can help by collecting inventory, tree
              health, pest and stewardship data which contribute to a national
              database used by managers and scientists to create healthier, more
              resilient urban forests for all people.</span
            >
          </p>
        </div>
      </q-img>
    </div>
    <div class="row q-ma-xl justify-center align-center">
      <div class="col-md-4 col-sm-12 text-center">
        <p class="text-h5 text-bold">COOLING OUR CITIES</p>
        <p class="text-h4 text-bold">2 - 4° F</p>
        <p class="text-body1">
          Temperature reduction trees <br />provide in a city
        </p>
      </div>
      <div class="col-md-4 col-sm-12 text-center">
        <p class="text-h5 text-bold">PLANTING A HEALTHY FUTURE</p>
        <p class="text-h4 text-bold">10%</p>
        <p class="text-body1">
          A ten percentage point increase in canopy city-wide was associated
          with an estimated reduction of more than 376 deaths city-wide.
        </p>
      </div>
      <div class="col-md-4 col-sm-12 text-center">
        <p class="text-h5 text-bold">CLEANING OUR AIR</p>
        <p class="text-h4 text-bold">7 - 24%</p>
        <p class="text-body1">
          A single tree can remove 7-24% <br />of air pollution
        </p>
      </div>
    </div>
    <div
      class="row bg-secondary justify-center align-center"
      style="border-top:5px solid #058c5d"
    >
      <div class="col-md-2 col-sm-0"></div>
      <div class="col-md-4 col-sm-12 text-center" style="color:#fde354">
        <p class="text-h4 q-mb-lg q-pt-lg text-weight-light">
          Tree Health Matters
        </p>
        <p class="text-h6 q-pa-none q-ma-none">
          Healthier trees mean healthier communities.
        </p>
        <p class="text-h4 q-mt-lg q-pb-lg text-weight-light">
          It's as simple as that.
        </p>
      </div>
      <div class="col-md-4 col-sm-12 text-center">
        <img
          src="/img/city_tree_icon.png"
          style="max-height: 200px"
          class="q-mt-sm"
        />
      </div>
      <div class="col-md-2 col-sm-0"></div>
    </div>
    <div class="row q-mt-xl">
      <div class="col-md-6 col-sm-12">
        <div
          style="background-image: url('/img/Find Project Banner.jpg'); background-position: 70% 0px; height: 550px; background-repeat: no-repeat;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover;"
        >
          <div class="text-left">
            <br /><br />
            <h3 class="q-pt-none q-mt-none q-ml-xl" style="font-weight:900">
              Start Your Own Project.
            </h3>
            <q-btn
              class="q-pt-none q-mt-none q-ml-xl"
              size="lg"
              color="bue-grey-9"
              outline
              >Sign Up</q-btn
            >
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12" style="background-color: #fde354">
        <div>
          <div style="padding-left: 30px; padding-right: 30px;">
            <h4 class="q-mb-none">
              Boy Scout troop 169 helps the city of Oconomowoc WI monitor EAB
              infested ash trees.
            </h4>
            <p class="text-overline text-blue-grey-9">
              Case Study | Oconomowoc, WI
            </p>
            <q-separator spaced></q-separator>
            <p style="color: black;">
              A Boy Scout in the town of Oconomowoc WI noticed that ash trees
              were dying in his town. As part of his Eagle Scout service project
              he started an Healthy Trees Healthy Cities project and used the
              Health Check module to assess all 316 city owned ash trees. The
              city forester used this information to prioritize trees to send
              his professional crews to for further evaluation. The Eagle Scout
              project earned the 2017 National Eagle Scout Association Council
              service project of the year award. The US Forest Service
              remeasured 20 percent of the trees in the project to assess the
              reliability of data collected by non-experts and found substantial
              agreement between the health check ratings conducted by the Boy
              Scouts and those conducted by tree health experts.
            </p>
            <form
              action="https://www.fs.usda.gov/treesearch/pubs/57408"
              method="get"
              target="_blank"
            >
              <q-btn color="blue-grey-9" outline type="submit" class="q-mb-md"
                >Full Story</q-btn
              >
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row bg-black">
      <div class="col-3">
        <div class="q-pa-xl">
          <span class="text-white " style="font-size: 15px;"
            >For more information, questions or support contact
            a.somboonlakana@tnc.org</span
          >
        </div>

        <!--ul class="social-icons">
                  <li class="social-icons-facebook">
                    <a
                      href="https://www.facebook.com/HealthyTreesHC"
                      target="_blank"
                      title="Facebook"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                  </li>
                  <li class="social-icons-twitter">
                    <a
                      href="https://twitter.com/HealthyTreesHC"
                      target="_blank"
                      title="Twitter"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                </ul-->
      </div>
      <div class="col-9 self-center text-right">
        <a target="_blank" href="http://www.nature.org">
          <img src="/img/tnc.png" class=" q-ma-lg" height="60" alt=""
        /></a>
        <a target="_blank" href="http://www.fs.fed.us/">
          <img
            src="/img/usfs.png"
            class="q-ma-lg q-pr-lg"
            height="60"
            alt=""
            style="margin-right: 15px;"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingContent',
  computed: {
    drawerState() {
      return this.$store.state.drawerState;
    },
    userLoggedIn() {
      return this.$store.state.userLoggedIn;
    },
  },
  watch: {
    drawerState() {
      console.log(this.drawerState);
      this.drawer = !this.drawer;
    },
  },

  data() {
    return {
      drawer: true,
      active: false,
      slide: 1,
      autoplay: true,
      selected: 'home',
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
