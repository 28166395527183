import { createStore } from 'vuex';
import router from '../router';
import authStore from './authStore';
import apiAccessStore from './apiAccessStore';
import userStore from './modules/userStore';
import treesStore from './modules/treesStore';
import s3Store from './modules/s3Store';
import qaqcStore from './modules/qaqcStore';

export default createStore({
  state: {
    drawerState: true,
    userLoggedIn: true,
    openedProjects: [],
    showTreeHome: false,
    savingEdit: false,
    createNewProject: true,
    savingMessage: '',
    showHelp: { url: '', filename: '' },
    dashboardStats: '',
    language: 'english',
  },
  mutations: {
    updateOpenedProjects(state, val) {
      const indexOfObject = state.openedProjects.findIndex((object) => {
        return object.project_id === val.project_id;
      });
      if (indexOfObject < 0) {
        state.openedProjects.push(val);
      }
    },
    closeProject(state, prjId) {
      const indexOfObject = state.openedProjects.findIndex((object) => {
        return object.project_id === prjId;
      });
      state.openedProjects.splice(indexOfObject, 1);

      router.push('/dashboard/myprojects');
    },
    toggleDrawer(state) {
      console.log('here');
      state.drawerState = !state.drawerState;
      console.log(state.drawerState);
    },
    addProject(state, obj) {
      state.projects.unshift(obj);
    },
    updateSavingEdit(state, val) {
      // call this function from any PUT or POST with a value of 0, 1, or 2
      // this matches the order of the messages in the array below
      const message = ['Saving Edit', 'Edit Saved', 'Error - Try saving again'];
      // update the dialog text
      state.savingMessage = message[val];
      // set the dialog to visible
      state.savingEdit = true;
      // if the edit was successfully saved, hide the message after 3 seconds
      if (val == 1) {
        setTimeout(() => {
          state.savingEdit = false;
        }, '3000');
      }
      // if there was an error saving, hide the message after 5 seconds
      if (val == 2) {
        setTimeout(() => {
          state.savingEdit = false;
        }, '5000');
      }
    },
    updateShowHelp(state, obj) {
      state.showHelp = {};
      state.showHelp = obj;
    },
    updateShowTreeHome(state, bool) {
      state.showTreeHome = bool;
    },
    updateCreateNewProject(state) {
      state.createNewProject = !state.createNewProject;
    },
    updateDashboardStats(state, obj) {
      state.dashboardStats = obj;
    },
    updateLanguage(state, val) {
      state.config.language = val;
    },
  },

  actions: {
    async getDashboardStats(context) {
      const response = await context.dispatch('protectedApiRequest', {
        route: `trees`,
        type: 'GET',
      });
      console.log(response);
      context.commit('updateDashboardStats', response);
    },
  },

  modules: {
    authStore,
    apiAccessStore,
    userStore,
    treesStore,
    s3Store,
    qaqcStore,
  },
});
