<template>
  <div class="">
    <q-toolbar class="bg-blue-grey-9 text-white">
      <q-btn
        icon="menu"
        flat
        round
        color=""
        @click="toggleDrawer"
        v-if="$q.screen.lt.md"
      >
      </q-btn>
      <q-toolbar-title class="text-subtitle2">Resources</q-toolbar-title>
      <q-space></q-space>

      <q-btn padding="xs" round flat color="white" icon="help"
        ><q-menu class="">
          <div class="q-ma-md">
            Get started using the shortcuts below. These tasks can also be found
            on the left side menu.
          </div></q-menu
        ></q-btn
      >
    </q-toolbar>

    <div class="bg-white">
      <q-scroll-area style="height:calc(100vh - 82px)" class="shadow-1">
        <keep-alive> <resources></resources></keep-alive>
      </q-scroll-area>
    </div>
  </div>
</template>

<script>
import Resources from '../AppTools/Resources.vue';
export default {
  components: { Resources },
  name: 'DashboardJoinProject',
  computed: {},
  activated() {
    console.log('activated');
    //open most recent project in session
  },
  data() {
    return {
      projectSelected: '',
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
